import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IFooterSubComponentStyles {}

export interface IFooterStyles
  extends IAppBaseStyles<IFooterSubComponentStyles> {
    footer: IStyle;
    footerMobile: IStyle;
}

export interface IFooterStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getFooterClassNames = (
  styles?: IStyleFunctionOrObject<IFooterStyleProps, IFooterStyles>,
  props?: IFooterStyleProps
): IComponentBaseClassNames<
  IFooterStyleProps,
  IFooterStyles,
  IFooterSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IFooterStyleProps,
    IFooterStyles,
    IFooterSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<IFooterStyleProps, IFooterStyles> = (
  props: IFooterStyleProps
): IFooterStyles => {
  return {
    root: ["app-Footer", {}],
    footer: {
      textAlign: "center",
      position: "fixed",
      left: "5",
      bottom: "0",
      height: "60px",
      width: "100%",
      borderTopStyle: "solid",
      borderTopWidth: "medium",
      borderTopColor: "black",
      backgroundColor: "white",
      paddingTop: "5px",
    }, footerMobile: {
      textAlign: "center",
      position: "fixed",
      left: "5",
      bottom: "0",
      height: "60px",
      width: "100%",
      fontSize: "10px",
      borderTopStyle: "solid",
      borderTopWidth: "medium",
      borderTopColor: "black",
      backgroundColor: "white",
      paddingTop: "5px",
    }
  };
};
