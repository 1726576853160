import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface ICurrentBookingSubComponentStyles {}

export interface ICurrentBookingStyles
  extends IAppBaseStyles<ICurrentBookingSubComponentStyles> {}

export interface ICurrentBookingStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getCurrentBookingClassNames = (
  styles?: IStyleFunctionOrObject<
    ICurrentBookingStyleProps,
    ICurrentBookingStyles
  >,
  props?: ICurrentBookingStyleProps
): IComponentBaseClassNames<
  ICurrentBookingStyleProps,
  ICurrentBookingStyles,
  ICurrentBookingSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    ICurrentBookingStyleProps,
    ICurrentBookingStyles,
    ICurrentBookingSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  ICurrentBookingStyleProps,
  ICurrentBookingStyles
> = (props: ICurrentBookingStyleProps): ICurrentBookingStyles => {
  return {
    root: ["app-CurrentBooking", {}],
  };
};
