import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IHomeSubComponentStyles {}

export interface IHomeStyles extends IAppBaseStyles<IHomeSubComponentStyles> {}

export interface IHomeStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getHomeClassNames = (
  styles?: IStyleFunctionOrObject<IHomeStyleProps, IHomeStyles>,
  props?: IHomeStyleProps
): IComponentBaseClassNames<
  IHomeStyleProps,
  IHomeStyles,
  IHomeSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IHomeStyleProps,
    IHomeStyles,
    IHomeSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<IHomeStyleProps, IHomeStyles> = (
  props: IHomeStyleProps
): IHomeStyles => {
  return {};
};
