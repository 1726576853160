import { DefaultButton, IconButton, Label, Stack } from "@fluentui/react";
import * as React from "react";
import { LastNews } from "../../Components/LastNews";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { UserAccountServices } from "../../Services/UserAccountServices";
import { getHomeClassNames } from "./Home.styles";
import { IHomeProps, IHomeState } from "./Home.types";

export class HomeComponent extends React.Component<IHomeProps, IHomeState> {
  constructor(props: IHomeProps) {
    super(props);
    this.state = {
      estResident: false,
    };
  }
  private _iconButtonStyles = {
    root: {
      color: this.props.theme.palette.white,
    },
  };

  async componentDidMount() {
    LoadingServices.setLoading("home", true);
    let estResident = await UserAccountServices.GetEstResident();

    this.setState({ estResident: estResident });
    LoadingServices.setLoading("home", false);
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getHomeClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    return (
      <Stack style={{ marginLeft: "10px", height: "80vh" }}>
        <Stack
          horizontalAlign="end"
          style={{ width: "95%", marginTop: "20px" }}
        >
          <DefaultButton
            id="btnPositionNavire"
            style={{
              color: this.props.theme.palette.blueDark,
              backgroundColor: "#f3cf45",
              borderRadius: 10,
              height: "40px",
              width: "200px",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "#f3cf45",
            }}
            onMouseOver={() => {
              let btn = document.getElementById("btnPositionNavire");
              btn.style.backgroundColor = "#c3a637";
              btn.style.borderColor = "#c3a637";
            }}
            onMouseLeave={() => {
              let btn = document.getElementById("btnPositionNavire");
              btn.style.backgroundColor = "#f3cf45";
              btn.style.borderColor = "#f3cf45";
            }}
            href="http://position.desgagnes.com/RNI.aspx"
            target="_blank"
          >
            <img
              style={{ margin: "15px", maxHeight: "20px" }}
              src="/img/pin-map.png"
            />
            {i18n.t("Home:Label:PositionDuNavire")}
          </DefaultButton>
        </Stack>

        {this.state.estResident ? <LastNews {...this.props}></LastNews> : ""}

        <Stack
          style={{
            marginTop: "50px",
            marginRight: "40px",
            width: this.props.mobile ? "95%" : "40%",
          }}
          horizontal
          verticalAlign="center"
        >
          <Label>{i18n.t("Home:Label:Create")}</Label>

          <DefaultButton
            style={{
              color: "white",
              backgroundColor: this.props.theme.palette.blueDark,
              borderRadius: 10,
              height: "40px",
              minWidth: "75px",
              marginLeft: "25px",
            }}
            href="https://moncompte.traversiers.com/processus/connexion/?_return_url=L21vbi1jb21wdGUv"
            target="_blank"
          >
            <IconButton
              iconProps={{ iconName: "UserOptional" }}
              styles={this._iconButtonStyles}
            />
          </DefaultButton>
        </Stack>
      </Stack>
    );
  }
}
