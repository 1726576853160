import axios from "axios";
import Cookies from "universal-cookie";
import { UserInfo } from "../Models/IUserInfo";
import { IVoyageDepart } from "../Models/IVoyageDepart";
import { apiUrl } from "./ApiService";

export class VoyageServices {
  public static async GetDeparts(
    noPortOrigine: string,
    noPortDestination: string
  ): Promise<IVoyageDepart[]> {
    let result: any | undefined;

    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/voyage/get-departs?noPortOrigine=${noPortOrigine}&noPortDestination=${noPortDestination}`
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
}
