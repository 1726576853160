import axios from "axios";
import { ISimulationDto } from "../Models/ISimulationDto";
import { ISimulationResultatDto } from "../Models/ISimulationResultatDto";
import { apiUrl } from "./ApiService";

export class CalculateurServices {
  public static async GetSimulerReservation(
    simulationDto: ISimulationDto
  ): Promise<ISimulationResultatDto> {
    let result: any | undefined;

    try {
      const x = await axios.post<any>(
        `${apiUrl}/api/calculateur/simuler-reservation`,
        simulationDto
      );

      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetEstimerAssurance (valeurVehicule:number, noPeriode:number, portOrigine:string, portDestination:string): Promise<number> {
    let result: any | undefined;
    try {
      const x = await axios.post<any>(
        `${apiUrl}/api/calculateur/estimer-assurance`,
        {valeur: valeurVehicule, noPeriode: noPeriode, portOrigine: portOrigine, portDestination: portDestination}, 
      );

      result = x.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
}
