import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface IRouteCalculatorSubComponentStyles {}

export interface IRouteCalculatorStyles
  extends IAppBaseStyles<IRouteCalculatorSubComponentStyles> {}

export interface IRouteCalculatorStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getRouteCalculatorClassNames = (
  styles?: IStyleFunctionOrObject<
    IRouteCalculatorStyleProps,
    IRouteCalculatorStyles
  >,
  props?: IRouteCalculatorStyleProps
): IComponentBaseClassNames<
  IRouteCalculatorStyleProps,
  IRouteCalculatorStyles,
  IRouteCalculatorSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IRouteCalculatorStyleProps,
    IRouteCalculatorStyles,
    IRouteCalculatorSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IRouteCalculatorStyleProps,
  IRouteCalculatorStyles
> = (props: IRouteCalculatorStyleProps): IRouteCalculatorStyles => {
  return {
    root: ["app-RouteCalculator", {}],
  };
};
