import { Checkbox, IconButton, Label, Stack } from "@fluentui/react";
import * as React from "react";
import { IServiceSupplementaires } from "../../Models/IServiceSupplementaires";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ReservationServices } from "../../Services/ReservationServices";
import { ModalWithSimpleBanner } from "../ModalWithSimpleBanner";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import {
  IServicesSupplementaireProps,
  IServicesSupplementaireState,
} from "./ServicesSupplementaire.types";

export class ServicesSupplementaireComponent extends React.Component<
  IServicesSupplementaireProps,
  IServicesSupplementaireState
> {
  constructor(props: IServicesSupplementaireProps) {
    super(props);
    this.state = { showModal: false, showModalInfo: false, info: "" };
  }
  private _iconButtonStyles = {
    root: {
      color: this.props.theme.palette.neutralPrimary,
      marginLeft: "auto",
      marginTop: "4px",
      marginRight: "2px",
      width: "10px",
    },
    rootHovered: {
      color: this.props.theme.palette.neutralDark,
    },
  };

  render(): JSX.Element {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("SS:Title:ServeiceSupplementaire")}
        render={
          <Stack style={{ marginLeft: "25px", marginBottom: "20px" }}>
            {this.renderModal()}
            {this.props.passagerSelected
              ? this.props.serviceSupplementaires
                  ?.filter((s) => s.categorie === "PASSAGER")
                  .map((x) => {
                    return this.renderUnderComponent(x);
                  })
              : ""}
            {this.props.vehiculeSelected && this.props.passagerSelected
              ? this.props.serviceSupplementaires
                  ?.filter((s) => s.categorie === "VEHICULE")
                  .map((x) => {
                    return this.renderUnderComponent(x);
                  })
              : ""}
          </Stack>
        }
      />
    );
  }

  renderUnderComponent(x: IServiceSupplementaires) {
    return (
      <Stack horizontal key={x.code} verticalAlign="center">
        <Label style={{ minWidth: "200px" }}>{x.libelle}</Label>
        <Checkbox
          checked={x.isCheck}
          onChange={(e, v) => {
            this.props.updateServiceSupplementaire(x, v);
          }}
        />
        <IconButton
          style={{ marginLeft: "20px" }}
          iconProps={{ iconName: "FeedbackRequestSolid" }}
          styles={this._iconButtonStyles}
          onClick={() => {
            this.setState({ showModalInfo: true, info: x.bulleInformation });
          }}
        />
      </Stack>
    );
  }
  renderModal() {
    return (
      <Stack>
        <ModalWithSimpleBanner
          onClose={() => {
            this.setState({
              showModalInfo: false,
              info: "",
            });
          }}
          {...this.props}
          isOpen={this.state.showModalInfo}
          render={
            <Stack
              style={{
                marginLeft: "25px",
                marginRight: "25px",
                marginTop: "25px",
                minWidth: this.props.mobile ? "" : "500px",
                maxWidth: "700px",
              }}
            >
              {this.state.info}
            </Stack>
          }
        />
      </Stack>
    );
  }
}
