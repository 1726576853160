import { styled } from "@fluentui/react/lib/Utilities";
import { IDocumentReservationProps } from "./DocumentReservation.types";
import {
  getStyles,
  IDocumentReservationStyleProps,
  IDocumentReservationStyles,
} from "./DocumentReservation.styles";
import { DocumentReservationComponent } from "./DocumentReservation.base";

/**
 * DocumentReservation
 */
export const DocumentReservation = styled<
  IDocumentReservationProps,
  IDocumentReservationStyleProps,
  IDocumentReservationStyles
>(DocumentReservationComponent, getStyles);
