import { styled } from "@fluentui/react/lib/Utilities";
import { IForgetPasswordProps } from "./ForgetPassword.types";
import {
  getStyles,
  IForgetPasswordStyleProps,
  IForgetPasswordStyles,
} from "./ForgetPassword.styles";
import { ForgetPasswordComponent } from "./ForgetPassword.base";

/**
 * ForgetPassword
 */
export const ForgetPassword = styled<
  IForgetPasswordProps,
  IForgetPasswordStyleProps,
  IForgetPasswordStyles
>(ForgetPasswordComponent, getStyles);
