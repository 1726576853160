import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface IAppHeaderSubComponentStyles {}

export interface IAppHeaderStyles
  extends IAppBaseStyles<IAppHeaderSubComponentStyles> {
  borderBottom: IStyle;
  logo: IStyle;
}

export interface IAppHeaderStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getAppHeaderClassNames = (
  styles?: IStyleFunctionOrObject<IAppHeaderStyleProps, IAppHeaderStyles>,
  props?: IAppHeaderStyleProps
): IComponentBaseClassNames<
  IAppHeaderStyleProps,
  IAppHeaderStyles,
  IAppHeaderSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IAppHeaderStyleProps,
    IAppHeaderStyles,
    IAppHeaderSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IAppHeaderStyleProps,
  IAppHeaderStyles
> = (props: IAppHeaderStyleProps): IAppHeaderStyles => {
  return {
    root: ["app-AppHeader", {}],
    borderBottom: {
      height: "100%",
      borderBottomStyle: "solid",
      borderBottomWidth: "medium",
      borderBottomColor: "darkblue",
    },
    logo: { maxHeight: 55 },
  };
};
