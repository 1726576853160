import axios from "axios";
import { IProvince } from "../Models/IProvince";
import { apiUrl } from "./ApiService";
import i18n from "./i18n";
export class ProvinceServices {
  public static async GetProvinces(): Promise<IProvince[]> {
    let result: any | undefined;

    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/province/get-provinces-langue/${i18n.getLanguage()}`
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
}
