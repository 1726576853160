import { DefaultButton, IComboBoxOption, Label, Stack } from "@fluentui/react";
import * as React from "react";
import Cookies from "universal-cookie";
import { AdressInformation } from "../../Components/AdressInformation";
import { BasicInformation } from "../../Components/BasicInformation";
import { CommunicationInformation } from "../../Components/CommunicationInformation";
import { LoginInformation } from "../../Components/LoginInformation";
import CustomStringServices from "../../Services/CustomStringServices/CustomStringServices";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ProvinceServices } from "../../Services/ProvinceServices";
import { UserAccountServices } from "../../Services/UserAccountServices";
import { ModalWithSimpleBanner } from "../ModalWithSimpleBanner";
import { IMyProfilProps, IMyProfilState } from "./MyProfil.types";

export class MyProfilComponent extends React.Component<
  IMyProfilProps,
  IMyProfilState
> {
  constructor(props: IMyProfilProps) {
    super(props);
    this.state = {
      titleOptions: [],
      provinces: [],
      showModal: false,
      showConfirmPWError: false,
      showRegexPW: false,
      passwordConfirm: "",
      isReadOnly: true,
      account: {
        compteInfo: {
          titre: "",
          prenom: "",
          nom: "",
          estResident: false,
          dateDeNaissance: new Date(),
          langue: 0,
        },
        compteConnexion: {
          courriel: "",
          motDePasse: "",
        },
        compteAdresse: {
          numeroCivique: "",
          rue: "",
          appartement: "",
          ville: "",
          codePostal: "",
          casierPostal: "",
          provinceId: null,
          villeResidentCode: "",
        },
        compteCommunication: {
          telephone: "",
          cellulaire: "",
          infolettre: false,
        },
      },
      isLoading: true,
    };
  }

  async componentDidMount() {
    LoadingServices.setLoading("GetAccount", true);
    let account = await UserAccountServices.GetAccount();

    if (account) {
      this.setState({
        account: account,
        isLoading: false,
        cannotUpdateResident: account.compteInfo.estResident,
      });
    }

    let p = await ProvinceServices.GetProvinces();
    let optionProvinces: IComboBoxOption[] = [];
    p.forEach((x) => {
      optionProvinces.push({ key: x.id, text: x.nom });
    });
    this.setState({ provinces: optionProvinces });

    const titleOptions: IComboBoxOption[] = [
      {
        key: "M",
        text: i18n.t("BasicInformation:ChoiceGroupe:AbbreviationMister"),
      },
      {
        key: "MME",
        text: i18n.t("BasicInformation:ChoiceGroupe:AbbreviationMiss"),
      },
    ];
    this.setState({ titleOptions });

    LoadingServices.setLoading("GetAccount", false);
  }

  render(): JSX.Element {
    if (this.state.isLoading) {
      return <Stack></Stack>;
    } else {
      return (
        <Stack style={{ marginBottom: "100px" }}>
          {this.renderModal()}
          <Stack style={{ marginLeft: "20px", marginTop: "25px" }}>
            <BasicInformation
              {...this.props}
              isNew={false}
              titleOptions={this.state.titleOptions}
              isClient={true}
              isReadOnly={this.state.isReadOnly}
              updateTitre={(value) => {
                this.state.account.compteInfo.titre = value;
                this.setState({ ...this.state });
              }}
              updatePrenom={(value) => {
                this.state.account.compteInfo.prenom = value;
                this.setState({ ...this.state });
              }}
              updateNom={(value) => {
                this.state.account.compteInfo.nom = value;
                this.setState({ ...this.state });
              }}
              updateDateDeNaissance={(value) => {
                this.state.account.compteInfo.dateDeNaissance = value;
                this.setState({ ...this.state });
              }}
              updateLangue={(value) => {
                this.state.account.compteInfo.langue = value;
                this.setState({ ...this.state });
              }}
              updateIsResident={(value) => {
                this.state.account.compteInfo.estResident = value;
                this.setState({ ...this.state });
              }}
              isResident={this.state.account.compteInfo.estResident}
              titre={this.state.account.compteInfo.titre}
              prenom={this.state.account.compteInfo.prenom}
              nom={this.state.account.compteInfo.nom}
              dateDeNaissance={this.state.account.compteInfo.dateDeNaissance}
              langue={this.state.account.compteInfo.langue}
              cannotUpdateResident={true}
            />

            <LoginInformation
              {...this.props}
              isReadOnly={this.state.isReadOnly}
              emailReadOnly={true}
              courriel={this.state.account.compteConnexion.courriel}
              updateCourriel={(value) => {
                this.state.account.compteConnexion.courriel = value;
                this.setState({ ...this.state });
              }}
              updateMotDePasse={(value) => {
                let showErrorMsg: boolean;
                let showConfirmPWError: boolean;
                if (CustomStringServices.ValidatePasswordRegex(value)) {
                  showErrorMsg = false;
                } else {
                  showErrorMsg = true;
                }
                if (
                  this.state.passwordConfirm.length > 0 &&
                  value !== this.state.passwordConfirm
                ) {
                  showConfirmPWError = true;
                }
                this.state.account.compteConnexion.motDePasse = value;

                this.setState({
                  account: this.state.account,
                  showRegexPW: showErrorMsg,
                  showConfirmPWError: showConfirmPWError,
                });
              }}
              updateConfrimMotDePasse={(value) => {
                let showConfirmPWError: boolean;
                if (value !== this.state.account.compteConnexion.motDePasse) {
                  showConfirmPWError = true;
                } else {
                  showConfirmPWError = false;
                }

                this.setState({
                  showConfirmPWError: showConfirmPWError,
                  passwordConfirm: value,
                });
              }}
              showRegexPW={this.state.showRegexPW}
              showConfirmPWError={this.state.showConfirmPWError}
            />

            <AdressInformation
              {...this.props}
              provinces={this.state.provinces}
              isPassenger={false}
              isReadOnly={this.state.isReadOnly}
              updateNumeroCivique={(value) => {
                this.state.account.compteAdresse.numeroCivique = value;
                this.setState({ ...this.state });
              }}
              updateRue={(value) => {
                this.state.account.compteAdresse.rue = value;
                this.setState({ ...this.state });
              }}
              updateAppartement={(value) => {
                this.state.account.compteAdresse.appartement = value;
                this.setState({ ...this.state });
              }}
              updateVille={(value) => {
                this.state.account.compteAdresse.ville = value;
                this.state.account.compteAdresse.villeResidentCode = value;
                this.setState({ ...this.state });
              }}
              updateCodePostal={(value) => {
                this.state.account.compteAdresse.codePostal = value;
                this.setState({ ...this.state });
              }}
              updateCasierPostal={(value) => {
                this.state.account.compteAdresse.casierPostal = value;
                this.setState({ ...this.state });
              }}
              updateProvince={(value) => {
                this.state.account.compteAdresse.provinceId = parseFloat(value);
                this.setState({ ...this.state });
              }}
              isResident={this.state.account.compteInfo.estResident}
              numeroCivique={CustomStringServices.StringReplace(
                this.state.account.compteAdresse.numeroCivique
              )}
              rue={CustomStringServices.StringReplace(
                this.state.account.compteAdresse.rue
              )}
              appartement={CustomStringServices.StringReplace(
                this.state.account.compteAdresse.appartement
              )}
              ville={this.state.account.compteAdresse.ville}
              codePostal={CustomStringServices.StringReplace(
                this.state.account.compteAdresse.codePostal
              )}
              casierPostal={CustomStringServices.StringReplace(
                this.state.account.compteAdresse.casierPostal
              )}
              provinceId={this.state.account.compteAdresse.provinceId}
              villeResidentCode={
                this.state.account.compteAdresse.villeResidentCode
              }
            />
            <CommunicationInformation
              {...this.props}
              isPassenger={false}
              isReadOnly={this.state.isReadOnly}
              updateTelephone={(value) => {
                this.state.account.compteCommunication.telephone = value;
                this.setState({ ...this.state });
              }}
              updateCellulaire={(value) => {
                this.state.account.compteCommunication.cellulaire = value;
                this.setState({ ...this.state });
              }}
              updateInfolettre={(value) => {
                this.state.account.compteCommunication.infolettre = value;
                this.setState({ ...this.state });
              }}
              telephone={this.state.account.compteCommunication.telephone}
              cellulaire={CustomStringServices.StringReplace(
                this.state.account.compteCommunication.cellulaire
              )}
              infolettre={this.state.account.compteCommunication.infolettre}
            />
          </Stack>
          <Stack style={{ width: "95%", marginTop: "20px" }}>
            {this.state.isReadOnly ? (
              this.renderFooterButton()
            ) : (
              <Stack
                horizontal
                horizontalAlign="end"
                style={{ marginLeft: this.props.mobile ? "20px" : "" }}
              >
                <DefaultButton
                  onClick={() => {
                    this.setState({ isReadOnly: true });
                  }}
                  style={{
                    color: "white",
                    backgroundColor: this.props.theme.palette.blueDark,
                    borderRadius: 10,
                    height: "50px",
                    width: "250px",
                  }}
                >
                  {i18n.t("MP:Label:Cancel")}
                </DefaultButton>
                <DefaultButton
                  onClick={() => {
                    this.updateAccount();
                  }}
                  disabled={
                    this.state.account.compteInfo.estResident
                      ? !this.canbeSaveIsResident()
                      : !this.canBeSaveNotResident()
                  }
                  style={{
                    color: "white",
                    backgroundColor: (
                      this.state.account.compteInfo.estResident
                        ? this.canbeSaveIsResident()
                        : this.canBeSaveNotResident()
                    )
                      ? this.props.theme.palette.blueDark
                      : this.props.theme.palette.neutralLight,
                    borderRadius: 10,
                    height: "50px",
                    width: "250px",
                    marginLeft: "15px",
                  }}
                >
                  {i18n.t("MP:Label:Save")}
                </DefaultButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      );
    }
  }

  VerifyStateOfPassword() {
    return (
      this.state.account.compteConnexion.motDePasse ===
        this.state.passwordConfirm && !this.state.showRegexPW
    );
  }

  canBeSaveNotResident(): boolean {
    return (
      this.state.account.compteInfo.titre !== "" &&
      this.state.account.compteInfo.prenom !== "" &&
      this.state.account.compteInfo.nom !== "" &&
      this.state.account.compteInfo.langue !== null &&
      this.state.account.compteAdresse.numeroCivique !== "" &&
      this.state.account.compteAdresse.rue !== "" &&
      this.state.account.compteAdresse.ville !== "" &&
      this.state.account.compteAdresse.codePostal !== "" &&
      this.state.account.compteAdresse.codePostal.replaceAll("_", "").length ===
        6 &&
      this.state.account.compteAdresse.provinceId !== null &&
      this.state.account.compteConnexion.courriel !== "" &&
      this.state.account.compteCommunication.telephone !== "" &&
      !this.state.account.compteCommunication.telephone.includes("_") &&
      this.VerifyStateOfPassword()
    );
  }

  canbeSaveIsResident(): boolean {
    return (
      this.state.account.compteInfo.titre !== "" &&
      this.state.account.compteInfo.prenom !== "" &&
      this.state.account.compteInfo.nom !== "" &&
      this.state.account.compteInfo.langue !== null &&
      this.state.account.compteAdresse.ville !== "" &&
      this.state.account.compteAdresse.codePostal !== "" &&
      this.state.account.compteAdresse.codePostal.replaceAll("_", "").length ===
        6 &&
      this.state.account.compteAdresse.provinceId !== null &&
      this.state.account.compteConnexion.courriel !== "" &&
      this.state.account.compteCommunication.telephone !== "" &&
      !this.state.account.compteCommunication.telephone.includes("_") &&
      this.VerifyStateOfPassword()
    );
  }

  async deleteAccount() {
    LoadingServices.setLoading("deleteAccount", true);

    let result = await UserAccountServices.DeleteCompte();
    if (result < 299) {
      let cookies = new Cookies();
      cookies.remove("userInfo");
      window.location.reload();
    } else if (result < 500) {
      // toto error gestion
    } else if (result > 500) {
      // toto error gestion
    }
    LoadingServices.setLoading("deleteAccount", false);
  }

  async updateAccount() {
    LoadingServices.setLoading("UpdateAccount", true);
    let result = await UserAccountServices.UpdateAccount(this.state.account);
    if (result < 299) {
      this.props.updateLangue(this.state.account.compteInfo.langue);
      this.props.updateNomPrenom(
        this.state.account.compteInfo.nom,
        this.state.account.compteInfo.prenom
      );
      this.setState({ isReadOnly: true });
    } else if (result < 500) {
      //toto error gestion
    } else if (result > 500) {
      //toto error gestion
    }
    LoadingServices.setLoading("UpdateAccount", false);
  }

  renderFooterButton() {
    let styleDeleteAccountStack;
    let styleDeleteAccountLabel;
    let labelCannotCloseAccount;

    if (this.state.account.peutEtreSupprimer) {
      styleDeleteAccountStack = { marginLeft: "20px", cursor: "pointer" };
      styleDeleteAccountLabel = {
        marginLeft: "20px",
        fontWeight: "bold",
        cursor: "pointer",
      };
      labelCannotCloseAccount = "";
    } else {
      styleDeleteAccountStack = { marginLeft: "20px", cursor: "default" };
      styleDeleteAccountLabel = {
        marginLeft: "20px",
        fontWeight: "bold",
        cursor: "default",
        color: "lightgray",
      };
      labelCannotCloseAccount = (
        <Label style={{ color: "red", fontStyle: "italic" }}>
          {i18n.t("MP:P:CannotDeleteAccount")}
        </Label>
      );
    }

    return (
      <Stack horizontal horizontalAlign="space-between">
        <Stack
          horizontal
          style={styleDeleteAccountStack}
          verticalAlign="center"
          onClick={() => {
            if (!this.state.account.peutEtreSupprimer) return;
            this.setState({ showModal: true });
          }}
        >
          <img src="/img/TrashLogo.png" />
          <Label style={styleDeleteAccountLabel}>
            {i18n.t("MP:Label:CloseAccount")}
          </Label>

          {labelCannotCloseAccount}
        </Stack>

        <DefaultButton
          style={{
            color: "white",
            backgroundColor: this.props.theme.palette.blueDark,
            borderRadius: 10,
            height: "50px",
            width: "250px",
          }}
          onClick={() => {
            this.setState({ isReadOnly: false });
          }}
        >
          {i18n.t("MP:Label:Update")}
        </DefaultButton>
      </Stack>
    );
  }

  renderModal() {
    return (
      <Stack>
        <ModalWithSimpleBanner
          onClose={() => {
            this.setState({ showModal: false });
          }}
          redirectUrlToClose={"/myprofil"}
          {...this.props}
          isOpen={this.state.showModal}
          render={
            <Stack
              style={{
                marginLeft: "25px",
                marginRight: "25px",
              }}
            >
              <h3 style={{ borderBottom: "solid", marginRight: "25px" }}>
                {i18n.t("MP:Modal:h3")}
              </h3>
              <Label style={{ color: this.props.theme.palette.blueDark }}>
                {i18n.t("MP:Modal:Question")}
              </Label>
              <Label style={{ color: this.props.theme.palette.blueDark }}>
                {i18n.t("MP:Modal:Label")}
              </Label>
              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{
                  marginTop: "50px",
                }}
              >
                <DefaultButton
                  style={{
                    minWidth: "150px",
                    color: this.props.theme.palette.blueDark,
                    borderRadius: 10,
                    height: "50px",
                  }}
                  onClick={() => {
                    this.setState({ showModal: false });
                  }}
                >
                  {i18n.t("MP:P:DeleteQuestionNo")}
                </DefaultButton>

                <DefaultButton
                  style={{
                    minWidth: "150px",
                    color: "white",
                    backgroundColor: this.props.theme.palette.blueDark,
                    borderRadius: 10,
                    height: "50px",
                  }}
                  onClick={() => {
                    this.deleteAccount();
                  }}
                >
                  {i18n.t("MP:P:DeleteQuestionYes")}
                </DefaultButton>
              </Stack>
            </Stack>
          }
        />
      </Stack>
    );
  }
}
