import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IVehicleSubComponentStyles {}

export interface IVehicleStyles
  extends IAppBaseStyles<IVehicleSubComponentStyles> {}

export interface IVehicleStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getVehicleClassNames = (
  styles?: IStyleFunctionOrObject<IVehicleStyleProps, IVehicleStyles>,
  props?: IVehicleStyleProps
): IComponentBaseClassNames<
  IVehicleStyleProps,
  IVehicleStyles,
  IVehicleSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IVehicleStyleProps,
    IVehicleStyles,
    IVehicleSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IVehicleStyleProps,
  IVehicleStyles
> = (props: IVehicleStyleProps): IVehicleStyles => {
  return {
    root: ["app-Vehicle", {}],
  };
};
