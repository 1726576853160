import { styled } from "@fluentui/react/lib/Utilities";
import { IMyPassengersProps } from "./MyPassengers.types";
import {
  getStyles,
  IMyPassengersStyleProps,
  IMyPassengersStyles,
} from "./MyPassengers.styles";
import { MyPassengersComponent } from "./MyPassengers.base";

/**
 * MyPassengers
 */
export const MyPassengers = styled<
  IMyPassengersProps,
  IMyPassengersStyleProps,
  IMyPassengersStyles
>(MyPassengersComponent, getStyles);
