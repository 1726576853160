import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IReservationsSubComponentStyles {}

export interface IReservationsStyles
  extends IAppBaseStyles<IReservationsSubComponentStyles> {}

export interface IReservationsStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getReservationsClassNames = (
  styles?: IStyleFunctionOrObject<IReservationsStyleProps, IReservationsStyles>,
  props?: IReservationsStyleProps
): IComponentBaseClassNames<
  IReservationsStyleProps,
  IReservationsStyles,
  IReservationsSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IReservationsStyleProps,
    IReservationsStyles,
    IReservationsSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IReservationsStyleProps,
  IReservationsStyles
> = (props: IReservationsStyleProps): IReservationsStyles => {
  return {
    root: ["app-Reservations", {}],
  };
};
