import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface INavBarSubComponentStyles {}

export interface INavBarStyles
  extends IAppBaseStyles<INavBarSubComponentStyles> {
  logo: IStyle;
}

export interface INavBarStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getNavBarClassNames = (
  styles?: IStyleFunctionOrObject<INavBarStyleProps, INavBarStyles>,
  props?: INavBarStyleProps
): IComponentBaseClassNames<
  INavBarStyleProps,
  INavBarStyles,
  INavBarSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    INavBarStyleProps,
    INavBarStyles,
    INavBarSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<INavBarStyleProps, INavBarStyles> = (
  props: INavBarStyleProps
): INavBarStyles => {
  return {
    root: ["app-NavBar", {}],
    logo: { maxHeight: "40px" },
  };
};
