import { Stack } from "@fluentui/react";
import * as React from "react";
import { getAppClassNames } from "./App.styles";
import { IAppState, IAppProps } from "./App.types";
import { AppRouter } from "../AppRouter";
import LoadingServices from "../Services/LoadingServices/LoadingServices";
import Cookies from "universal-cookie";
import { AppService } from "../Services/AppService";

export class AppComponent extends React.Component<IAppProps, IAppState> {
  private _cookies = new Cookies();

  constructor(props: IAppProps) {
    super(props);
    this.state = { maintenance: { enMaintenance: false, message: "" } };
  }

  async componentDidMount() {
    LoadingServices.setLoading("init", true);
    LoadingServices.setLoading("init", false);
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getAppClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <Stack>
        <AppRouter
          {...this.props}
          {...this.state}
          maintenance={this.state.maintenance}
        />
      </Stack>
    );
  }
}
