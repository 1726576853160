import {
  Checkbox,
  DefaultButton,
  IComboBoxOption,
  IProcessedStyleSet,
  Label,
  Stack,
} from "@fluentui/react";
import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { AdressInformation } from "../../Components/AdressInformation";
import { BasicInformation } from "../../Components/BasicInformation";
import { CommunicationInformation } from "../../Components/CommunicationInformation";
import { LoginInformation } from "../../Components/LoginInformation";
import { ModalWithSimpleBanner } from "../../Components/ModalWithSimpleBanner";
import CustomStringServices from "../../Services/CustomStringServices/CustomStringServices";
import i18n from "../../Services/i18n";
import { ProvinceServices } from "../../Services/ProvinceServices";
import { UserAccountServices } from "../../Services/UserAccountServices";
import { getSignUpClassNames, ISignUpStyles } from "./SignUp.styles";
import { ISignUpProps, ISignUpState } from "./SignUp.types";

export class SignUpComponent extends React.Component<
  ISignUpProps,
  ISignUpState
> {
  constructor(props: ISignUpProps) {
    super(props);

    this.state = {
      provinces: [],
      titleOptions: [],
      emailWasChange: false,
      pwWasChange: false,
      confirmPwWasChange: false,
      passwordConfirm: "",
      notRobot: false,
      creationIsDone: false,
      traitementEnCours: false,
      errorMsg: "",
      fieldEmpty: false,
      termOfUseCheck: false,
      isResident: false,
      account: {
        compteInfo: {
          titre: i18n.t("BasicInformation:ChoiceGroupe:AbbreviationMister"),
          prenom: "",
          nom: "",
          estResident: false,
          dateDeNaissance: null,
          langue: 0,
        },
        compteConnexion: {
          courriel: "",
          motDePasse: "",
        },
        compteAdresse: {
          numeroCivique: "",
          rue: "",
          appartement: "",
          ville: "",
          codePostal: "",
          casierPostal: "",
          provinceId: null,
          villeResidentCode: "",
        },
        compteCommunication: {
          telephone: "",
          cellulaire: "",
          infolettre: true,
        },
      },
    };
  }

  renderModal(classNames: IProcessedStyleSet<ISignUpStyles>) {
    const { creationIsDone } = this.state;

    return (
      <ModalWithSimpleBanner
        redirectUrlToClose={"/"}
        {...this.props}
        isOpen={creationIsDone}
        render={
          <Stack>
            <Stack
              style={{
                marginLeft: "15px",
                marginRight: "15px",
                marginTop: "15px",
              }} /*horizontalAlign="center"*/
            >
              <Label>{i18n.t("SignUp:Modal:Text1")}</Label>
              <Label>{i18n.t("SignUp:Modal:Text2")}</Label>
              <Label>{i18n.t("SignUp:Modal:Text3")}</Label>
            </Stack>
            <Label style={{ marginLeft: "15px" }}>
              {i18n.t("SignUp:Modal:Text5")}
              <a href="/signin">{i18n.t("SignUp:Modal:TextLink1")}</a>
            </Label>
          </Stack>
        }
      />
    );
  }

  async getProvinces() {
    let p = await ProvinceServices.GetProvinces();
    let optionProvinces: IComboBoxOption[] = [];
    p.forEach((x) => {
      optionProvinces.push({ key: x.id, text: x.nom });
    });
    this.setState({ provinces: optionProvinces });
  }

  async getProvinceId() {
    let p = await ProvinceServices.GetProvinces();
    let province = i18n.getLanguage() === "fr" ? "Québec" : "Quebec";
    let pId = p.filter((x) => x.nom === province)[0].id;
    this.state.account.compteAdresse.provinceId = pId;
    this.setState({ ...this.state });
  }

  async getTitles() {
    const titleOptions: IComboBoxOption[] = [
      {
        key: "M",
        text: i18n.t("BasicInformation:ChoiceGroupe:AbbreviationMister"),
      },
      {
        key: "MME",
        text: i18n.t("BasicInformation:ChoiceGroupe:AbbreviationMiss"),
      },
    ];
    this.setState({ titleOptions });
  }

  async componentDidMount() {
    this.getTitles();
    this.getProvinces();
    this.getProvinceId();
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getSignUpClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    return (
      <Stack
        className={this.props.mobile ? classNames.mobile : classNames.desktop}
      >
        {this.renderModal(classNames)}
        <Stack
          horizontal
          horizontalAlign="space-between"
          className={classNames.modalLine}
        >
          <Stack
            horizontal
            style={{
              marginLeft: "25px",
              marginTop: "15px",
              marginBottom: "10px",
            }}
          >
            <Link to="/">
              <img className={classNames.logo} src="/img/logo-Bella.svg" />
            </Link>
          </Stack>

          <Stack style={{ marginRight: "25px", marginTop: "15px" }}>
            <Label
              style={{ cursor: "pointer", fontSize: "bold" }}
              onClick={async () => {
                let lang = i18n.getLanguage();
                i18n.changeLanguage(lang);
                this.getTitles();
                this.getProvinces();
                this.getProvinceId();
                this.forceUpdate();
              }}
            >
              {i18n.t("app:translation")}
            </Label>
          </Stack>
        </Stack>

        <h2
          style={{
            marginTop: "20px",
            marginLeft: "20px",
            color: this.props.theme.palette.blueLight,
          }}
        >
          {i18n.t("SignUp:H_Title:CreeCompte")}
        </h2>

        <Stack style={{ marginLeft: "20px" }}>
          {this.state.fieldEmpty ? (
            <h2 style={{ color: "red" }}>
              {i18n.t("SignUp:Modal:Obligatoire")}
            </h2>
          ) : (
            ""
          )}

          <BasicInformation
            {...this.props}
            isNew={false}
            titleOptions={this.state.titleOptions}
            isReadOnly={false}
            isClient={true}
            langue={this.state.account.compteInfo.langue}
            isResident={this.state.isResident}
            dateDeNaissance={this.state.account.compteInfo.dateDeNaissance}
            updateTitre={(value) => {
              this.state.account.compteInfo.titre = value;
              this.setState({ ...this.state });
            }}
            updatePrenom={(value) => {
              this.state.account.compteInfo.prenom = value;
              this.setState({ ...this.state });
            }}
            updateNom={(value) => {
              this.state.account.compteInfo.nom = value;
              this.setState({ ...this.state });
            }}
            updateDateDeNaissance={(value) => {
              this.state.account.compteInfo.dateDeNaissance = value;
              this.setState({
                ...this.state,
              });
            }}
            updateLangue={(value) => {
              this.state.account.compteInfo.langue = value;
              this.setState({ ...this.state });
            }}
            updateIsResident={(value) => {
              let acc = this.state.account;
              acc.compteAdresse.ville = "";

              if (value) {
                this.getProvinceId();
              } else {
                acc.compteAdresse.provinceId = null;
              }

              this.setState({ isResident: value, account: acc });
            }}
            titre={this.state.account.compteInfo.titre}
          />
          <LoginInformation
            {...this.props}
            isReadOnly={false}
            emailWasChange={this.state.emailWasChange}
            pwWasChange={this.state.pwWasChange}
            confirmPwWasChange={this.state.confirmPwWasChange}
            updateCourriel={async (value) => {
              let showErrorMsg: boolean;
              let isExist: boolean;
              this.state.account.compteConnexion.courriel = value;
              if (CustomStringServices.ValidateEmail(value)) {
                isExist = await UserAccountServices.GetExistCompte(value);
                if (!isExist) {
                  showErrorMsg = false;
                }
              } else {
                showErrorMsg = true;
              }

              this.setState({
                emailWasChange: true,
                account: this.state.account,
                showEmailError: showErrorMsg,
                showEmailExistError: isExist,
              });
            }}
            updateMotDePasse={(value) => {
              let showErrorMsg: boolean;
              let showConfirmPWError: boolean;

              if (CustomStringServices.ValidatePasswordRegex(value)) {
                this.state.account.compteConnexion.motDePasse = value;
                showErrorMsg = false;
              } else {
                this.state.account.compteConnexion.motDePasse = value;
                showErrorMsg = true;
              }

              if (
                this.state.passwordConfirm.length > 0 &&
                value !== this.state.passwordConfirm
              ) {
                showConfirmPWError = true;
              }

              this.setState({
                pwWasChange: true,
                account: this.state.account,
                showRegexPW: showErrorMsg,
                showConfirmPWError: showConfirmPWError,
              });
            }}
            updateConfrimMotDePasse={(value) => {
              if (!CustomStringServices.ValidatePasswordRegex(value)) {
                let s =
                  value === this.state.account.compteConnexion.motDePasse
                    ? false
                    : true;

                this.setState({
                  showRegexPW: true,
                  showConfirmPWError: s,
                  passwordConfirm: value,
                });
              } else if (
                value !== this.state.account.compteConnexion.motDePasse
              ) {
                this.setState({
                  confirmPwWasChange: true,
                  showConfirmPWError: true,
                  passwordConfirm: value,
                  showRegexPW: false,
                });
              } else {
                this.setState({
                  confirmPwWasChange: true,
                  showConfirmPWError: false,
                  passwordConfirm: value,
                  showRegexPW: false,
                });
              }
            }}
            showConfirmPWError={this.state.showConfirmPWError}
            showEmailError={this.state.showEmailError}
            showRegexPW={this.state.showRegexPW}
            showEmailExistError={this.state.showEmailExistError}
          />

          <AdressInformation
            provinces={this.state.provinces}
            isReadOnly={false}
            isPassenger={false}
            updateNumeroCivique={(value) => {
              this.state.account.compteAdresse.numeroCivique = value;
              this.setState({ ...this.state });
            }}
            updateRue={(value) => {
              this.state.account.compteAdresse.rue = value;
              this.setState({ ...this.state });
            }}
            updateAppartement={(value) => {
              this.state.account.compteAdresse.appartement = value;
              this.setState({ ...this.state });
            }}
            updateVille={(value) => {
              this.state.account.compteAdresse.ville = value;
              this.setState({ ...this.state });
            }}
            updateCodePostal={(value) => {
              this.state.account.compteAdresse.codePostal = value;
              this.setState({ ...this.state });
            }}
            updateCasierPostal={(value) => {
              this.state.account.compteAdresse.casierPostal = value;
              this.setState({ ...this.state });
            }}
            updateProvince={(value) => {
              this.state.account.compteAdresse.provinceId = parseFloat(value);
              this.setState({ ...this.state });
            }}
            {...this.props}
            isResident={this.state.isResident}
          />
          <CommunicationInformation
            {...this.props}
            isReadOnly={false}
            isPassenger={false}
            updateTelephone={(value) => {
              this.state.account.compteCommunication.telephone = value;
              this.setState({ ...this.state });
            }}
            updateCellulaire={(value) => {
              this.state.account.compteCommunication.cellulaire = value;
              this.setState({ ...this.state });
            }}
            updateInfolettre={(value) => {
              this.state.account.compteCommunication.infolettre = value;
              this.setState({ ...this.state });
            }}
            infolettre={this.state.account.compteCommunication.infolettre}
          />

          {this.renderButton()}
        </Stack>
        
        <Label style={{ color: "red", marginLeft: "20px" }}>
              {this.state.errorMsg}
        </Label>
      </Stack>
    );
  }

  renderButton() {
    return (
      <Stack
        verticalAlign="center"
        horizontalAlign="space-between"
        horizontal={!this.props.mobile}
        style={{
          marginLeft: !this.props.mobile ? "20px" : "",
          marginTop: "20px",
          width: "50%",
        }}
      >
        <Stack tabIndex={40}>
          <Checkbox
            onChange={(e, v) => {
              this.setState({ termOfUseCheck: v });
            }}
          />
        </Stack>

        <Label>
          {i18n.t("SignUp:CheckBox:Condition")}{" "}
          <a
            href={
              i18n.getLanguage() === "fr"
                ? "/pdf/RNI_Politique_Conditions_utilisation_Francais.pdf"
                : "/pdf/RNI_Politique_Conditions_utilisation_Anglais.pdf"
            }
            target={"_blank"}
          >
            {i18n.t("SignUp:CheckBox:ConditionLink")}
          </a>
        </Label>
        <ReCAPTCHA
          tabIndex={41}
          style={{
            marginLeft: !this.props.mobile ? "20px" : "",
            marginTop: this.props.mobile ? "20px" : "",
          }}
          sitekey="6Lco_2oeAAAAAIBPZCIKHcUZsI7Y75-WPj-y2C78"
          onChange={(e) => {
            this.setState({ notRobot: !this.state.notRobot });
          }}
        />
        <DefaultButton
          tabIndex={42}
          disabled={
            this.state.isResident
              ? !this.canbeSaveIsResident()
              : !this.canBeSaveNotResident()
          }
          style={{
            color: "white",
            backgroundColor: (
              this.state.isResident
                ? this.canbeSaveIsResident()
                : this.canBeSaveNotResident()
            )
              ? this.props.theme.palette.blueDark
              : this.props.theme.palette.neutralLight,
            borderRadius: 10,
            height: "50px",
            width: "250px",
          }}
          onClick={() => {
            if (!this.state.traitementEnCours)
              this.setState({traitementEnCours: true}, ()=>this.validateFormBeforeSave());
          }}
        >
          {i18n.t("SignUp:Button:Save")}
        </DefaultButton>
      </Stack>
    );
  }

  canbeSaveIsResident(): boolean {
    return (
      this.state.account.compteInfo.titre !== "" &&
      this.state.account.compteInfo.prenom !== "" &&
      this.state.account.compteInfo.nom !== "" &&
      this.state.account.compteInfo.langue !== null &&
      this.state.account.compteAdresse.ville !== "" &&
      this.state.account.compteAdresse.codePostal !== "" &&
      this.state.account.compteAdresse.codePostal.replaceAll("_", "").length ===
        6 &&
      this.state.account.compteAdresse.provinceId !== null &&
      this.state.account.compteConnexion.courriel !== "" &&
      this.state.account.compteConnexion.motDePasse !== "" &&
      this.state.account.compteCommunication.telephone !== "" &&
      !this.state.account.compteCommunication.telephone.includes("_") &&
      this.state.termOfUseCheck &&
      this.state.account.compteConnexion.motDePasse ===
        this.state.passwordConfirm &&
      this.state.notRobot &&
      !this.state.showEmailError &&
      !this.state.showRegexPW &&
      !this.state.showEmailExistError &&
      this.isValidDateNaissanceResident()
    );
  }

  canBeSaveNotResident(): boolean {
    return (
      this.state.account.compteInfo.titre !== "" &&
      this.state.account.compteInfo.prenom !== "" &&
      this.state.account.compteInfo.nom !== "" &&
      this.state.account.compteInfo.langue !== null &&
      this.state.account.compteAdresse.numeroCivique !== "" &&
      this.state.account.compteAdresse.rue !== "" &&
      this.state.account.compteAdresse.ville !== "" &&
      this.state.account.compteAdresse.codePostal !== "" &&
      this.state.account.compteAdresse.codePostal.replaceAll("_", "").length ===
        6 &&
      this.state.account.compteAdresse.provinceId !== null &&
      this.state.account.compteConnexion.courriel !== "" &&
      this.state.account.compteConnexion.motDePasse !== "" &&
      this.state.account.compteCommunication.telephone !== "" &&
      !this.state.account.compteCommunication.telephone.includes("_") &&
      this.state.termOfUseCheck &&
      this.state.account.compteConnexion.motDePasse ===
        this.state.passwordConfirm &&
      this.state.notRobot &&
      !this.state.showEmailError &&
      !this.state.showRegexPW &&
      !this.state.showEmailExistError
    );
  }

  isValidDateNaissanceResident() {
    if (!this.state.account.compteInfo.dateDeNaissance)
      return false;

    let datNow = new Date();
    let difference =
      this.state.account.compteInfo.dateDeNaissance.getTime() -
      datNow.getTime();
    let numberOfDay = (difference / (1000 * 3600 * 24)) * -1;
    const isOverSixteen = numberOfDay >= parseInt(process.env.REACT_APP_RESIDENT_AGE_OVER_DAYS);

    return isOverSixteen;
  }

  async validateFormBeforeSave() {
    if (
      this.state.isResident
        ? !this.canbeSaveIsResident()
        : !this.canBeSaveNotResident()
    ) {
      this.setState({ fieldEmpty: true });
      window.scrollTo(0, 0);
    } else {
      let account = this.state.account;
      account.compteInfo.estResident = this.state.isResident;

      await UserAccountServices.AddUserAccount(account).then((x) => {
        if (x < 299) {
          this.setState({ creationIsDone: true });
        } else {
          this.setState({ creationIsDone: false, errorMsg: i18n.t("app:genericError"), traitementEnCours: false });
        }        
      });
    }
  }
}
