import axios from "axios";
import { IValeur } from "../Models/IValeur";
import i18n from "./i18n";
import Cookies from "universal-cookie";
import { UserInfo } from "../Models/IUserInfo";
import { apiUrl } from "./ApiService";
export class ValeurServices {
  public static async GetAttentionParticuiliere(): Promise<IValeur[]> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/valeur/get-attentionparticuiliere`,
        config
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
}
