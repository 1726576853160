import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface IAdressInformationSubComponentStyles {}

export interface IAdressInformationStyles
  extends IAppBaseStyles<IAdressInformationSubComponentStyles> {}

export interface IAdressInformationStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getAdressInformationClassNames = (
  styles?: IStyleFunctionOrObject<
    IAdressInformationStyleProps,
    IAdressInformationStyles
  >,
  props?: IAdressInformationStyleProps
): IComponentBaseClassNames<
  IAdressInformationStyleProps,
  IAdressInformationStyles,
  IAdressInformationSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IAdressInformationStyleProps,
    IAdressInformationStyles,
    IAdressInformationSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IAdressInformationStyleProps,
  IAdressInformationStyles
> = (props: IAdressInformationStyleProps): IAdressInformationStyles => {
  return {
    root: ["app-AdressInformation", {}],
  };
};
