import { Label, Stack } from "@fluentui/react";
import * as React from "react";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ReservationServices } from "../../Services/ReservationServices";
import { Reservations } from "../Reservations";
import { IHistoryProps, IHistoryState } from "./History.types";

export class HistoryComponent extends React.Component<
  IHistoryProps,
  IHistoryState
> {
  constructor(props: IHistoryProps) {
    super(props);
    this.state = { isLoading: false, miniReservations: [] };
  }
  async componentDidMount() {
    LoadingServices.setLoading("History", true);
    let rc = await ReservationServices.GetReservationHistorique();
    this.setState({ miniReservations: rc, isLoading: false });
    LoadingServices.setLoading("History", false);
  }

  render(): JSX.Element {
    if (this.state.isLoading) {
      return <Stack>
          
        </Stack>;
    } else if (this.state.miniReservations.length === 0) {
      return (
        <Stack style={{ marginLeft: "25px", marginTop: "25px" }}>
          <Label style={{ fontSize: "18px" }}>
            {i18n.t("CB:Label:HistoryNoItem")}
          </Label>
        </Stack>
      );
    } else {
      return (
        <>
          <Stack>
            <Label style={{ marginLeft: "25px", marginTop: "25px" }}>
                {i18n.t("CB:Label:HistoryNotice")}
            </Label>          
          </Stack>

          <Reservations
            {...this.props}
            miniReservations={this.state.miniReservations}
            isCurrent={false}
          />
        </>
      );
    }
  }
}
