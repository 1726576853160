import { styled } from "@fluentui/react/lib/Utilities";
import { IHomeProps } from "./Home.types";
import { getStyles, IHomeStyleProps, IHomeStyles } from "./Home.styles";
import { HomeComponent } from "./Home.base";

/**
 * Home
 */
export const Home = styled<IHomeProps, IHomeStyleProps, IHomeStyles>(
  HomeComponent,
  getStyles
);
