import axios from "axios";
import Cookies from "universal-cookie";
import { ICategorieVehicle } from "../Models/ICategorieVehicle";
import { UserInfo } from "../Models/IUserInfo";
import { IVehicle } from "../Models/IVehicle";
import { apiUrl } from "./ApiService";
export class ClientVehicleServices {
  public static async GetAll(): Promise<IVehicle[]> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/clientvehicule/getlist-vehicule`,
        config
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetVehicle(id: string): Promise<IVehicle> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/clientvehicule/get-vehicule/${id}`,
        config
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async EditVehicle(vehicle: IVehicle): Promise<number> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.put<any>(
        `${apiUrl}/api/clientvehicule/edit-vehicule`,
        vehicle,
        config
      );
      result = x.status;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async AddVehicle(vehicle: IVehicle): Promise<number> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.post<any>(
        `${apiUrl}/api/clientvehicule/add-vehicule`,
        vehicle,
        config
      );

      result = x.status;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async Delete(id: number): Promise<number> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.delete<any>(
        `${apiUrl}/api/clientvehicule/delete-vehicule/${id}`,
        config
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetCategorie(): Promise<ICategorieVehicle[]> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/valeur/get-categorievehicule`,
        config
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
}
