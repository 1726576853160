import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface ISignInSubComponentStyles {}

export interface ISignInStyles
  extends IAppBaseStyles<ISignInSubComponentStyles> {
  logo: IStyle;
}

export interface ISignInStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getSignInClassNames = (
  styles?: IStyleFunctionOrObject<ISignInStyleProps, ISignInStyles>,
  props?: ISignInStyleProps
): IComponentBaseClassNames<
  ISignInStyleProps,
  ISignInStyles,
  ISignInSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    ISignInStyleProps,
    ISignInStyles,
    ISignInSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<ISignInStyleProps, ISignInStyles> = (
  props: ISignInStyleProps
): ISignInStyles => {
  return {
    logo: {
      maxHeight: 50,
    },
  };
};
