import { styled } from "@fluentui/react/lib/Utilities";
import { IAppRouterProps } from "./AppRouter.types";
import {
  getStyles,
  IAppRouterStyleProps,
  IAppRouterStyles,
} from "./AppRouter.styles";
import { AppRouterComponent } from "./AppRouter.base";

/**
 * AppRouter
 */
export const AppRouter = styled<
  IAppRouterProps,
  IAppRouterStyleProps,
  IAppRouterStyles
>(AppRouterComponent, getStyles);
