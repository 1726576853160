import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface INvxCustomBorderRadiusStackSubComponentStyles {}

export interface INvxCustomBorderRadiusStackStyles
  extends IAppBaseStyles<INvxCustomBorderRadiusStackSubComponentStyles> {}

export interface INvxCustomBorderRadiusStackStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getNvxCustomBorderRadiusStackClassNames = (
  styles?: IStyleFunctionOrObject<
    INvxCustomBorderRadiusStackStyleProps,
    INvxCustomBorderRadiusStackStyles
  >,
  props?: INvxCustomBorderRadiusStackStyleProps
): IComponentBaseClassNames<
  INvxCustomBorderRadiusStackStyleProps,
  INvxCustomBorderRadiusStackStyles,
  INvxCustomBorderRadiusStackSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    INvxCustomBorderRadiusStackStyleProps,
    INvxCustomBorderRadiusStackStyles,
    INvxCustomBorderRadiusStackSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  INvxCustomBorderRadiusStackStyleProps,
  INvxCustomBorderRadiusStackStyles
> = (
  props: INvxCustomBorderRadiusStackStyleProps
): INvxCustomBorderRadiusStackStyles => {
  return {
    root: ["app-NvxCustomBorderRadiusStack", {}],
  };
};
