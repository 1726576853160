import { styled } from "@fluentui/react/lib/Utilities";
import { ILoginInformationProps } from "./LoginInformation.types";
import {
  getStyles,
  ILoginInformationStyleProps,
  ILoginInformationStyles,
} from "./LoginInformation.styles";
import { LoginInformationComponent } from "./LoginInformation.base";

/**
 * LoginInformation
 */
export const LoginInformation = styled<
  ILoginInformationProps,
  ILoginInformationStyleProps,
  ILoginInformationStyles
>(LoginInformationComponent, getStyles);
