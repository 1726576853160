import { styled } from "@fluentui/react/lib/Utilities";
import { IMyVehiclesProps } from "./MyVehicles.types";
import {
  getStyles,
  IMyVehiclesStyleProps,
  IMyVehiclesStyles,
} from "./MyVehicles.styles";
import { MyVehiclesComponent } from "./MyVehicles.base";

/**
 * MyVehicles
 */
export const MyVehicles = styled<
  IMyVehiclesProps,
  IMyVehiclesStyleProps,
  IMyVehiclesStyles
>(MyVehiclesComponent, getStyles);
