/**
 * Application Theme Loading
 * prepares fluent ui theme and application global tokens
 */

import { ITheme, loadTheme } from "@fluentui/react";
import { IAppTokens } from "./Base/IAppTokens";

/* You can create themes at
 https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/index.html
*/

export const getAppTheme = (): ITheme => {
  const theme: ITheme = loadTheme({
    palette: {
      themePrimary: "#0078d4",
      themeLighterAlt: "#eff6fc",
      themeLighter: "#deecf9",
      themeLight: "#c7e0f4",
      themeTertiary: "#71afe5",
      themeSecondary: "#2b88d8",
      themeDarkAlt: "#106ebe",
      themeDark: "#005a9e",
      themeDarker: "#004578",
      neutralLighterAlt: "#faf9f8",
      neutralLighter: "#f3f2f1",
      neutralLight: "#edebe9",
      neutralQuaternaryAlt: "#e1dfdd",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c6c4",
      neutralTertiary: "#a19f9d",
      neutralSecondary: "#605e5c",
      neutralPrimaryAlt: "#3b3a39",
      neutralPrimary: "#323130",
      neutralDark: "#201f1e",
      black: "#000000",
      white: "#ffffff",
    },
  });

  return theme;
};

export const theme = getAppTheme();

export interface IAppCustomizations {
  theme: ITheme;
  tokens: IAppTokens;
}

const AppTokens: IAppTokens = {};

export const getAppCustomizations = (): IAppCustomizations => {
  return { theme: theme, tokens: AppTokens };
};
