import { styled } from "@fluentui/react/lib/Utilities";
import { IReservationsProps } from "./Reservations.types";
import {
  getStyles,
  IReservationsStyleProps,
  IReservationsStyles,
} from "./Reservations.styles";
import { ReservationsComponent } from "./Reservations.base";

/**
 * Reservations
 */
export const Reservations = styled<
  IReservationsProps,
  IReservationsStyleProps,
  IReservationsStyles
>(ReservationsComponent, getStyles);
