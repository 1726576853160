import { styled } from "@fluentui/react/lib/Utilities";
import { ISignUpProps } from "./SignUp.types";
import { getStyles, ISignUpStyleProps, ISignUpStyles } from "./SignUp.styles";
import { SignUpComponent } from "./SignUp.base";

/**
 * SignUp
 */
export const SignUp = styled<ISignUpProps, ISignUpStyleProps, ISignUpStyles>(
  SignUpComponent,
  getStyles
);
