import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IServicesSupplementaireSubComponentStyles {}

export interface IServicesSupplementaireStyles
  extends IAppBaseStyles<IServicesSupplementaireSubComponentStyles> {}

export interface IServicesSupplementaireStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getServicesSupplementaireClassNames = (
  styles?: IStyleFunctionOrObject<
    IServicesSupplementaireStyleProps,
    IServicesSupplementaireStyles
  >,
  props?: IServicesSupplementaireStyleProps
): IComponentBaseClassNames<
  IServicesSupplementaireStyleProps,
  IServicesSupplementaireStyles,
  IServicesSupplementaireSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IServicesSupplementaireStyleProps,
    IServicesSupplementaireStyles,
    IServicesSupplementaireSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IServicesSupplementaireStyleProps,
  IServicesSupplementaireStyles
> = (
  props: IServicesSupplementaireStyleProps
): IServicesSupplementaireStyles => {
  return {};
};
