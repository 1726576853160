class LoadingServices {
  private loadingItems: { [id: string]: boolean };

  constructor() {
    this.loadingItems = {};
  }

  setLoading = (key: string, loading: boolean): void => {
    this.loadingItems[key] = loading;
    document.body.classList.toggle("is-loading", this.getLoading());
  };

  private getLoading = (): boolean => {
    for (const key in this.loadingItems) {
      if (this.loadingItems[key]) {
        return true;
      }
    }
    return false;
  };
}

export default new LoadingServices();
