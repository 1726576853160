import i18n from "./i18n";
import axios from "axios";
import Cookies from "universal-cookie";
import { ICartItem } from "../Models/ICartItem";
import { IMiniPassenger } from "../Models/IMiniPassenger";
import { IMiniReservation } from "../Models/IMiniReservation";
import { IPaiementPanier } from "../Models/IPaiementPanier";
import { IServiceSupplementaires } from "../Models/IServiceSupplementaires";
import { ISommaireReservation } from "../Models/ISommaireReservation";
import { UserInfo } from "../Models/IUserInfo";
import { IVehiculeSelectedReservation } from "../Models/IVehiculeSelectedReservation";
import { IReservation } from "../Models/IReservation";
import { IVehiculeDisponnible } from "../Models/IVehiculeDisponnible";
import { apiUrl } from "./ApiService";
import { IReservationTransferEnCours } from "../Models/IReservationTransferEnCours";
import { IPaiementTimer } from "../Models/IPaiementTimer";
import { IParametreSurreservation } from "../Models/IParametreSurreservation";
import { IVehiculesInformationSurreservation } from "../Models/IVehiculesInformationSurreservation";
import { IAddToCartResponse } from "../Models/IAddToCartResponse";
export class ReservationServices {
  public static async GetNombreItemPanier(): Promise<number> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/reservation/panier-nombre-items`,
        config
      );

      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetClientPassager(
    noPortOrigine: number,
    noPortDestination: number,
    noPeriode: number
  ): Promise<IMiniPassenger[]> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/reservation/get-client-passager/${noPortOrigine}/${noPortDestination}/${noPeriode}`,
        config
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetSommaireReservation(
    noPortOrigine: number,
    noPortDestination: number,
    noVoyage: string,
    estAllerRetour: boolean,
    serviceSupplementaireCodes: string[],
    passagerIds: string[],
    vsr: IVehiculeSelectedReservation[],
    tutor: string
  ): Promise<ISommaireReservation> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };
    try {
      const x = await axios.post<any>(
        `${apiUrl}/api/reservation/sommaire-reservation`,
        {
          noPortOrigine: noPortOrigine,
          noPortDestination: noPortDestination,
          noVoyage: noVoyage,
          estAllerRetour: estAllerRetour,
          serviceSupplementaireCodes: serviceSupplementaireCodes,
          passagerIds: passagerIds,
          vehicules: vsr,
          tuteur: tutor,
        },
        config
      );

      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async AddReservation(
    noPortOrigine: number,
    noPortDestination: number,
    noVoyage: string,
    estAllerRetour: boolean,
    serviceSupplementaireCodes: any[],
    passagerIds: string[],
    vsr: IVehiculeSelectedReservation[],
    tutor: string
  ): Promise<IAddToCartResponse> {
    let result: IAddToCartResponse = new IAddToCartResponse();
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };
    try {
      const x = await axios.post<any>(
        `${apiUrl}/api/reservation/add-reservation`,
        {
          noPortOrigine: noPortOrigine,
          noPortDestination: noPortDestination,
          noVoyage: noVoyage,
          estAllerRetour: estAllerRetour,
          serviceSupplementaireCodes: serviceSupplementaireCodes,
          passagerIds: passagerIds,
          vehicules: vsr,
          tuteur: tutor,
        },
        config
      );
      result.code = x.status;
      result.message = (x.data.erreurs) ? x.data.erreurs[0] : '';
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async DemarrerPaiement(): Promise<IPaiementTimer> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/reservation/demarrer-paiement`,
        config
      );
      result = x.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetItemPanier(): Promise<ICartItem> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/reservation/panier-reservations`,
        config
      );

      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetReservation(
    reservationId: string
  ): Promise<IReservation> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/reservation/get-reservation/` + reservationId,
        config
      );

      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetReservationCourantes(): Promise<IMiniReservation[]> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/reservation/reservations-courantes`,
        config
      );

      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetReservationTransfertEnCours(): Promise<
    IReservationTransferEnCours[]
  > {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/reservation/transfert-en-cours`,
        config
      );

      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetReservationHistorique(): Promise<IMiniReservation[]> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/reservation/historique-reservations`,
        config
      );

      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetServicesSupplementaires(
    noPortDestination: number,
    noPeriode: number
  ): Promise<
    IServiceSupplementaires[]
  > {
    let result: any | undefined;
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/reservation/get-services-supplementaires/${i18n.getLanguage()}/${noPortDestination}/${noPeriode}`,
      );

      result = x.data.data.serviceSupplementaires;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async PayerPanier(
    paiementPanier: IPaiementPanier
  ): Promise<any> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    try {
      const x = await axios.post<any>(
        `${apiUrl}/api/reservation/payer-panier`,
        {
          nomCarte: paiementPanier.nomCarte,
          numeroCarte: paiementPanier.numeroCarte,
          codeValidation: paiementPanier.codeValidation,
          expiration: paiementPanier.expiration,
          montantPayer: paiementPanier.montantPayer,
        },
        config
      );

      result = x;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async DeleteItem(id: string): Promise<number> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };
    try {
      const x = await axios.delete<any>(
        `${apiUrl}/api/reservation/delete-reservation/${id}`,
        config
      );

      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async AnnulerReservation(id: string): Promise<boolean> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };
    try {
      const x = await axios.delete<any>(
        `${apiUrl}/api/reservation/annuler-reservation/${id}`,
        config
      );

      result = x.data.succes;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetVehiculeDisponnible(
    noPeriode: number
  ): Promise<IVehiculeDisponnible> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/reservation/get-client-vehicule/${noPeriode}`,
        config
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetVehiculeInformationSurrerservation(
    param: IParametreSurreservation
  ): Promise<IVehiculesInformationSurreservation> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };
    try {
      const x = await axios.post<any>(
        `${apiUrl}/api/reservation/vehicules-information-surreservation`,
        param,
        config
      );

      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
}
