import {
  Checkbox,
  DefaultButton,
  IProcessedStyleSet,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { getSignInClassNames, ISignInStyles } from "./SignIn.styles";
import { ISignInState, ISignInProps } from "./SignIn.types";
import i18n from "../../Services/i18n";
import { Link, Navigate } from "react-router-dom";
import { CompteUtilisateur } from "../../Models/ICompteUtilisateur";
import { UserAccountServices } from "../../Services/UserAccountServices";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";

export class SignInComponent extends React.Component<
  ISignInProps,
  ISignInState
> {
  constructor(props: ISignInProps) {
    super(props);
    this.state = {
      redirect: false,
      fail: false,
    };
  }

  private _userInfo: CompteUtilisateur = {
    nomUsager: "",
    motDePasse: "",
    seSouvenirDeMoi: false,
  };

  renderDesktop(classNames: IProcessedStyleSet<ISignInStyles>) {
    return (
      <Stack horizontal style={{ overflow: "hidden" }}>
        <Stack.Item
          grow={1}
          style={{
            maxHeight: window.innerHeight - 0.5,
          }}
        >
          <img
            src="/img/BellaDesgagnes.jpg"
            style={{
              maxHeight: window.innerHeight,
              height: "100vh",
              width: "100%",
              minWidth: "100%",
            }}
          />
        </Stack.Item>
        <Stack.Item
          grow={2}
          style={{ marginLeft: "5px", maxHeight: window.innerHeight }}
        >
          {this.renderInput(classNames)}
        </Stack.Item>
      </Stack>
    );
  }

  renderMobile(classNames: IProcessedStyleSet<ISignInStyles>) {
    return (
      <Stack style={{ marginLeft: "5px", marginBottom: "25px" }}>
        {this.renderInput(classNames)}
      </Stack>
    );
  }

  renderInput(classNames: IProcessedStyleSet<ISignInStyles>) {
    return (
      <Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal>
            <Link to="/" style={{ marginTop: "15px", marginRight: "20px" }}>
              <img className={classNames.logo} src="/img/logo-Bella.svg" />
            </Link>
          </Stack>
          <Stack style={{ marginRight: "25px" }}>
            <Label
              style={{ cursor: "pointer" }}
              onClick={() => {
                let lang = i18n.getLanguage();
                i18n.changeLanguage(lang);
                this.forceUpdate();
              }}
            >
              {i18n.t("app:translation")}
            </Label>
          </Stack>
        </Stack>

        <h1 style={{ marginTop: "40px", marginBottom: "40px" }}>
          {i18n.t("SignIn:H_Title:Connexion")}
        </h1>

        <Stack>
          <p
            style={{
              color: "red",
              maxWidth: "75%",
              padding: "0px",

              position: "absolute",
              marginTop: "-25px",

              paddingRight: "30px",
              paddingLeft: "5px",
            }}
          >
            {this.state.fail ? i18n.t("SignIn:MsgError:LoginFail") : ""}
          </p>
        </Stack>

        <Stack>
          <Stack
            style={{
              border: "1px solid black",
              borderRadius: 10,
              width: "90%",
              marginTop: "25px",
            }}
          >
            <h4
              style={{
                position: "absolute",
                marginTop: "-10px",
                marginLeft: "15px",
                backgroundColor: "white",
                paddingRight: "30px",
                paddingLeft: "5px",
              }}
            >
              {i18n.t("SignIn:TextFieldLabel:UserName")}
            </h4>
            <Stack style={{ margin: "5px", marginTop: "10px" }}>
              <TextField
                autoFocus
                borderless
                onChange={(e, v) => {
                  if (v !== undefined) {
                    this._userInfo.nomUsager = v;
                  }
                }}
              />
            </Stack>
          </Stack>
          <Stack>
            <Stack
              style={{
                border: "1px solid black",
                borderRadius: 10,
                width: "90%",
                marginTop: "25px",
              }}
            >
              <h4
                style={{
                  position: "absolute",
                  marginTop: "-10px",
                  marginLeft: "15px",
                  backgroundColor: "white",
                  paddingRight: "30px",
                  paddingLeft: "5px",
                  marginBottom: "52px",
                }}
              >
                {i18n.t("SignIn:TextFieldLabel:PassWord")}
              </h4>
              <Stack style={{ margin: "5px", marginTop: "10px" }}>
                <TextField
                  type="password"
                  canRevealPassword
                  revealPasswordAriaLabel="Show password"
                  borderless
                  onChange={(e, v) => {
                    if (v !== undefined) {
                      this._userInfo.motDePasse = v;
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.UserConnexion();
                    }
                  }}
                />
              </Stack>
            </Stack>

            <Link
              style={{
                cursor: "pointer",
                marginTop: "10px",
                color: this.props.theme.palette.blueLight,
              }}
              to="/forgetpassword"
            >
              {i18n.t("SignIn:TextFieldLabel:PassWordForget")}
            </Link>
          </Stack>

          <DefaultButton
            onClick={() => {
              this.UserConnexion();
            }}
            style={{
              marginTop: "50px",
              color: "white",
              backgroundColor: this.props.theme.palette.blueDark,
              borderRadius: 10,
              height: "50px",
              width: "250px",
              marginBottom: "10px",
            }}
          >
            {i18n.t("SignIn:Button:Connexion")}
          </DefaultButton>
          <Stack>
            <Checkbox
              label={i18n.t("SignIn:Checkbox:RememberMe")}
              onChange={(e, value) => {
                this._userInfo.seSouvenirDeMoi = value;
              }}
            />
          </Stack>

          <Stack style={{ marginTop: "10px" }}>
            <h3>{i18n.t("SignIn:H_Title:NoAccount")} </h3>
            <Link to="/signup">
              <DefaultButton
                style={{
                  borderRadius: 10,
                  height: "50px",
                  width: "250px",
                }}
              >
                {i18n.t("SignIn:Button:CreationCompte")}
              </DefaultButton>
            </Link>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames } = getSignInClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    if (this.state.redirect) {
      return <Navigate to="/" />;
    } else {
      return this.props.mobile
        ? this.renderMobile(classNames)
        : this.renderDesktop(classNames);
    }
  }

  async UserConnexion() {
    LoadingServices.setLoading("UserConnexion", true);
    const status = await UserAccountServices.UserConnexion(this._userInfo);
    if (status < 299) {
      this.setState({ redirect: true });
    } else if (status > 299 && status < 499) {
      this.setState({ fail: true });
    }
    LoadingServices.setLoading("UserConnexion", false);
  }
}
