export class ValidationServices {

    public static ImmatriculationIsValid(value: string, onKeyDown: boolean = false): Boolean {
        const minLen = onKeyDown ? 0 : 1
        if (value == null)
            return false;
        if (value.length  < minLen || value.length > 8)
            return false;
        const regex = /^[A-Za-z0-9\- ]*$/;
        const isValid = regex.test(value);
        if (!isValid)
            return false;
        return true;
    }
  }
  