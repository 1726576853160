import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface IContactUsSubComponentStyles {}

export interface IContactUsStyles
  extends IAppBaseStyles<IContactUsSubComponentStyles> {}

export interface IContactUsStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getContactUsClassNames = (
  styles?: IStyleFunctionOrObject<IContactUsStyleProps, IContactUsStyles>,
  props?: IContactUsStyleProps
): IComponentBaseClassNames<
  IContactUsStyleProps,
  IContactUsStyles,
  IContactUsSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IContactUsStyleProps,
    IContactUsStyles,
    IContactUsSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IContactUsStyleProps,
  IContactUsStyles
> = (props: IContactUsStyleProps): IContactUsStyles => {
  return {
    root: ["app-ContactUs", {}],
  };
};
