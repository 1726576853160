import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface ITicketingSubComponentStyles {}

export interface ITicketingStyles
  extends IAppBaseStyles<ITicketingSubComponentStyles> {}

export interface ITicketingStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getTicketingClassNames = (
  styles?: IStyleFunctionOrObject<ITicketingStyleProps, ITicketingStyles>,
  props?: ITicketingStyleProps
): IComponentBaseClassNames<
  ITicketingStyleProps,
  ITicketingStyles,
  ITicketingSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    ITicketingStyleProps,
    ITicketingStyles,
    ITicketingSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  ITicketingStyleProps,
  ITicketingStyles
> = (props: ITicketingStyleProps): ITicketingStyles => {
  return {
    root: ["app-Ticketing", {}],
  };
};
