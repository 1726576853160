import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "universal-cookie";

export class HttpClient {
  private http: AxiosInstance;
  private onResolveAccessToken: () => string | undefined;

  constructor(
    onAxiosInstanceCreated?: (http: AxiosInstance) => AxiosInstance,
    onResolveAccessToken?: () => string | undefined,
  ) {
    this.http = onAxiosInstanceCreated
      ? onAxiosInstanceCreated(axios.create())
      : axios.create();
    this.onResolveAccessToken = onResolveAccessToken ?? this.getDefaultToken;
    this.useDefaultInterceptor(axios);
  }

  public useDefaultInterceptor(http: AxiosInstance): void {
    var _cookies = new Cookies();
    http.interceptors.response.use(
      (response: AxiosResponse<any>) => {
        if (
          response &&
          response.status === 419 &&
          document.location.href.indexOf("/signin") === -1 &&
          document.location.href.indexOf("/logout") === -1
        ) {
          _cookies.remove("userInfo");
          document.location.href = "/signin";
        }
        return response;
      },
      (error: any) => {
        if (
          error &&
          error.response &&
          error.response.status === 419 &&
          document.location.href.indexOf("/signin") === -1 &&
          document.location.href.indexOf("/logout") === -1
        ) {
          _cookies.remove("userInfo");
          document.location.href = "/signin";
        }
        return error.response;
      },
    );
  }

  public static create(
    onAxiosInstanceCreated?: (http: AxiosInstance) => AxiosInstance,
    onResolveAccessToken?: () => string | undefined,
  ): HttpClient {
    return new HttpClient(onAxiosInstanceCreated, onResolveAccessToken);
  }

  private getDefaultToken(): string | undefined {
    const x = localStorage.getItem("token");
    return !x || x == null ? undefined : x;
  }

  public isAuthenticated = (): boolean => {
    return this.onResolveAccessToken() !== undefined;
  };

  public async post<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
    raw?: boolean,
  ): Promise<T> {
    const x = await this.http.post(url, data, this.asConfigured(config));
    return raw ? x : x.data;
  }

  private asConfigured(config?: AxiosRequestConfig) {
    const token = this.onResolveAccessToken();
    return (
      config ?? {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
              "X-Requested-With": "XMLHttpRequest",
            }
          : {
              "X-Requested-With": "XMLHttpRequest",
            },
      }
    );
  }

  public async get<T>(
    url: string,
    config?: AxiosRequestConfig,
    raw?: boolean,
  ): Promise<T> {
    const x = await this.http.get(url, this.asConfigured(config));
    return raw ? x : x.data;
  }
}
