import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface ILayoutBaseSubComponentStyles {}

export interface ILayoutBaseStyles
  extends IAppBaseStyles<ILayoutBaseSubComponentStyles> {
  line: IStyle;
}

export interface ILayoutBaseStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getLayoutBaseClassNames = (
  styles?: IStyleFunctionOrObject<ILayoutBaseStyleProps, ILayoutBaseStyles>,
  props?: ILayoutBaseStyleProps
): IComponentBaseClassNames<
  ILayoutBaseStyleProps,
  ILayoutBaseStyles,
  ILayoutBaseSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    ILayoutBaseStyleProps,
    ILayoutBaseStyles,
    ILayoutBaseSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  ILayoutBaseStyleProps,
  ILayoutBaseStyles
> = (props: ILayoutBaseStyleProps): ILayoutBaseStyles => {
  return {
    root: ["app-LayoutBase", {}],
    line: {
      "border-bottom-style": "solid",
      "border-bottom-width": "thin",
      "border-bottom-color": "darkblue",
    },
  };
};
