export default {
  "app:title": "Application anglais",
  "app:Version": " -- Alpha version : 2 -- ",
  "app:translation": "ENG/FR",
  "app:title:browser": "Relais Nordik Inc : Ticket online",
  "app:footerDR": "@ {year} Relais Nordik Inc All rights reserved",
  "app:footerConfidentialite": "Privacy",
  "app:footerContactUs": "Contact us ",
  "app:genericError": "An error occured, please communicate with us at 1 800 463-0680",
  //#REGION SignUp Page

  "SignUp:CheckBox:Condition": "I have read and accept ",
  "SignUp:CheckBox:ConditionLink": " the conditions",
  "SignUp:CheckBox:Yes": "Yes",
  "SignUp:CheckBox:Francais": "French",
  "SignUp:CheckBox:Anglais": "English",
  "SignUp:H_Title:CreeCompte": "Create an account",

  "SignUp:H_Title:Relais": "Relais",
  "SignUp:H_Title:NordikInc": "Nordik Inc.",
  "SignUp:Button:Save": "Save",
  "SignUp:Modal:Text1": "Hello !",
  "SignUp:Modal:Text2": "Your account has been successfully created.",
  "SignUp:Modal:Text3": "You will shortly receive an email activating your account at Relais Nordik Inc.",
  "SignUp:Modal:Text5": "If you haven't received anything yet, ",
  "SignUp:Modal:TextLink1": " click here",
  "SignUp:Modal:Obligatoire": "You must enter all required fields.",

  //#ENDREGION SignUp Page

  //#REGION SignIn Page

  "SignIn:H_Title:Relais": "Relais",
  "SignIn:H_Title:NordikInc": "Nordik Inc.",
  "SignIn:H_Title:Connexion": "Login",
  "SignIn:H_Title:NoAccount": "You do not have an account ?",
  "SignIn:TextFieldLabel:UserName": "Email",
  "SignIn:TextFieldLabel:UserNameForget": "Forgot login ID ?",
  "SignIn:TextFieldLabel:PassWord": "Password",
  "SignIn:TextFieldLabel:PassWordForget": "Forgot your password ?",
  "SignIn:Button:Connexion": "Log in",
  "SignIn:Button:CreationCompte": "Create an account",
  "SignIn:Checkbox:RememberMe": "Remember me",
  "SignIn:MsgError:LoginFail": "Connection failed, wrong login or password.",

  //#ENDREGION SignIn Page

  //#REGION ForgetPassword Page
  "ForgetPassword:H_Title:Relais": "Relais",
  "ForgetPassword:H_Title:Nordik": "Nordik Inc.",
  "ForgetPassword:H_Title:H1": "Forgot your password?",

  "ForgetPassword:Label:1":
    "Please enter your email address and we will send you a ",
  "ForgetPassword:Label:2": "link to reset your password.",
  "ForgetPassword:DefaultButton:Placeholder": "Email",
  "ForgetPassword:DefaultButton:Text": "send >",

  //#ENDREGION ForgetPassword Page

  // #REGION Communication Information
  "CommunicationInformation:H_Title:Communication": "Communication",
  "CommunicationInformation:TextFieldLabel:Telephone": "Phone :",
  "CommunicationInformation:TextFieldLabel:Cellulaire": "Mobile :",
  "CommunicationInformation:TextFieldLabel:Email": "Email :",
  "CommunicationInformation:EndMessage":
    "I wish to receive email communication from Relais Nordik Inc.",

  // #ENDREGION Communication Information

  // #REGION Adress Information
  "AdressInformation:H_Title:Adress": "Address",
  "AdressInformation:TextFieldLabel:NumeroCivique": "Civic number :",
  "AdressInformation:TextFieldLabel:Rue": "Street :",
  "AdressInformation:TextFieldLabel:Appartement": "Appartment :",
  "AdressInformation:TextFieldLabel:CassierPostal": "PO Box :",
  "AdressInformation:TextFieldLabel:Ville": "City :",
  "AdressInformation:TextFieldLabel:Province": "Province :",
  "AdressInformation:TextFieldLabel:CodePostal": "Postal code :",
  "AdressInformation:TextFieldLabel:CodePostal:Mask": "Post\\al Code:a9a9a9",
  "AdressInformation:TextFieldLabel:SameAdresse": "Same address as customer",
  // #ENDREGION Adress Information

  // #REGION Login Information
  "LoginInformation:H_Title:InformationConex": "Login",
  "LoginInformation:TextFieldLabel:Email": "Email :",
  "LoginInformation:TextFieldLabel:Password": "Password :",
  "LoginInformation:TextFieldLabel:PasswordConfirm": "Confirm :",
  "LoginInformation:MsgError:RegexEmail":
    "Your password must contain 8 characters, including an uppercase letter, a lowercase letter, a number and a special character.",
  "LoginInformation:MsgError:ConfirmPW":
    "The confirmation password must be identical to the password past",
  "LoginInformation:MsgError:EmailAlreadyUse": "Your email is already in use",
  "LoginInformation:MsgError:Connect": "Login",
  "LoginInformation:MsgError:EmailInvalid": "Your email is invalid",
  "LoginInformation:MsgError:IsTooYoung": "Must be 16 years old or more",
  // #ENDREGION Login Information

  // confirmation mdp {i18n.t("Paiement:Title:Timer")}
  "ConfirmationPassword:Titre": "Confirmation of your new password",
  "ConfirmationPassword:MotDePasse": "Password: ",
  "ConfirmationPassword:Sauvegarder": "Save",
  "ConfirmationPassword:ConfirmerMotDePasse": "Confirm : ",
  "ConfirmationPassword:MotDePasseValidation": "Your password contains at least eight characters, including at least one number and includes both lower and uppercase letters and special characters.",
  "ConfirmationPassword:MotDePasseDifferent": "Your password and confirmation password do not match.",

  //#REGION CONFIRMATION ACCOUNT
  "CA:H3:1": "Thank you !",
  "CA:H3:2": "Your account can now be used.",
  "CA:H3:3": "Click here to start using your account",

  //ENDREGION CONFIRMATION ACCOUNT

  // #REGION Basic Information
  "BasicInformation:H_Title:InformationPerso": "Personal information",
  "BasicInformation:H_Title:InformationPersoPassager": "Personal",
  "BasicInformation:TextFieldLabel:Title": "Title :",
  "BasicInformation:TextFieldLabel:FirstName": "First name :",
  "BasicInformation:TextFieldLabel:Name": "Last name :",
  "BasicInformation:TextFieldLabel:LangueCommunication":
    "Language for browsing and communication :",
  "BasicInformation:CheckBox:EstResident": "Is a resident :",
  "BasicInformation:ChoiceGroupe:French": "French",
  "BasicInformation:ChoiceGroupe:English": "English",
  "BasicInformation:Checkbox:label": "Yes",
  "BasicInformation:DateDeNaissance:label": "Date of birth :",
  "BasicInformation:Gender:label": "Gender :",
  "BasicInformation:DateDeNaissance:Placeholder": "Select a date",
  "BasicInformation:AgeCategory:Label": "Age Category :",
  "BasicInformation:ChoiceGroupe:Male": "Male",
  "BasicInformation:ChoiceGroupe:Feminine": "Female",
  "BasicInformation:ChoiceGroupe:Yes": "Yes",
  "BasicInformation:ChoiceGroupe:No": "No",
  "BasicInformation:ChoiceGroupe:AbbreviationMister": "MR",
  "BasicInformation:ChoiceGroupe:AbbreviationMiss": "MS",
  "BasicInformation:PsgToApproveMsg": "The Lower-Northern-Shore resident status must be manually approved by a member of the Relais Nordik team. This approval might take up to 48h during which you will not be able to book a ticket online for this passenger.",

  // #ENDREGION Basic Information

  //#REGION Vehicle

  "Vehicle:title:Conteneurisable": "Containerizable vehicle",
  "Vehicle:title:Attente": "Vehicle awaiting approval",
  "Vehicle:button:add": "Add a vehicle",

  "Vehicle:title:DeleteQuestion": "",
  "Vehicle:P:DeleteQuestion1": "Are you sure you want to delete this vehicle?",
  "Vehicle:P:DeleteQuestion2": "",
  "Vehicle:P:DeleteQuestionYes": "Yes",
  "Vehicle:P:DeleteQuestionNo": "No",
  "Vehicle:P:DeleteQuestionUpdate": "Update",

  "Vehicle:Title:h3-1": "Vehicle information",
  "Vehicle:Title:h2-1": "Containerizable vehicle definition",
  "Vehicle:DefinitionVehiculeConteneurisable:Label":
    "Refers to a vehicle weighing no more than 3,000 kilograms and measuring no more than 6 metres in length by 2.1 metres in width by 2.1 metres in height. These dimensions correspond to standard containers (twenty-foot equivalent units—TEUs).",
  "Vehicle:label:Immatriculation": "License number",
  "Vehicle:label:Marque": "Brand",
  "Vehicle:label:QcPlate": "Quebec license plate ?",
  "Vehicle:label:BelongsToCustomer": "This vehicle belongs to client ?",
  "Vehicle:label:VehicleType": "Type of vehicle",
  "Vehicle:label:Modele": "Model",
  "Vehicle:label:Annee": "Year",
  "Vehicle:label:Couleur": "Color",
  "Vehicle:Title:h3-2": "Dimensions",
  "Vehicle:label:Poids": "Weight",
  "Vehicle:label:Longueur": "Length",
  "Vehicle:label:Largeur": "Width",
  "Vehicle:label:Hauteur": "Height",
  "Vehicle:Button:Annuler": "Cancel",
  "Vehicle:Button:Sauvegarder": "Save",
  "Vehicle:InputSuffix:KG": "Kg",
  "Vehicle:InputSuffix:Meters": "Meters",

  "Vehicle:label:LongueurError": "Your length must be less than 6",
  "Vehicle:label:LargeurError": "Your width must be less than 2.1",
  "Vehicle:label:HauteurError": "Your height must be less than 2.1",

  "Vehicle:label:Under12HoursVehicle":
    "You can book a vehicle up to Monday at noon of the current trip.",

  //#ENDREGION Vehicle

  //#REGION HEADER
  "AppHeader:label:logOut": "Log out",
  "AppHeader:label:SignUp": "Sign up",
  "AppHeader:label:SignIn": "Sign in",
  "AppHeader:label:UserCo": "Logged in as:",
  "AppHeader:label:Cart": "Cart",

  //#ENDREGION HEADER

  //#REGION MyPassenger
  "Passenger:title:h1-1": "Passenger information",
  "Passenger:title:h3-1": "My passengers",
  "Passenger:title:EnAttenteApprobation": "Passengers awaiting approval",
  "Passenger:Button:Add": "Add a passenger",
  "Passenger:Button:Cancel": "Cancel",
  "Passenger:Button:Save": "Save",

  "Passenger:title:DeleteQuestion": "",
  "Passenger:P:DeleteQuestion1":
    "Are you sure you want to delete this passenger?",
  "Passenger:P:DeleteQuestion2": "",
  "Passenger:P:DeleteQuestionYes": "Yes",
  "Passenger:P:DeleteQuestionNo": "No",
  "Passenger:P:DeleteQuestionUpdate": "Update",
  "Passenger:p:InfoPassagerToApprove": "A passenger awaiting approval must be manually approved by a member of the Relais Nordik team as being eligible to the resident discount exclusive to the Lower-Northern-Shore inhabitant. This approval might take up to 48h as per our regular office schedule. You will not be able to book a ticket online for this passenger until they are approved.",
  "Passenger:Label:Tuteur": "Tutor",
  "Passenger:Label:MsgErrorKids": "A child under 2 years old must travel with an adult",

  //#ENDREGION MyPassenger

  //#Region LayoutBase

  "LayoutBase:Name:ConctactUs": "Contact us",
  "LayoutBase:Name:MyInformation": "My information",
  "LayoutBase:Name:Reservation": "Booking",
  "LayoutBase:Name:Calculator": "Calculator",
  "LayoutBase:Name:TM": "Freight transport",
  "LayoutBase:Name:RM": "Merchandise Complaint",

  "LayoutBase:Link:ConctactUs": "Contact us",
  "LayoutBase:Link:MyProfil": "My profile",
  "LayoutBase:Link:MyPassengers": "My Passengers",
  "LayoutBase:Link:MyVehicles": "My Vehicles",
  "LayoutBase:Link:RPV": "Passenger and/or vehicle booking",
  "LayoutBase:Link:CurrentReservation": "My current bookings",
  "LayoutBase:Link:History": "History",
  "LayoutBase:Link:Calculator": "Cost calculator",
  "LayoutBase:Link:Estimator": "Estimator",
  "LayoutBase:Link:Reservation": "Reservation",
  "LayoutBase:Link:THN": "Non-standard transport",
  "LayoutBase:Link:MarcDang": "Dangerous goods",
  "LayoutBase:Link:RM": "Merchandise Complaint",
  //#EndRegion LayoutBase

  //#Region MyProfil

  "MP:Label:CloseAccount": "I want to close my account",
  "MP:Label:Update": "Update",
  "MP:Label:Cancel": "Cancel",
  "MP:Label:Save": "Save",

  "MP:Modal:h3": "Are you sure you want to deactivate your account?",
  "MP:Modal:Question": "Are you sure you want to deactivate this account.",
  "MP:Modal:Label":
    "Once deactivated, you will no longer be able to access it.",
  "MP:P:DeleteQuestionYes": "Yes",
  "MP:P:DeleteQuestionNo": "No",
  "MP:P:CannotDeleteAccount": "You are currently unable to delete your account since you still have active bookings. If you have any questions, contact us at 418-723-8787 option 2.",
  

  //#ENDREGION MyProfil

  //#Region Attention Particuliere

  "AP:Title:AttentionParticuliere": "Special requirements",
  "AP:Label:MobReduite": " Reduced mobility ",
  "AP:Label:Condition": " Terms : ",
  "AP:Label:PartiAlimentaire": " Nutritional peculiarities :  ",
  "AP:Label:Identification": " Identification :  ",
  "AP:Label:Relation": " Relationship :  ",
  "AP:Label:TelephoneOne": " Phone 1 :  ",
  "AP:Label:TelephoneTwo": " Phone 2 :  ",
  "AP:Label:Autres": "Others:",
  "AP:Label:ContactUrgence": "Emergency contact: ",

  //#EndRegion Attention Particuliere

  //#Region InformationItinerary

  "IF:Titre:InformationIntineraire": "Information and itinerary",
  "IF:Titre:NombrePlaceDisponiblePartie1": "There is currently ",
  "IF:Titre:NombrePlaceDisponiblePartie2": " vehicle spaces left for this path.",
  "IF:Label:PortOrigine": "Port of origin :",
  "IF:Label:PortDestination": "Port of destination :",
  "IF:Label:DateDeDepart": "Date of departure :",
  "IF:Label:Voyage": "Trip number : ",
  "IF:Label:AllerRetour": "Round trip ",
  "IF:Label:isResident": "Is resident ",
  
  "RPV:Message:PassageMaritimeOnly": "Only maritime passage and containerizable vehicle bookings may be purchased through our online service. Cabins and all-included trips can only be booked on the phone (1-800-463-0680).", 
  "RPV:btn:Recommencer": "Start over",
  "RPV:btn:Retour": "Return",
  "RPV:btn:AjoutPanier": "Add to Cart",
  "RPV:btn:Suivant": "Next",
  "RPV:Message:NoVehiculeForRoundTrip": "Unfortunately, it is not possible to book a round trip for a vehicle. You have to make one reservation for each way (downstream and upstream). Click on Return to modify your booking.",
  "RPV:Message:NoPlaceLeft": "Unfortunately, this path is fully booked, there is no space left. To book a vehicle reservation, please select another trip.",
  "RPV:Message:TooManySelectedVehicle1": "There is only ",
  "RPV:Message:TooManySelectedVehicle2": " space(s) left on this trip. Please select the appropriate vehicle.",
  "RPV:Message:OverbookMustChoose1": "There is only ",
  "RPV:Message:OverbookMustChoose2": " space(s) left for the trip ",
  "RPV:Message:OverbookMustChoose3": ". To continue, please delete booking(s) that will not travel in order to make your payment.",
  
  //#EndRegion InformationItinerary

  //#Region ReservationVehicle
  "RV:Label:MatiereDangereuse": "I will not be transporting dangerous goods",
  "RV:Label:Particularite": "Particularities",
  "RV:Label:MatiereDangereuseMsgError":
    "You must confirm that you will not be transporting dangerous goods to continue",

  "RV:PopUp:MatiereDangereuse1":
    "Relais Nordik Inc is subject to the Transportation of Dangerous Goods Act, 1992 and its regulations. Every person is required to declare if they are transporting dangerous goods. There are over 3,600 dangerous goods listed in the Act. Several well-known items, materials or products are included in the list: ",
  "RV:PopUp:li1": " propane and gasoline (camping);",
  "RV:PopUp:li2":
    " fuel for camping equipment (e.g. naphtha or liquefied petroleum gas [LPG])",
  "RV:PopUp:li3": " Fireworks;",
  "RV:PopUp:li4": " Firearms and ammunition;",
  "RV:PopUp:li5": " Batteries that contain acid (except vehicle batteries);",
  "RV:PopUp:li6": " Cleaning products (disinfectants and detergents);",
  "RV:PopUp:li7": " Fire extinguishers;",
  "RV:PopUp:li8": " Air or oxygen cylinders;",
  "RV:PopUp:li9":
    " Any other goods that pose a risk to the safety and health of people, property or the environment.",
  "RV:PopUp:RedMsg":
    " If you are carrying such goods, you must declare it before your embarkation. Any person who fails to mention to Relais Nordik Inc. the dangerous goods he/she is carrying commits an offence under Canadian law and is liable to a fine or prosecution.",

  //#EndRegion ReservationVehicle

  //#Region ServiceSupplementaire

  "SS:Title:ServeiceSupplementaire": "Add-on ",
  //#EndRegion ServiceSupplementaire

  //#Region Sommaire

  "Somaire:Title:Sommaire": "Summary",
  "Somaire:Label:Voyage": "Trip : ",
  "Somaire:Label:Troncon": "Itinerary : ",
  "Somaire:Label:DateDembarquement": "Date of boarding : ",
  "Somaire:Label:CoutPassage": "Passenger fee",
  "Somaire:Label:CoutVehicle": "Vehicle fee",
  "Somaire:Label:CoutSS": "Add-on fee",
  "Somaire:Label:SommaireDesCouts": "Cost summary",
  "Somaire:Label:Passager": "Passengers",
  "Somaire:Label:Vehicules": "Vehicles",
  "Somaire:Label:Services": "Services",
  "Somaire:Label:Total": "Total",
  "Somaire:Label:Tps": "GST",
  "Somaire:Label:Tvq": "QST",
  "Somaire:Label:MontantAPayer": "Total amount",

  //#EndRegion Sommaire

  "LatestNews:Label:Titre" : "Latest news ...",
  "LatestNews:Label:EntetePsg" : "Here is the list of your resident passengers that have been approved in the last 30 days.",
  "LatestNews:Label:NoPassager" : "You do not have any resident passengers who have been approved in the last 30 days.",
  "LatestNews:Label:EnteteVhc" : "Here is the list of your resident vehicles that have been approved in the last 30 days.",
  "LatestNews:Label:NoVehicule" : "You do not have any resident vehicles that have been approved in the last 30 days.",

  //#Region Cart

  "Cart:Title:NoItem": "You have no reservations in your cart.",
  "Cart:Title:MsgBar":
    "Some of your bookings have expired. These bookings have been deleted from your cart.",
    
    "Cart:Title:OverBookingMsgBar":
    "Unable to reserve the vehicle, please refresh your cart.",
  
    "Cart:Title:MsgBarOverbook":
    "Sorry, there are no more spaces available for your vehicle on this segment. The reservation for this vehicle has been removed.",

  "Cart:Title:MyCart": "MY SHOPPING CART",
  "Cart:Title:DeleteItem":
    "Do you want to permanently delete this reservation from the cart?",
  "Cart:Title:DeleteItemQuestion": "Are you sure you want to delete this item?",
  "Cart:Label:Yes": "Yes",
  "Cart:Label:Non": "No",
  "Cart:Label:SousTotal": "SUBTOTAL : ",
  "Cart:Label:Tps": "GST :",
  "Cart:Label:Tvq": "QST :",
  "Cart:Label:Total": "Total :",
  "Cart:Label:SS": "Add-on : ",
  "Cart:Label:SSS": "Add-ons : ",
  "Cart:Label:Vehicule": "Vehicles : ",
  "Cart:Label:Passager": "Passengers : ",
  "Cart:Label:Checkbox1": "I have read and accept  ",
  "Cart:Label:Checkbox2": "the conditions",
  "Cart:Label:Checkbox3": " and refund policies for passengers and vehicles",
  "Cart:Label:AddReservation": "Add a reservation",
  "Cart:Label:Paiement": "Payment",
  "Cart:Button:ReturnToCart": "Return to cart",

  "Cart:Modal:Error400":
    "Payment is declined. Please try again or contact us at  1-800-463-8060",
  "Cart:Modal:Error500": "Technical error, please contact us at 1-800-463-8060",
  //#EndRegion Cart

  //#Region Paiement
  "Paiement:Title:Timesup": "Transaction time expired, please revise your cart.",
  "Paiement:Title:Timer": "Container space for your vehicles are currently reserved for ",
  "Paiement:Title:Paiement": "Payment made to Relais Nordik Inc",
  "Paiement:Label:MontantTotal": "Total amount : ",
  "Paiement:Label:NomProprio": "Card owner : ",
  "Paiement:Label:CartNumber": "Credit card number : ",
  "Paiement:Label:ValidationNumber": "Card verification code ( CVS) :",
  "Paiement:Label:DateDExpiration": "Expiration date: ",
  "Paiement:Label:Email": "Email : ",
  "Paiement:Label:PayerMaintenant": "Pay now ",
  "Paiment:Label:Cancel": "Cancel",
  //#EndRegion Paiement

  //#Region Confirmation
  "Confirmation:Title:Merci": "Thank you",
  "Confirmation:Title:Resumer": "Booking summary  ",
  "Confirmation:Title:Reservation": "Your booking has been saved",
  "Confirmation:Title:Reservation1": "in our system. Thanks for traveling",
  "Confirmation:Title:Reservation3": "with us!",
  "Confirmation:text:R":
    "Thank you for choosing to travel with us! You will recieve <br /> an email shortly containing all the information<br />",
  "Confirmation:text:1":
    "Thank you for your booking!  In the next few moments, you will receive an email with all the essential information concerning your trip and your boarding.",
  "Confirmation:text:2":
    "At any time before boarding, you have access to your documents in the  My current booking section.",
  "Confirmation:text:3":
    "For all other questions, please call us at 1-800-463-0680",

  //#EndRegion Confirmation

  //#Region CurrentBooking
  "CB:Label:History": "History",
  "CB:Label:MontantPayer": "Amount paid",
  "CB:Label:DateDeDepart": "Date of departure",
  "CB:Label:NoConfirmation": "Number of confirmation",
  "CB:Label:HistoryNotice": "Bookings are available only for the current season.",
  "CB:Label:HistoryNoItem": "There is no previous booking in your account.",
  "CB:Label:ResTraitement": "Reservation awaiting acceptance",
  "CB:Label:MRC": "My current bookings",
  "CB:Label:Voyager": "Trip",
  "CB:Label:Reservation": "Reservation",
  "CB:Label:OD": "Origin-Destination",
  "CB:Label:Action": "Cancellation",
  "CB:Label:PolitiquePsg": "Passengers cancellation policy",
  "CB:Label:FirstRulePsg":
    "A confirmation of the reservation is sent to the passenger after the receipt of the payment by credit card for the total cost (100%) of the boarding pass.",
  "CB:Label:SecondRulePsg":
    "100% refundable up to 6 hours before the scheduled arrival time of the ship according to the official schedule displayed (cancellation notice must be sent by email or canceled via your account).",
  
  "CB:Label:PolitiqueVhc": "Vehicle cancellation policy",
  "CB:Label:FirstRuleVhc":
    "A confirmation of the reservation is sent to the passenger after the receipt of the payment by credit card for the total cost of the boarding pass.",
  "CB:Label:SecondRuleVhc":
    "100% refundable up to 2 weeks before the scheduled arrival time of the ship according to the official schedule displayed (cancellation notice must be sent by email or canceled via your account).",
  "CB:Label:ThirdRuleVhc":
    "75% refundable after the 2 weeks delay but before Monday noon preceding the date at which the boat leaves Rimouski to begin his regular trip.",
  
  "CB:H3:ModalDelete": "Would you like to cancel the reservation?",
  "CB:label:Question": "Are you sure you want to delete this reservation?",
  "CB:label:MoreInformationLigne1": "You are about to cancel your entire booking.",
  "CB:label:MoreInformationLigne2": "If you need to cancel one or more passengers of your booking please contact us at 1-800-463-0680",
  "CB:label:ConfirmationAnnulationLigne1": "The reservation has been cancelled. The refund will be automatically applied to your credit card within 3-5 business days.",
  "CB:label:ConfirmationAnnulationLigne2": "For any additional questions, please contact us at 1-800-463-0680.",
  "CB:Label:No": "No",
  "CB:Label:Yes": "Yes",

  //#EndRegion CurrentBooking

  //#Region Calculator
  "Calculator:Title:Cal": "Calculator",
  "Calculator:Title:Passager": "Number of passengers",
  "Calculator:PassagerLabel:Adulte": "Adult (16 to 64 years old)",
  "Calculator:PassagerLabel:Jeunes": "Youth (2 to 16 years old)",
  "Calculator:PassagerLabel:bebe": "Baby (0 - 2 years free)",
  "Calculator:PassagerLabel:Aine": "Senior (65 years and over)",
  "Calculator:Title:Vehicule": "Number of vehicles",
  "Calculator:label:AvertissementVehiculeAllerRetour": "It is not possible to book a round trip for a vehicle. You have to make one reservation for each way (downstream and upstream). ",
  "Calculator:label:Vehicuel": "Containerizable vehicle",
  "Calculator:label:Moto": " Motorcycle, Scooter",
  "Calculator:label:Bike": "Bike",
  "Calculator:Somaire:Passager": "Passengers",
  "Calculator:Somaire:Vehicule": "Vehicles",
  "Calculator:Somaire:Service": "Add-ons",
  "Calculator:Somaire:SousTotal": "Subtotal",
  "Calculator:Somaire:Tps": "GST",
  "Calculator:Somaire:Tvq": "QST",
  "Calculator:Somaire:MontantPayer": "Total amount",
  "Calculator:MontantApprox:Advert":
    "This amount is approximate. The price will be calculated at the end of the reservation.",
  "Calculator:btn:Reserver": "To book",
  "Calculator:btn:Calculer": "Calculate",
  "Calculator:btn:PourPMOnly": "For maritime passages only",

  "Calculator:label:AssuranceSection": "Vehicle insurance:",
  "Calculator:label:ValeurVehicule": "Value of vehicle",
  "Calculator:label:AssuranceQuestion": "Would you like to be informed about vehicle insurance? ",
  "Calculator:label:AssuranceQuestionMandatoryMsg": "(Mandatory answer)",
  "Calculator:label:AssuranceCost": "Estimated cost of insurance",
  "Calculator:label:AssuranceInfo": "To find out more about our insurance, please contact us at 1-800-463-0680 after completing the reservation.",

  //#EndRegion Calculator

  //#Region DocumentReservation
  "DR:Title:DC": "Reservation document",
  "DR:Label:Trocon": "Path :",
  "DR:Label:DE": "Boarding date : ",
  "DR:Label:NR": "Reservation number",
  "DR:Label:Services": "Services",
  "DR:Label:Description": "Description",
  "DR:Label:Billet": "Ticket",
  "DR:Label:PassageMaritime": "Maritime Passage",
  "DR:Label:Vehicule": "Vehicle",
  "DR:Label:Impression": "Document:",
  "DR:Label:FicheReservation": "Reservation form",
  "DR:Label:LettreTransport": "Sea Waybill",
  //#EndRegion DocumentReservation

  //#Region ContactUs
  "ContactUs:Label1:Terminaux": "Warehouses",

  "ContactUs:Label1:Rimouski": "Rimouski",
  "ContactUs:Label1:RNInc": "Relais Nordik Inc. ",
  "ContactUs:Label1:Adress": "17 Ave Lebrun Cp1113 Succ A",
  "ContactUs:Label1:ville": "Rimouski, Québec ",
  "ContactUs:Label1:CodePostal": "G5L 7R1",

  "ContactUs:Label1:PhoneLabel": "Phone :",
  "ContactUs:Label1:Phone1": "418-723-8787",
  "ContactUs:Label1:Phone2": "1-800-463-0680",
  "ContactUs:Label1:Fax": "Fax  : 418-722-9307",

  "ContactUs:Label1:OpenDay": "Open Monday through Friday",
  "ContactUs:Label1:OpenHours": "from 8:30 a.m. to 5 p.m.",
  "ContactUs:Label1:CloseBetween": "Closed between 12 p.m. and 1 p.m.",

  "ContactUs:Label2:SeptIle": "Sept- Iles",
  "ContactUs:Label2:RNInc": "Relais Nordik Inc. ",
  "ContactUs:Label2:Adress": "265 rue des pionniers",
  "ContactUs:Label2:ville": "Sept-Iles, Québec ",
  "ContactUs:Label2:CodePostal": "G4R 4K2",

  "ContactUs:Label2:PhoneLabel": "Telephone :",
  "ContactUs:Label2:Phone1": "418-723-8787",
  "ContactUs:Label2:Phone2": "1-800-463-0680",
  "ContactUs:Label2:Fax": "Fax : 418-722-9307",

  "ContactUs:Label2:OpenDay": "Open Monday through Friday",
  "ContactUs:Label2:OpenHours": "from 8:30 a.m. to 5 p.m.",
  "ContactUs:Label2:CloseBetween": "Closed between 12 p.m. and 1 p.m.",

  "ContactUs:h1:ContactUs": "Contact us",
  "ContactUs:Label:Opinion": "Your opinion on our services",
  "ContactUs:Label:1": "Thank you for taking the time to share your",
  "ContactUs:Label:2": "comments and suggestions with us.",
  "ContactUs:Label:3":
    "Your opinion is important to us and can help us improve our services",
  "ContactUs:link:site": "https://relaisnordik.com/contact",
  //#EndRegion ContactUs

  //#Region Home
  "Home:Label:GoHome": "Home",
  "Home:Label:Create":
    "Did you know that you can receive alerts from the Société des Traversiers du Québec, notifying you of changes to the Maritime service of Relais Nordik?  All you need to do is create an account and subscribe to alerts for your port(s) of embarkation.",
  "Home:Label:PositionDuNavire":"Ship's position",
  //#EndRegion Home

  "Privacy:Title": "Privacy policy",

  "Message:VehiculePlaceDispo1":"There is currently ",
  "Message:VehiculePlaceDispo2":" vehicle spaces left for this path."
};
