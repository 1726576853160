import { styled } from "@fluentui/react/lib/Utilities";
import { IPassengerProps } from "./Passenger.types";
import {
  getStyles,
  IPassengerStyleProps,
  IPassengerStyles,
} from "./Passenger.styles";
import { PassengerComponent } from "./Passenger.base";

/**
 * AddPassenger
 */
export const Passenger = styled<
  IPassengerProps,
  IPassengerStyleProps,
  IPassengerStyles
>(PassengerComponent, getStyles);
