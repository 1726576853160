import { styled } from "@fluentui/react/lib/Utilities";
import { IModalWithSimpleBannerProps } from "./ModalWithSimpleBanner.types";
import {
  getStyles,
  IModalWithSimpleBannerStyleProps,
  IModalWithSimpleBannerStyles,
} from "./ModalWithSimpleBanner.styles";
import { ModalWithSimpleBannerComponent } from "./ModalWithSimpleBanner.base";

/**
 * ModalWithSimpleBanner
 */
export const ModalWithSimpleBanner = styled<
  IModalWithSimpleBannerProps,
  IModalWithSimpleBannerStyleProps,
  IModalWithSimpleBannerStyles
>(ModalWithSimpleBannerComponent, getStyles);
