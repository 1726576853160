import { styled } from "@fluentui/react/lib/Utilities";
import { IVehicleProps } from "./Vehicle.types";
import {
  getStyles,
  IVehicleStyleProps,
  IVehicleStyles,
} from "./Vehicle.styles";
import { VehicleComponent } from "./Vehicle.base";

/**
 * Vehicle
 */
export const Vehicle = styled<
  IVehicleProps,
  IVehicleStyleProps,
  IVehicleStyles
>(VehicleComponent, getStyles);
