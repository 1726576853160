import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface ICartSubComponentStyles {}

export interface ICartStyles extends IAppBaseStyles<ICartSubComponentStyles> {}

export interface ICartStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getCartClassNames = (
  styles?: IStyleFunctionOrObject<ICartStyleProps, ICartStyles>,
  props?: ICartStyleProps
): IComponentBaseClassNames<
  ICartStyleProps,
  ICartStyles,
  ICartSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    ICartStyleProps,
    ICartStyles,
    ICartSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<ICartStyleProps, ICartStyles> = (
  props: ICartStyleProps
): ICartStyles => {
  return {
    root: ["app-Cart", {}],
  };
};
