import { IconButton, Modal, Stack } from "@fluentui/react";
import * as React from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import i18n from "../../Services/i18n";
import { getModalWithSimpleBannerClassNames } from "./ModalWithSimpleBanner.styles";
import {
  IModalWithSimpleBannerProps,
  IModalWithSimpleBannerState,
} from "./ModalWithSimpleBanner.types";

export class ModalWithSimpleBannerComponent extends React.Component<
  IModalWithSimpleBannerProps,
  IModalWithSimpleBannerState
> {
  constructor(props: IModalWithSimpleBannerProps) {
    super(props);
    this.state = { redirect: false };
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { redirect } = this.state;
    const { classNames, subComponentStyles } =
      getModalWithSimpleBannerClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    if (redirect) {
      return <Navigate to={this.props.redirectUrlToClose} />;
    } else {
      return (
        <Modal isOpen={this.props.isOpen}>
          <Stack
            style={{
              minWidth: this.props.mobile ? window.innerWidth - 50 : "",
              minHeight: this.props.mobile ? window.innerHeight - 50 : "",
              marginBottom: "30px",
            }}
          >
            <Stack
              horizontal
              horizontalAlign="space-between"
              className={classNames.modalLine}
              style={{ minWidth: this.props.mobile ? "" : "500px" }}
            >
              <Stack horizontal>
                <img
                  style={{ margin: "15px" }}
                  className={classNames.logo}
                  src="/img/logo-Bella.svg"
                />
              </Stack>
              <Stack horizontalAlign="end">
                {this.props.redirectUrlToClose ? (
                  <Link to={this.props.redirectUrlToClose}>
                    <IconButton
                      className={classNames.iconButtonStyles}
                      iconProps={{ iconName: "Cancel" }}
                      ariaLabel="Close popup modal"
                      onClick={() => {
                        if (this.props.onClose)
                          this.props.onClose();
                      }}
                    />
                  </Link>
                ) : (
                  <IconButton
                    className={classNames.iconButtonStyles}
                    iconProps={{ iconName: "Cancel" }}
                    ariaLabel="Close popup modal"
                    onClick={() => {
                      if (this.props.onClose)
                        this.props.onClose();
                    }}
                  />
                )}
              </Stack>
            </Stack>
            {this.props.render}
          </Stack>
        </Modal>
      );
    }
  }
}
