import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IInformationItinerarySubComponentStyles {}

export interface IInformationItineraryStyles
  extends IAppBaseStyles<IInformationItinerarySubComponentStyles> {}

export interface IInformationItineraryStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getInformationItineraryClassNames = (
  styles?: IStyleFunctionOrObject<
    IInformationItineraryStyleProps,
    IInformationItineraryStyles
  >,
  props?: IInformationItineraryStyleProps
): IComponentBaseClassNames<
  IInformationItineraryStyleProps,
  IInformationItineraryStyles,
  IInformationItinerarySubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IInformationItineraryStyleProps,
    IInformationItineraryStyles,
    IInformationItinerarySubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IInformationItineraryStyleProps,
  IInformationItineraryStyles
> = (props: IInformationItineraryStyleProps): IInformationItineraryStyles => {
  return {
    root: ["app-InformationItinerary", {}],
  };
};
