import {
  Checkbox,
  IconButton,
  IProcessedStyleSet,
  Label,
  Modal,
  Stack,
  TextField,
  ChoiceGroup,
  FontSizes
} from "@fluentui/react";
import * as React from "react";
import { IVehiculeSelectedReservation } from "../../Models/IVehiculeSelectedReservation";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ReservationServices } from "../../Services/ReservationServices";
import { CalculateurServices } from "../../Services/CalculateurServices";
import { StylingServices } from "../../Services/StylingServices";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import {
  getReservationVehicleClassNames,
  IReservationVehicleStyles,
} from "./ReservationVehicle.styles";
import {
  IReservationVehicleProps,
  IReservationVehicleState,
} from "./ReservationVehicle.types";

export class ReservationVehicleComponent extends React.Component<
  IReservationVehicleProps,
  IReservationVehicleState
> {
  private _iconButtonStyles = {
    root: {
      color: this.props.theme.palette.neutralPrimary,
    },
    rootHovered: {
      color: this.props.theme.palette.neutralDark,
    },
  };
  constructor(props: IReservationVehicleProps) {
    super(props);
    this.state = {
      vehicleSelectedReservation: [],
      isLoading: true,
      noVehiculeDisponnible: false,
      showModalMd: false,
    };
  }

  async getEstimeAssurance(valeur: number): Promise<number> {
    try {
      const estimer = await CalculateurServices.GetEstimerAssurance(valeur, this.props.noPeriode, this.props.portOrigine, this.props.portDestination);
      return estimer;
    } catch (error) {
      console.error('Erreur lors de la récupération de l’estimation :', error);
      return null; 
    }
  }

  async getVehicles() {
    LoadingServices.setLoading("getVehicles", true);

    let v = await ReservationServices.GetVehiculeDisponnible(
      this.props.noPeriode
    );
    let vehicleSelectedReservation: IVehiculeSelectedReservation[] = [];
    if (!v.delaiDepasserPourVehicule) {
      v.listeVehicules.forEach((x) => {
        if (!x.enAttenteApprobation) {
          let vsr: IVehiculeSelectedReservation = {
            vehiculeId: x.id,
            neContientMatiereDangereuse: false,
            particularite: "",
            showParticularity: false,
            infoAssurance: null,
            selected: false,
            vehiculeLibelle: x.vehiculeLibelle,
          };
          let vehicle = this.props.vehicleSelectedReservation.filter(
            (k) => k.vehiculeId === x.id
          )[0];
          if (vehicle) {
            vsr.neContientMatiereDangereuse =
              vehicle.neContientMatiereDangereuse;
            vsr.particularite = vehicle.particularite;
            vsr.selected = vehicle.selected;
          }
          vehicleSelectedReservation.push(vsr);
        }
      });
    }

    this.setState({
      vehicleSelectedReservation: vehicleSelectedReservation,
      isLoading: false,
      noVehiculeDisponnible: v.delaiDepasserPourVehicule,
    });
    LoadingServices.setLoading("getVehicles", false);
  }

  async componentDidMount() {
    await this.getVehicles();
    await this.props.getPlaceDispo()
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getReservationVehicleClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );

 

    let color = "red";
    if (this.props.informationSurreservation !== null) {
      color = StylingServices.GetGradationColor(this.props.informationSurreservation.nombrePlaceUtilise, this.props.informationSurreservation.nombrePlaceMaximum)
    }
    
    if (this.state.isLoading) {
      return <Stack></Stack>;
    } else if (this.state.noVehiculeDisponnible) {
      return (
        <Label style={{ color: this.props.theme.palette.red }}>
          {i18n.t("Vehicle:label:Under12HoursVehicle")}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{
            marginLeft: "10px",
          }}
        >
          {this.props.informationSurreservation !== null ? (
            <Stack style={{ marginTop: "15px" }}>
              <Label style={{ color: color }}>                
                {i18n.t("Message:VehiculePlaceDispo1")}
                {this.props.informationSurreservation.nombrePlaceDisponible}
                {i18n.t("Message:VehiculePlaceDispo2")}
              </Label>
            </Stack>
          ) : (
            ""
          )}

          {this.props.informationSurreservation.nombrePlaceDisponible < this.state.vehicleSelectedReservation.filter(x=>x.selected).length ? (
            <Stack style={{ marginTop: "15px" }}>
              <Label style={{ color: "red" }}>
                {i18n.t("RPV:Message:TooManySelectedVehicle1")}
                {this.props.informationSurreservation.nombrePlaceDisponible}
                {i18n.t("RPV:Message:TooManySelectedVehicle2")}
              </Label>
            </Stack>
          ) : (
            ""
          )}

          <Stack>
            {this.modalInfoMD(classNames)}
            {this.props.vehicleSelectedReservation.length > 0
              ? this.renderUnderComponent(
                  i18n.t("Vehicle:title:Conteneurisable"),
                  this.props.vehicleSelectedReservation
                )
              : ""}
          </Stack>
        </Stack>
      );
    }
  }

  renderUnderComponent(
    title: string,
    vehicles: IVehiculeSelectedReservation[]
  ) {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={title}
        render={
          <Stack
            style={{
              marginLeft: "5px",
              marginTop: "20px",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            {this.renderVehicles(vehicles)}
          </Stack>
        }
      />
    );
  }

  renderVehicles(vehicles: IVehiculeSelectedReservation[]): JSX.Element[] {

    let element = vehicles.map((x, i) => {
      return (
        <Stack style={{ marginLeft: "15px" }} key={x.vehiculeId}>
          <Stack horizontal horizontalAlign="space-between">
            <Label>
              - {x.vehiculeLibelle}
            </Label>

            <Stack>
              <Checkbox
                checked={x.selected}
                onChange={(e, value) => {
                  let vehicles = this.props.vehicleSelectedReservation;
                  x.showParticularity = !x.showParticularity;
                  if (!value) x.neContientMatiereDangereuse = false;
                  this.props.updateVehiculeSelect(x.vehiculeId, value);
                  this.setState({ vehicleSelectedReservation: vehicles });
                }}
              />
            </Stack>
          </Stack>
          {x.showParticularity ? (
          <Stack>
            <Stack style={{marginTop:'20px', marginBottom:'20px'}}>
              <Label>{i18n.t("Calculator:label:AssuranceSection")}</Label>
              <Stack style={{marginLeft:'20px'}}>
                <Stack horizontal>
                  <Label style={{marginRight:'10px'}}>{i18n.t("Calculator:label:ValeurVehicule")}</Label>
                  <TextField 
                  onChange={async (e, v) => {
                    let value = Number(v);

                    if (value < 0) {
                      value = 0;
                      if (e.target instanceof HTMLInputElement) {
                        e.target.value = value.toString();
                      }
                    }

                    if (value > 999999) {
                      value = 999999;
                      if (e.target instanceof HTMLInputElement) {
                        e.target.value = value.toString();
                      }
                    }

                    const estimation = await this.getEstimeAssurance(value);
                    this.props.updateVehiculeEstimerAssurance(x.vehiculeId, estimation);

                    this.setState({ vehicleSelectedReservation: vehicles });
                  }}
                  onKeyPress={(e) => {
                    const target = e.target as HTMLInputElement;

                    if (!(
                      (e.key >= '0' && e.key <= '9') ||
                      e.key === 'Backspace' || 
                      e.key === 'Tab' || 
                      e.key === 'ArrowLeft' || e.key === 'ArrowRight' ||
                      e.key === 'Enter' 
                    )) {
                      e.preventDefault();
                    }

                    if (target.value.length >= 6 && e.key !== 'Backspace') {
                      e.preventDefault(); 
                    }
                  }}
                  width={'50px'} 
                />
                  <Label style={{marginLeft:'10px'}}>{i18n.t("Calculator:label:AssuranceCost")+ ': ' + ((x.estimeAssurance?.toFixed(2)) ?? '')} $</Label>
                </Stack>
                
                <Label style={{marginTop:'10px'}}>{i18n.t("Calculator:label:AssuranceQuestion")} <span style={{color:"red"}}>{i18n.t("Calculator:label:AssuranceQuestionMandatoryMsg")}</span></Label>
                <ChoiceGroup
                  selectedKey={x.infoAssurance === undefined ? 0 : x.infoAssurance === true ? '1' : '0'}
                  options={[{ key: '1', text: i18n.t("BasicInformation:ChoiceGroupe:Yes") },{ key: '0', text: i18n.t("BasicInformation:ChoiceGroupe:No") }]}
                  onChange={(e, selected) => {
                    let vehicles = this.props.vehicleSelectedReservation;
                    x.infoAssurance = selected.key === '1';
                    this.props.updateVehiculeInfoAssurance(x.vehiculeId, x.infoAssurance);
                    this.setState({ vehicleSelectedReservation: vehicles });
                  }}
                />     

                { x.infoAssurance && <Label style={{color:'red', marginTop:'5px'}}>{i18n.t("Calculator:label:AssuranceInfo")}</Label>}
              
              </Stack>
            </Stack>
            <Stack
              style={{
                marginLeft: "15px",
                marginBottom: "25px",
              }}
            >
              <Stack>
                <Stack style={{ minHeight: "30px" }}>
                  {x.selected && !x.neContientMatiereDangereuse ? (
                    <Label style={{ color: this.props.theme.palette.red }}>
                      {i18n.t("RV:Label:MatiereDangereuseMsgError")}
                    </Label>
                  ) : (
                    ""
                  )}{" "}
                </Stack>

                <Stack horizontal verticalAlign="center">
                  <IconButton
                    style={{ marginRight: "20px" }}
                    iconProps={{ iconName: "FeedbackRequestSolid" }}
                    styles={this._iconButtonStyles}
                    onClick={() => {
                      this.setState({ showModalMd: true });
                    }}
                  />
                  <Label>{i18n.t("RV:Label:MatiereDangereuse")}</Label>
                  <Stack style={{ marginLeft: "15px" }}>
                    <Checkbox
                      checked={x.neContientMatiereDangereuse}
                      onChange={(e, v) => {
                        this.props.updateVehiculeCMD(x.vehiculeId, v);
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>

              <Stack>
                <Label>{i18n.t("RV:Label:Particularite")}</Label>
                <TextField
                  value={x.particularite}
                  multiline
                  onChange={(e, v) => {
                    if (v !== undefined && v.length < 1000) {
                      this.props.updateVehiculeParticularite(x.vehiculeId, v);
                    }
                  }}
                />
              </Stack>
            </Stack></Stack>
          ) : (
            ""
          )}
        </Stack>
      );
    });
    return element;
  }

  modalInfoMD(classNames: IProcessedStyleSet<IReservationVehicleStyles>) {
    return (
      <Modal isOpen={this.state.showModalMd}>
        <Stack
          style={{
            minWidth: this.props.mobile ? window.innerWidth - 50 : "",
            minHeight: this.props.mobile ? window.innerHeight - 50 : "",
            marginBottom: "30px",
          }}
        >
          <Stack
            horizontal
            horizontalAlign="space-between"
            className={classNames.modalLine}
            style={{
              minWidth: this.props.mobile ? "" : "500px",
              maxWidth: "700px",
            }}
          >
            <Stack horizontal>
              <h2 style={{ marginLeft: "15px" }}>
                <img className={classNames.logo} src="/img/logo-Bella.svg" />
              </h2>
            </Stack>
            <Stack horizontalAlign="end">
              <IconButton
                className={classNames.iconButtonStyles}
                iconProps={{ iconName: "Cancel" }}
                ariaLabel="Close popup modal"
                onClick={() => {
                  this.setState({ showModalMd: false });
                }}
              />
            </Stack>
          </Stack>
          <Stack
            style={{
              marginLeft: "20px",
              maxWidth: "600px",
              marginRight: "20px",
              marginTop: "20px",
            }}
            horizontalAlign="center"
          >
            <Label>{i18n.t("RV:PopUp:MatiereDangereuse1")}</Label>
            <ul style={{ marginTop: "10px" }}>
              <li>{i18n.t("RV:PopUp:li1")}</li>
              <li>{i18n.t("RV:PopUp:li2")}</li>
              <li>{i18n.t("RV:PopUp:li3")}</li>
              <li>{i18n.t("RV:PopUp:li4")}</li>
              <li>{i18n.t("RV:PopUp:li5")}</li>
              <li>{i18n.t("RV:PopUp:li6")}</li>
              <li>{i18n.t("RV:PopUp:li7")}</li>
              <li>{i18n.t("RV:PopUp:li8")}</li>
              <li>{i18n.t("RV:PopUp:li9")}</li>
            </ul>
            <Label style={{ marginTop: "10px", color: "red" }}>
              {i18n.t("RV:PopUp:RedMsg")}
            </Label>
          </Stack>
        </Stack>
      </Modal>
    );
  }
}
