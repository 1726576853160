import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface ILegalConditionsSubComponentStyles {}

export interface ILegalConditionsStyles
  extends IAppBaseStyles<ILegalConditionsSubComponentStyles> {}

export interface ILegalConditionsStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getLegalConditionsClassNames = (
  styles?: IStyleFunctionOrObject<
    ILegalConditionsStyleProps,
    ILegalConditionsStyles
  >,
  props?: ILegalConditionsStyleProps
): IComponentBaseClassNames<
  ILegalConditionsStyleProps,
  ILegalConditionsStyles,
  ILegalConditionsSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    ILegalConditionsStyleProps,
    ILegalConditionsStyles,
    ILegalConditionsSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  ILegalConditionsStyleProps,
  ILegalConditionsStyles
> = (props: ILegalConditionsStyleProps): ILegalConditionsStyles => {
  return {
    root: ["app-LegalConditions", {}],
  };
};
