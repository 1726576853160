import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface ISignUpSubComponentStyles {}

export interface ISignUpStyles
  extends IAppBaseStyles<ISignUpSubComponentStyles> {
  logo: IStyle;
  choiceGroupe: IStyle;
  mobile: IStyle;
  desktop: IStyle;
  modalLine: IStyle;
  logoModal: IStyle;
  iconButtonStyles: IStyle;
}

export interface ISignUpStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getSignUpClassNames = (
  styles?: IStyleFunctionOrObject<ISignUpStyleProps, ISignUpStyles>,
  props?: ISignUpStyleProps
): IComponentBaseClassNames<
  ISignUpStyleProps,
  ISignUpStyles,
  ISignUpSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    ISignUpStyleProps,
    ISignUpStyles,
    ISignUpSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<ISignUpStyleProps, ISignUpStyles> = (
  props: ISignUpStyleProps
): ISignUpStyles => {
  return {
    logo: {
      maxHeight: 50,
    },
    choiceGroupe: { display: "inline-flex" },
    mobile: {
      maxwidth: "300px",
    },
    desktop: {
      minwidth: "1200px",
    },
    modalLine: {
      "border-bottom-style": "outset",
      "border-bottom-width": "medium",
      "border-bottom-color": "darkblue",
    },
    logoModal: {
      maxHeight: 20,
    },
    iconButtonStyles: {
      root: {
        color: props.theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
      },
      rootHovered: {
        color: props.theme.palette.neutralDark,
      },
    },
  };
};
