import { styled } from "@fluentui/react/lib/Utilities";
import { IConfirmationPaymentProps } from "./ConfirmationPayment.types";
import {
  getStyles,
  IConfirmationPaymentStyleProps,
  IConfirmationPaymentStyles,
} from "./ConfirmationPayment.styles";
import { ConfirmationPaymentComponent } from "./ConfirmationPayment.base";

/**
 * ConfirmationPayment
 */
export const ConfirmationPayment = styled<
  IConfirmationPaymentProps,
  IConfirmationPaymentStyleProps,
  IConfirmationPaymentStyles
>(ConfirmationPaymentComponent, getStyles);
