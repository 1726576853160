import { styled } from "@fluentui/react/lib/Utilities";
import { IPrivacyPolicyProps } from "./PrivacyPolicy.types";
import {
  getStyles,
  IPrivacyPolicyStyleProps,
  IPrivacyPolicyStyles,
} from "./PrivacyPolicy.styles";
import { PrivacyPolicyComponent } from "./PrivacyPolicy.base";

/**
 * PrivacyPolicy
 */
export const PrivacyPolicy = styled<
  IPrivacyPolicyProps,
  IPrivacyPolicyStyleProps,
  IPrivacyPolicyStyles
>(PrivacyPolicyComponent, getStyles);
