import { styled } from "@fluentui/react/lib/Utilities";
import { IConfirmationPasswordProps } from "./ConfirmationPassword.types";
import {
  getStyles,
  IConfirmationPasswordStyleProps,
  IConfirmationPasswordStyles,
} from "./ConfirmationPassword.styles";
import { ConfirmationPasswordComponent } from "./ConfirmationPassword.base";

/**
 * ConfirmationPassword
 */
export const ConfirmationPassword = styled<
  IConfirmationPasswordProps,
  IConfirmationPasswordStyleProps,
  IConfirmationPasswordStyles
>(ConfirmationPasswordComponent, getStyles);
