import { Label, Stack } from "@fluentui/react";
import * as React from "react";
import i18n from "../../Services/i18n";
import { getPrivacyPolicyClassNames } from "./PrivacyPolicy.styles";
import {
  IPrivacyPolicyState,
  IPrivacyPolicyProps,
} from "./PrivacyPolicy.types";

export class PrivacyPolicyComponent extends React.Component<
  IPrivacyPolicyProps,
  IPrivacyPolicyState
> {
  constructor(props: IPrivacyPolicyProps) {
    super(props);
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getPrivacyPolicyClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <Stack horizontalAlign="center">
        <h1>{i18n.t("Privacy:Title")}</h1>
        <Stack
          horizontalAlign="center"
          style={{
            borderStyle: "solid",
            borderRadius: "5px",
            marginTop: "10px",
            marginLeft: "15px",
            marginRight:"15px",
            paddingBottom:"15px",
            height: "100%",
            width: "100%",
          }}
        >
          <Label>
           1 Sed at arcu gravida tellus commodo consectetur. Fusce congue
            condimentum velit, sed congue ante lacinia mollis. Maecenas commodo
            vitae odio in lacinia. In ornare dapibus purus non viverra. Aliquam
            sed elit elit. Aliquam sodales, sapien sit amet ultrices mollis,
            magna augue ultrices magna, rhoncus ornare justo arcu ac dui.
            Vivamus scelerisque neque nisl, fermentum commodo arcu viverra quis.
            Class aptent taciti sociosqu ad litora torquent per conubia nostra,
            per inceptos himenaeos. Nulla in enim ut magna dignissim feugiat.
            Nulla at dolor eu magna fermentum placerat. Etiam id gravida enim.
            Nullam convallis quis lectus eu ultrices. Nam tristique, ante id
            tempus placerat, erat ligula dapibus neque, malesuada maximus leo
            leo non magna. Etiam id lectus dolor. Nullam nec nisi varius orci
            malesuada iaculis a sed turpis. Cras lobortis dapibus arcu ut
            ullamcorper. Sed mollis mi vel orci cursus, in accumsan nisi varius.
            Cras pellentesque, diam id congue iaculis, odio urna maximus turpis,
            volutpat dictum odio ex id risus. Suspendisse consectetur nunc quis
            dui sollicitudin, in varius dolor lobortis. Suspendisse potenti.
            Praesent nec arcu id erat placerat mattis et sed ligula. Phasellus
            nec leo auctor, rutrum enim in, egestas quam. Nunc tristique justo
            eu varius molestie. Suspendisse ultricies convallis mi id rutrum.
            Morbi cursus nec erat sed convallis. Fusce dapibus ultricies cursus.
            Phasellus tortor augue, volutpat in porttitor non, tempus id magna.
            Donec at semper erat. Donec et augue pretium, elementum nisl et,
            hendrerit eros. Pellentesque vitae porttitor lorem. Fusce eget quam
            nec ligula posuere faucibus vitae vel dui. Curabitur rutrum tellus
            non ligula consectetur imperdiet. Cras vulputate ut turpis a
            molestie. Pellentesque porttitor at ipsum sit amet aliquam. Nulla
            sed aliquet eros, nec placerat ligula. Maecenas urna eros, rutrum
            vitae tortor eu, ultrices tincidunt ex. Sed finibus tempor arcu
            vitae tincidunt. Orci varius natoque penatibus et magnis dis
            parturient montes, nascetur ridiculus mus. Cras quis lorem ut lacus
            bibendum commodo vitae vitae dolor. Ut finibus tellus ut rutrum
            sollicitudin. Quisque finibus mauris nisi, eu vestibulum ipsum
            tempus in. Nunc justo felis, vestibulum id luctus a, fermentum sit
            amet elit. Suspendisse id dui viverra, tempus sapien id, viverra
            justo. Quisque tincidunt, elit in rutrum gravida, orci purus
            pulvinar metus, sit amet aliquet nunc velit a nulla. Quisque auctor
            orci nulla, vitae tempus odio eleifend vel. Donec ac tellus eu elit
            varius placerat in ut erat. Quisque porttitor sollicitudin ante a
            lacinia. Etiam blandit at orci quis bibendum. Curabitur dui odio,
            mollis ac scelerisque non, aliquet vitae nunc. Suspendisse eu ex
            consectetur tortor suscipit porttitor. Curabitur vel viverra sem.
            Nam nec viverra ligula, at imperdiet augue. Morbi viverra sapien vel
            eleifend auctor. Nam tincidunt porta felis, a pretium leo vulputate
            sed. Donec pharetra arcu eget interdum sollicitudin. Proin nec
            lectus dapibus, tempor nibh ut, varius mauris. Duis consectetur
            convallis urna quis tincidunt. Curabitur at blandit quam. In maximus
            pellentesque nisi, at semper nulla ullamcorper non. Nullam nec
            ullamcorper odio. Nulla facilisi. Ut sit amet magna nec est
            elementum laoreet a sit amet libero. Aliquam pretium mi nec urna
            iaculis, quis condimentum enim vehicula.
          </Label>
          <Label>
            lit a nulla. Quisque auctor orci nulla, vitae tempus odio eleifend
            vel. Donec ac tellus eu elit varius placerat in ut erat. Quisque
            porttitor sollicitudin ante a lacinia. Etiam et erat tempor, feugiat
            nibh sodales, condimentum mauris. In hac habitasse platea dictumst.
            Pellentesque habitant morbi tristique senectus et netus et malesuada
            fames ac turpis egestas. Proin consectetur leo purus, in facilisis
            lectus gravida vitae. Integer eget mi a nibh faucibus consequat.
            Phasellus sed molestie sapien. Quisque pellentesque volutpat libero
            id dictum. Fusce quam ex, elementum sit amet rutrum eu, efficitur
            suscipit mauris. Morbi vel libero dapibus, vulputate libero id,
            tempor turpis. Aenean pretium elit mi, ac hendrerit dolor varius sit
            amet. Ut eleifend lacinia dui, a fermentum metus vehicula id. Nam
            eget facilisis tortor. Duis a erat at augue convallis commodo.
            Suspendisse potenti. Proin varius justo vel quam pharetra efficitur.
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia curae; Pellentesque malesuada quam non viverra
            dapibus. Sed vel turpis id elit accumsan interdum id eu ligula. Nam
            id mattis nulla, ut efficitur quam. Aenean eu lorem arcu. Cras vel
            maximus sapien. Proin feugiat neque nec velit varius elementum ac
            quis justo. Praesent blandit at orci quis bibendum. Curabitur dui
            odio, mollis ac scelerisque non, aliquet vitae nunc. Suspendisse eu
            ex consectetur tortor suscipit porttitor. Curabitur vel viverra sem.
            Nam nec viverra ligula, at imperdiet augue. Morbi viverra sapien vel
            eleifend auctor. Nam tincidunt porta felis, a pretium leo vulputate
            sed. Donec pharetra arcu eget interdum sollicitudin. Proin nec
            lectus dapibus, tempor nibh ut, varius mauris. Duis consectetur
            convallis urna quis tincidunt. Curabitur at blandit quam. In maximus
            pellentesque nisi, at semper nulla ullamcorper non. Nullam nec
            ullamcorper odio. Nulla facilisi. Ut sit amet magna nec est
            elementum laoreet a sit amet libero. Aliquam pretium mi nec urna
            iaculis, quis condimentum enim vehicula.
          </Label>
          <Label>
              et erat tempor, feugiat nibh sodales, condimentum mauris. In hac
              habitasse platea dictumst. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Proin
              consectetur leo purus, in facilisis lectus gravida vitae. Integer
              eget mi a nibh faucibus consequat. Phasellus sed molestie sapien.
              Quisque pellentesque volutpat libero id dictum. Fusce quam ex,
              elementum sit amet rutrum eu, efficitur suscipit mauris. Morbi vel
              libero dapibus, vulputate libero id, tempor turpis. Aenean pretium
              elit mi, ac hendrerit dolor varius sit amet. Ut eleifend lacinia
              dui, a fermentum metus vehicula id. Nam eget facilisis tortor.
              Duis a erat at augue convallis commodo. Suspendisse potenti. Proin
              varius justo vel quam pharetra efficitur. Vestibulum ante ipsum
              primis in faucibus orci luctus et ultrices posuere cubilia curae;
              Pellentesque malesuada quam non viverra dapibus. Sed vel turpis id
              elit accumsan interdum id eu ligula. Nam id mattis nulla, ut
              efficitur quam. Aenean eu lorem arcu. Cras vel maximus sapien.
              Proin feugiat neque nec velit varius elementum ac quis justo.
              Praesent{" "}
            </Label>
            <h1> 1 - test</h1>
            <Label>
              et erat tempor, feugiat nibh sodales, condimentum mauris. In hac
              habitasse platea dictumst. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Proin
              consectetur leo purus, in facilisis lectus gravida vitae. Integer
              eget mi a nibh faucibus consequat. Phasellus sed molestie sapien.
              Quisque pellentesque volutpat libero id dictum. Fusce quam ex,
              elementum sit amet rutrum eu, efficitur suscipit mauris. Morbi vel
              libero dapibus, vulputate libero id, tempor turpis. Aenean pretium
              elit mi, ac hendrerit dolor varius sit amet. Ut eleifend lacinia
              dui, a fermentum metus vehicula id. Nam eget facilisis tortor.
              Duis a erat at augue convallis commodo. Suspendisse potenti. Proin
              varius justo vel quam pharetra efficitur. Vestibulum ante ipsum
              primis in faucibus orci luctus et ultrices posuere cubilia curae;
              Pellentesque malesuada quam non viverra dapibus. Sed vel turpis id
              elit accumsan interdum id eu ligula. Nam id mattis nulla, ut
              efficitur quam. Aenean eu lorem arcu. Cras vel maximus sapien.
              Proin feugiat neque nec velit varius elementum ac quis justo.
              Praesent{" "}
            </Label>
            <h1>2 - test</h1>
            <Label>
              et erat tempor, feugiat nibh sodales, condimentum mauris. In hac
              habitasse platea dictumst. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Proin
              consectetur leo purus, in facilisis lectus gravida vitae. Integer
              eget mi a nibh faucibus consequat. Phasellus sed molestie sapien.
              Quisque pellentesque volutpat libero id dictum. Fusce quam ex,
              elementum sit amet rutrum eu, efficitur suscipit mauris. Morbi vel
              libero dapibus, vulputate libero id, tempor turpis. Aenean pretium
              elit mi, ac hendrerit dolor varius sit amet. Ut eleifend lacinia
              dui, a fermentum metus vehicula id. Nam eget facilisis tortor.
              Duis a erat at augue convallis commodo. Suspendisse potenti. Proin
              varius justo vel quam pharetra efficitur. Vestibulum ante ipsum
              primis in faucibus orci luctus et ultrices posuere cubilia curae;
              Pellentesque malesuada quam non viverra dapibus. Sed vel turpis id
              elit accumsan interdum id eu ligula. Nam id mattis nulla, ut
              efficitur quam. Aenean eu lorem arcu. Cras vel maximus sapien.
              Proin feugiat neque nec velit varius elementum ac quis justo.
              Praesent{" "}
            </Label>
        </Stack>
      </Stack>
    );
  }
}
