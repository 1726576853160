import { styled } from "@fluentui/react/lib/Utilities";
import { ICommunicationInformationProps } from "./CommunicationInformation.types";
import {
  getStyles,
  ICommunicationInformationStyleProps,
  ICommunicationInformationStyles,
} from "./CommunicationInformation.styles";
import { CommunicationInformationComponent } from "./CommunicationInformation.base";

/**
 * CommunicationInformation
 */
export const CommunicationInformation = styled<
  ICommunicationInformationProps,
  ICommunicationInformationStyleProps,
  ICommunicationInformationStyles
>(CommunicationInformationComponent, getStyles);
