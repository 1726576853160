import { Label, Shimmer, ShimmerElementType, Stack } from "@fluentui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import i18n from "../../Services/i18n";
import { UserAccountServices } from "../../Services/UserAccountServices";
import { getConfirmationAccountClassNames } from "./ConfirmationAccount.styles";
import {
  IConfirmationAccountProps,
  IConfirmationAccountState,
} from "./ConfirmationAccount.types";

export class ConfirmationAccountComponent extends React.Component<
  IConfirmationAccountProps,
  IConfirmationAccountState
> {
  constructor(props: IConfirmationAccountProps) {
    super(props);
    this.state = {
      isLoading: true,
      succes: false,
      fail: false,
    };
  }

  async componentDidMount() {    
    this.confirmation();
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getConfirmationAccountClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const shimmerWithElementFirstRow = [
      { type: ShimmerElementType.circle },
      { type: ShimmerElementType.gap, width: "24%" },
      { type: ShimmerElementType.line },
    ];

    if (this.state.succes) {
      return (
        <Stack>
          <Stack horizontal className={classNames.modalLine}>
            <h2 style={{ marginLeft: "15px" }}>
              <img className={classNames.logo} src="/img/logo-Bella.svg" />
            </h2>
          </Stack>

          <Stack
            style={{ width: "100%", height: "80vh" }}
            verticalAlign="center"
            horizontalAlign="center"
          >
            <h3>{i18n.t("CA:H3:1")}</h3>
            <h3>{i18n.t("CA:H3:2")}</h3>
            <Link to="/signin">
              <h3>{i18n.t("CA:H3:3")}</h3>
            </Link>
          </Stack>
        </Stack>
      );
    } else if (this.state.isLoading) {
      return (
        <Stack horizontal verticalAlign="center">
          <Label>Loading...</Label>
          <Shimmer shimmerElements={shimmerWithElementFirstRow} />
        </Stack>
      );
    } else if (this.state.fail) {
      return <div>fail</div>;
    }
  }

  async confirmation() {
    let urlSplit = window.location.href.split("?")[1].split("&");
    let email = urlSplit[0].split("=")[1];
    let token = urlSplit[1].split("=")[1];
    let lang = urlSplit[2].split("=")[1];
    i18n.setLanguage(lang);

    let status = await UserAccountServices.Confirmation(email, token);

    switch (status) {
      case 200:
        this.setState({ isLoading: false, succes: true });
        break;

      default:
        break;
    }
  }
}
