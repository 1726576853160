import {
  Checkbox,
  ComboBox,
  DefaultButton,
  Dropdown,
  IComboBoxOption,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import { ICategorieVehicle } from "../../Models/ICategorieVehicle";
import { ClientVehicleServices } from "../../Services/ClientVehicleServices";
import { ValidationServices } from "../../Services/ValidationServices";
import CustomStringServices from "../../Services/CustomStringServices/CustomStringServices";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import { IVehicleProps, IVehicleState } from "./Vehicle.types";

export class VehicleComponent extends React.Component<
  IVehicleProps,
  IVehicleState
> {
  constructor(props: IVehicleProps) {
    super(props);
    this.state = {
      isLoading: true,
      vehicle: {
        id: 0,
        annee: null,
        couleur: null,
        immatriculation: null,
        model: null,
        categorie: "",
        marque: null,
        poidsEnKg: null,
        longueur: null,
        largeur: null,
        hauteur: null,
        immatriculeAuQuebec: false,
        appartientAuClient: false,
      },
      redirect: false,
      fail: false,
      isEdit: false,
      categories: [],
    };
  }

  async componentDidMount() {
    LoadingServices.setLoading("Vehicule", true);
    let cat: ICategorieVehicle[] = await ClientVehicleServices.GetCategorie();
    let cbCv: IComboBoxOption[] = [];
    cat.forEach((x) => {
      cbCv.push({ key: x.code, text: x.description });
    });
    if (window.location.href.includes("=")) {
      let urlSplit = window.location.href.split("/");
      let vehicleId = urlSplit[4].split("=")[1];
      let v = await ClientVehicleServices.GetVehicle(vehicleId);
      if (v !== null) {
        this.setState({
          vehicle: v,
          isEdit: true,
          categories: cbCv,
          isLoading: false,
        });
      }
    } else {
      this.setState({ categories: cbCv, isLoading: false });
    }
    LoadingServices.setLoading("Vehicule", false);
  }

  render(): JSX.Element {
    if (this.state.redirect) {
      return <Navigate to="/myvehicles" />;
    } else if (this.state.isLoading) {
      return <Stack></Stack>;
    } else {
      return this.props.mobile ? this.renderMobile() : this.renderDesktop();
    }
  }

  renderMobile(): JSX.Element {
    return (
      <Stack style={{ marginLeft: "20px" }}>
        <NvxCustomBorderRadiusStack
          {...this.props}
          title={i18n.t("Vehicle:Title:h3-1")}
          render={
            <Stack
              style={{ width: "90%", marginLeft: "15px", marginBottom: "20px" }}
            >
              {this.renderVehicleTypeMobile()}
              {this.renderMark()}
              {this.renderModel()}
              {this.renderYear()}
              {this.renderColor()}
              {this.renderRegistration()}
              {this.renderQcPlate()}
              {this.renderSameClientVehicle()}
            </Stack>
          }
        />
        <NvxCustomBorderRadiusStack
          {...this.props}
          title={i18n.t("Vehicle:Title:h3-2")}
          render={
            <Stack
              style={{ width: "90%", marginLeft: "15px", marginBottom: "20px" }}
            >
              {this.renderWeight()}
              {this.renderLength()}
              {this.renderLenght()}
              {this.renderHeight()}
            </Stack>
          }
        />
        {this.renderInfo()}
        {this.renderBtn()}
      </Stack>
    );
  }

  renderVehicleTypeDesktop() {
    return (
      <ComboBox
        tabIndex={1}
        placeholder={
          this.props.mobile ? i18n.t("Vehicle:label:VehicleType") : ""
        }
        options={this.state.categories}
        selectedKey={this.state.vehicle.categorie}
        onChange={(e, v) => {
          if (v !== undefined) {
            this.state.vehicle.categorie = v.key.toString();
            this.setState({ ...this.state });
          }
        }}
      />
    );
  }

  renderVehicleTypeMobile() {
    return (
      <Dropdown
        placeholder={
          this.props.mobile ? i18n.t("Vehicle:label:VehicleType") : ""
        }
        options={this.state.categories}
        selectedKey={this.state.vehicle.categorie}
        onChange={(e, v) => {
          if (v !== undefined) {
            this.state.vehicle.categorie = v.key.toString();
            this.setState({ ...this.state });
          }
        }}
      />
    );
  }

  renderMark() {
    return (
      <Stack
        style={{
          marginTop: this.props.mobile ? "10px" : "",
        }}
      >
        <TextField
          tabIndex={3}
          placeholder={this.props.mobile ? i18n.t("Vehicle:label:Marque") : ""}
          style={{
            minWidth: "200px",
          }}
          value={this.state.vehicle.marque}
          onChange={(e, v) => {
            if (v !== undefined && v.length < 25) {
              this.state.vehicle.marque = v;
              this.setState({ ...this.state });
            }
          }}
        />
      </Stack>
    );
  }

  renderModel() {
    return (
      <Stack
        style={{
          marginTop: this.props.mobile ? "10px" : "",
        }}
      >
        <TextField
          tabIndex={5}
          placeholder={this.props.mobile ? i18n.t("Vehicle:label:Modele") : ""}
          style={{ minWidth: "200px" }}
          value={this.state.vehicle.model}
          onChange={(e, v) => {
            if (v !== undefined && v.length < 25) {
              this.state.vehicle.model = v;
              this.setState({ ...this.state });
            }
          }}
        />
      </Stack>
    );
  }

  renderYear() {
    return (
      <Stack
        style={{
          marginTop: this.props.mobile ? "10px" : "",
        }}
      >
        <TextField
          tabIndex={7}
          placeholder={this.props.mobile ? i18n.t("Vehicle:label:Annee") : ""}
          style={{ minWidth: "200px" }}
          type="number"
          value={this.state.vehicle.annee?.toString()}
          onChange={(e, v) => {
            if (v !== undefined && v.length < 5) {
              this.state.vehicle.annee = parseFloat(v);
              this.setState({ ...this.state });
            }
          }}
        />
      </Stack>
    );
  }

  renderColor() {
    return (
      <Stack
        style={{
          marginTop: this.props.mobile ? "10px" : "",
        }}
      >
        <TextField
          tabIndex={8}
          placeholder={this.props.mobile ? i18n.t("Vehicle:label:Couleur") : ""}
          style={{ minWidth: "200px" }}
          value={this.state.vehicle?.couleur}
          onChange={(e, v) => {
            if (v !== undefined && v.length < 25) {
              this.state.vehicle.couleur = v;
              this.setState({ ...this.state });
            }
          }}
        />
      </Stack>
    );
  }

  renderRegistration() {
    return (
      <Stack
        style={{
          marginTop: this.props.mobile ? "10px" : "",
        }}
      >
        <TextField
          tabIndex={2}
          placeholder={
            this.props.mobile ? i18n.t("Vehicle:label:Immatriculation") : ""
          }
          value={this.state.vehicle.immatriculation}
          onChange={(e, v) => {
            if (ValidationServices.ImmatriculationIsValid(v, true)) {
              this.state.vehicle.immatriculation = v;
              this.setState({ ...this.state });
            }
          }}
        />
      </Stack>
    );
  }

  renderQcPlate() {
    return (
      <Stack
        style={{
          marginTop: this.props.mobile ? "10px" : "",
        }}
        horizontal
        verticalAlign="center"
      >
        <Label style={{ minWidth: "215px" }}>
          {i18n.t("Vehicle:label:QcPlate")}
        </Label>
        <Checkbox
          inputProps={{ tabIndex: 4 }}
          checked={this.state.vehicle.immatriculeAuQuebec}
          onChange={(e, v) => {
            if (v !== undefined) {
              this.state.vehicle.immatriculeAuQuebec = v;
              this.setState({ ...this.state });
            }
          }}
        />
      </Stack>
    );
  }

  renderSameClientVehicle() {
    return (
      <Stack horizontal style={{ marginTop: "10px" }} verticalAlign="center">
        <Label style={{ minWidth: "215px" }}>
          {i18n.t("Vehicle:label:BelongsToCustomer")}
        </Label>

        <Checkbox
          inputProps={{ tabIndex: 6 }}
          checked={this.state.vehicle.appartientAuClient}
          onChange={(e, v) => {
            if (v !== undefined) {
              this.state.vehicle.appartientAuClient = v;
              this.setState({ ...this.state });
            }
          }}
        />
      </Stack>
    );
  }

  renderWeight() {
    return (
      <Stack
        style={{
          marginTop: this.props.mobile ? "10px" : "",
        }}
      >
        <TextField
          tabIndex={9}
          placeholder={this.props.mobile ? i18n.t("Vehicle:label:Poids") : ""}
          value={
            this.state.vehicle.poidsEnKg?.toString() === ""
              ? ""
              : this.state.vehicle.poidsEnKg?.toString()
          }
          onChange={(e, v) => {
            if (v !== undefined) {
              this.state.vehicle.poidsEnKg =
                CustomStringServices.FormattedPrecisionValue(v);

              if (
                parseInt(this.state.vehicle.poidsEnKg) <= 3000 ||
                this.state.vehicle.poidsEnKg === ""
              ) {
                this.setState({ ...this.state });
              }
            }
          }}
          suffix={i18n.t("Vehicle:InputSuffix:KG")}
        />
      </Stack>
    );
  }

  renderLength() {
    return (
      <Stack
        style={{
          marginTop: this.props.mobile ? "10px" : "",
        }}
        horizontal={!this.props.mobile}
      >
        <TextField
          tabIndex={10}
          placeholder={
            this.props.mobile ? i18n.t("Vehicle:label:Longueur") : ""
          }
          value={this.state.vehicle.longueur?.toString()}
          onChange={(e, v) => {
            if (v !== undefined) {
              this.state.vehicle.longueur =
                CustomStringServices.FormattedPrecisionValue(v);
              this.setState({ ...this.state });
              let isLongeurError: boolean;
              if (parseFloat(v) > 6) {
                isLongeurError = true;
              } else {
                isLongeurError = false;
              }
              this.setState({
                isLongeurError: isLongeurError,
              });
            }
          }}
          suffix={i18n.t("Vehicle:InputSuffix:Meters")}
        />
        {this.state.isLongeurError ? (
          <Label
            style={{
              color: "red",
              marginLeft: !this.props.mobile ? "10px" : "",
            }}
          >
            {i18n.t("Vehicle:label:LongueurError")}
          </Label>
        ) : (
          ""
        )}
      </Stack>
    );
  }

  renderLenght() {
    return (
      <Stack
        style={{
          marginTop: this.props.mobile ? "10px" : "",
        }}
        horizontal={!this.props.mobile}
      >
        <TextField
          tabIndex={11}
          placeholder={this.props.mobile ? i18n.t("Vehicle:label:Largeur") : ""}
          value={this.state.vehicle.largeur?.toString()}
          onChange={(e, v) => {
            if (v !== undefined) {
              this.state.vehicle.largeur =
                CustomStringServices.FormattedPrecisionValue(v);

              let isLargeurError: boolean;
              if (parseFloat(v) > 2.1) {
                isLargeurError = true;
              } else {
                isLargeurError = false;
              }
              this.setState({
                ...this.state,
                isLargeurError: isLargeurError,
              });
            }
          }}
          suffix={i18n.t("Vehicle:InputSuffix:Meters")}
        />
        {this.state.isLargeurError ? (
          <Label
            style={{
              color: "red",
              marginLeft: !this.props.mobile ? "10px" : "",
            }}
          >
            {i18n.t("Vehicle:label:LargeurError")}
          </Label>
        ) : (
          ""
        )}
      </Stack>
    );
  }

  renderHeight() {
    return (
      <Stack
        style={{
          marginTop: this.props.mobile ? "10px" : "",
        }}
        horizontal={!this.props.mobile}
      >
        <TextField
          tabIndex={12}
          placeholder={this.props.mobile ? i18n.t("Vehicle:label:Hauteur") : ""}
          value={this.state.vehicle.hauteur?.toString()}
          onChange={(e, v) => {
            if (v !== undefined) {
              this.state.vehicle.hauteur =
                CustomStringServices.FormattedPrecisionValue(v);

              let isHauteurError: boolean;
              if (parseFloat(v) > 2.1) {
                isHauteurError = true;
              } else {
                isHauteurError = false;
              }
              this.setState({
                ...this.state,
                isHauteurError: isHauteurError,
              });
            }
          }}
          suffix={i18n.t("Vehicle:InputSuffix:Meters")}
        />

        {this.state.isHauteurError ? (
          <Label
            style={{
              color: "red",
              marginLeft: !this.props.mobile ? "10px" : "",
            }}
          >
            {i18n.t("Vehicle:label:HauteurError")}
          </Label>
        ) : (
          ""
        )}
      </Stack>
    );
  }

  renderInfo() {
    return (
      <Stack
        style={{
          border: "1px solid black",
          borderRadius: 30,
          width: "95%",
          marginTop: "25px",
          backgroundColor: this.props.theme.palette.blueDark,
        }}
      >
        <h2 style={{ color: "white", marginLeft: "20px", marginRight: "20px" }}>
          {i18n.t("Vehicle:Title:h2-1")}
        </h2>
        <Label
          style={{
            color: "white",
            marginLeft: "20px",
            marginRight: "20px",
            marginBottom: "20px",
          }}
        >
          <div style={{ textAlign: "justify", textJustify: "inter-word" }}>
            {i18n.t("Vehicle:DefinitionVehiculeConteneurisable:Label")}
          </div>
        </Label>
      </Stack>
    );
  }

  renderDesktop(): JSX.Element {
    return (
      <Stack style={{ marginLeft: "20px", marginBottom: "100px" }}>
        <NvxCustomBorderRadiusStack
          {...this.props}
          title={i18n.t("Vehicle:Title:h3-1")}
          render={
            <Stack
              style={{
                marginLeft: "20px",
                marginTop: "20px",
                marginRight: "20px",
                minHeight: "180px",
                marginBottom: "20px",
              }}
              horizontal
            >
              <Stack
                style={{ minHeight: "200px" }}
                verticalAlign="space-between"
              >
                <Stack horizontal>
                  <Label style={{ minWidth: "130px" }} required>
                    {i18n.t("Vehicle:label:VehicleType")}
                  </Label>
                  {this.renderVehicleTypeDesktop()}
                </Stack>

                <Stack horizontal>
                  <Label required style={{ minWidth: "130px" }}>
                    {i18n.t("Vehicle:label:Marque")}
                  </Label>
                  {this.renderMark()}
                </Stack>
                <Stack horizontal>
                  <Label required style={{ minWidth: "130px" }}>
                    {i18n.t("Vehicle:label:Modele")}
                  </Label>
                  {this.renderModel()}
                </Stack>
                <Stack horizontal>
                  <Label required style={{ minWidth: "130px" }}>
                    {i18n.t("Vehicle:label:Annee")}
                  </Label>
                  {this.renderYear()}
                </Stack>
                <Stack horizontal>
                  <Label required style={{ minWidth: "130px" }}>
                    {i18n.t("Vehicle:label:Couleur")}
                  </Label>
                  {this.renderColor()}
                </Stack>
              </Stack>

              <Stack style={{ marginLeft: "20px" }}>
                <Stack horizontal>
                  <Label required style={{ minWidth: "215px" }}>
                    {i18n.t("Vehicle:label:Immatriculation")}
                  </Label>
                  {this.renderRegistration()}
                </Stack>
                {this.renderQcPlate()}
                {this.renderSameClientVehicle()}
              </Stack>

              <Stack style={{ marginLeft: "20px" }}></Stack>
            </Stack>
          }
        />
        <NvxCustomBorderRadiusStack
          {...this.props}
          title={i18n.t("Vehicle:Title:h3-2")}
          render={
            <Stack
              style={{
                marginLeft: "20px",
                marginTop: "20px",
                marginRight: "20px",
                minHeight: "200px",
                marginBottom: "20px",
              }}
              horizontal
            >
              <Stack verticalAlign="space-between">
                <Stack horizontal>
                  <Label required style={{ minWidth: "90px" }}>
                    {i18n.t("Vehicle:label:Poids")}
                  </Label>
                  {this.renderWeight()}
                </Stack>
                <Stack horizontal>
                  <Label required style={{ minWidth: "90px" }}>
                    {i18n.t("Vehicle:label:Longueur")}
                  </Label>
                  {this.renderLength()}
                </Stack>
                <Stack horizontal>
                  <Label required style={{ minWidth: "90px" }}>
                    {i18n.t("Vehicle:label:Largeur")}
                  </Label>
                  {this.renderLenght()}
                </Stack>
                <Stack horizontal>
                  <Label required style={{ minWidth: "90px" }}>
                    {i18n.t("Vehicle:label:Hauteur")}
                  </Label>
                  {this.renderHeight()}
                </Stack>
              </Stack>
            </Stack>
          }
        />

        {this.renderInfo()}
        {this.renderBtn()}
      </Stack>
    );
  }

  renderBtn() {
    return (
      <Stack
        horizontal
        horizontalAlign={this.props.mobile ? "space-between" : "end"}
        style={{ width: "95%", marginBottom: "25px" }}
      >
        <Link to="/myvehicles">
          <DefaultButton
            style={{
              color: "white",
              backgroundColor: this.props.theme.palette.blueDark,
              borderRadius: 10,
              height: "50px",
              width: this.props.mobile ? "150px" : "250px",
              marginTop: "25px",
            }}
          >
            {i18n.t("Vehicle:Button:Annuler")}
          </DefaultButton>
        </Link>

        <DefaultButton
          disabled={!this.canNotSave()}
          style={{
            color: "white",
            backgroundColor: this.canNotSave()
              ? this.props.theme.palette.blueDark
              : this.props.theme.palette.neutralLight,
            borderRadius: 10,
            height: "50px",
            width: this.props.mobile ? "150px" : "250px",
            marginTop: "25px",
            marginLeft: "10px",
          }}
          onClick={() => {
            this.onSaveVehicle();
          }}
        >
          {i18n.t("Vehicle:Button:Sauvegarder")}
        </DefaultButton>
      </Stack>
    );
  }

  canNotSave(): boolean {
    return !(
      this.state.vehicle.annee === null ||
      this.state.vehicle?.annee?.toString() === "NaN" ||
      this.state.vehicle?.annee < 1900 ||
      this.state.vehicle?.annee > new Date().getFullYear() + 1 ||
      this.state.vehicle.couleur === null ||
      this.state.vehicle?.couleur?.toString() === "" ||
      !ValidationServices.ImmatriculationIsValid(this.state.vehicle.immatriculation) ||
      this.state.vehicle.model === null ||
      this.state.vehicle?.model?.toString() === "" ||
      this.state.vehicle.marque === null ||
      this.state.vehicle?.marque?.toString() === "" ||
      this.state.vehicle.poidsEnKg === null ||
      this.state.vehicle.poidsEnKg === "" ||
      this.state.vehicle?.poidsEnKg?.toString() === "NaN" ||
      this.state.vehicle.longueur === null ||
      this.state.vehicle.longueur === "" ||
      this.state.vehicle?.longueur?.toString() === "NaN" ||
      this.state.vehicle.largeur === null ||
      this.state.vehicle.largeur === "" ||
      this.state.vehicle?.largeur?.toString() === "NaN" ||
      this.state.vehicle.hauteur === null ||
      this.state.vehicle.hauteur === "" ||
      this.state.vehicle?.hauteur?.toString() === "NaN" ||
      this.state.isHauteurError ||
      this.state.isLargeurError ||
      this.state.isLongeurError
    );
  }

  async onSaveVehicle() {
    LoadingServices.setLoading("onSaveVehicle", true);
    let status: number;

    if (this.state.isEdit) {
      status = await ClientVehicleServices.EditVehicle(this.state.vehicle);
    } else {
      status = await ClientVehicleServices.AddVehicle(this.state.vehicle);
    }

    if (status < 299) {
      this.setState({ redirect: true });
    }
    LoadingServices.setLoading("onSaveVehicle", false);
  }
}
