import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import {
  IAppBaseStyleProps,
  IAppBaseStyles,
  IAppTokens,
} from "../../../src/Base";

export interface IDocumentReservationSubComponentStyles {}

export interface IDocumentReservationStyles
  extends IAppBaseStyles<IDocumentReservationSubComponentStyles> {}

export interface IDocumentReservationStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getDocumentReservationClassNames = (
  styles?: IStyleFunctionOrObject<
    IDocumentReservationStyleProps,
    IDocumentReservationStyles
  >,
  props?: IDocumentReservationStyleProps
): IComponentBaseClassNames<
  IDocumentReservationStyleProps,
  IDocumentReservationStyles,
  IDocumentReservationSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IDocumentReservationStyleProps,
    IDocumentReservationStyles,
    IDocumentReservationSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IDocumentReservationStyleProps,
  IDocumentReservationStyles
> = (props: IDocumentReservationStyleProps): IDocumentReservationStyles => {
  return {
    root: ["app-DocumentReservation", {}],
  };
};
