import { styled } from "@fluentui/react/lib/Utilities";
import { ILastNewsProps } from "./LastNews.types";
import {
  getStyles,
  ILastNewsStyleProps,
  ILastNewsStyles,
} from "./LastNews.styles";
import { LastNewsComponent } from "./LastNews.base";

/**
 * LastNews
 */
export const LastNews = styled<
  ILastNewsProps,
  ILastNewsStyleProps,
  ILastNewsStyles
>(LastNewsComponent, getStyles);
