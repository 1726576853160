import { styled } from "@fluentui/react/lib/Utilities";
import { IAdressInformationProps } from "./AdressInformation.types";
import {
  getStyles,
  IAdressInformationStyleProps,
  IAdressInformationStyles,
} from "./AdressInformation.styles";
import { AdressInformationComponent } from "./AdressInformation.base";

/**
 * AdressInformation
 */
export const AdressInformation = styled<
  IAdressInformationProps,
  IAdressInformationStyleProps,
  IAdressInformationStyles
>(AdressInformationComponent, getStyles);
