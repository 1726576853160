import { DefaultButton, IconButton, Label, Stack } from "@fluentui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import { IVehicle } from "../../Models/IVehicle";
import { ClientVehicleServices } from "../../Services/ClientVehicleServices";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ModalWithSimpleBanner } from "../ModalWithSimpleBanner";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import { IMyVehiclesProps, IMyVehiclesState } from "./MyVehicles.types";
export class MyVehiclesComponent extends React.Component<
  IMyVehiclesProps,
  IMyVehiclesState
> {
  async getVehicles() {
    LoadingServices.setLoading("getVehicles", true);
    let v = await ClientVehicleServices.GetAll();
    let vehiclesApprouved: IVehicle[] = [];
    let vehiclesNotApprouved: IVehicle[] = [];
    v.forEach((x) => {
      if (x.enAttenteApprobation) {
        vehiclesNotApprouved.push(x);
      } else {
        vehiclesApprouved.push(x);
      }
    });

    this.setState({
      vehiclesApprouved: vehiclesApprouved,
      vehiclesNotApprouved: vehiclesNotApprouved,
      isLoading: false,
      vehicleSelected: undefined,
      showModal: false,
    });
    LoadingServices.setLoading("getVehicles", false);
  }

  async componentDidMount() { 
    await this.getVehicles();
  }

  constructor(props: IMyVehiclesProps) {
    super(props);
    this.state = {
      vehiclesApprouved: [],
      vehiclesNotApprouved: [],
      showModal: false,
      isRedirect: false,
      isLoading: true,
    };
  }

  render(): JSX.Element {
    return (
      <Stack
        style={{
          marginLeft: "10px",
        }}
      >
        {this.state.isLoading ? (
          ""
        ) : (
          <Stack>
            {this.state.vehiclesApprouved.length > 0
              ? this.renderUnderComponent(
                  i18n.t("Vehicle:title:Conteneurisable"),
                  this.state.vehiclesApprouved
                )
              : ""}

            {this.state.vehiclesNotApprouved.length > 0
              ? this.renderUnderComponent(
                  i18n.t("Vehicle:title:Attente"),
                  this.state.vehiclesNotApprouved
                )
              : ""}
          </Stack>
        )}
        <Stack>
          <Link
            to="/vehicle"
            style={{
              marginTop: "25px",
              height: "50px",
              width: "250px",
            }}
          >
            <DefaultButton
              style={{
                color: "white",
                backgroundColor: this.props.theme.palette.blueDark,
                borderRadius: 10,
                height: "50px",
                width: "250px",
              }}
            >
              {i18n.t("Vehicle:button:add")}
            </DefaultButton>
          </Link>
          {this.renderModal()}
        </Stack>
      </Stack>
    );
  }

  renderModal() {
    return (
      <Stack>
        <ModalWithSimpleBanner
          onClose={() => {
            this.setState({ showModal: false });
          }}
          {...this.props}
          isOpen={this.state.showModal}
          redirectUrlToClose={"/myvehicles"}
          render={
            <Stack style={{ marginLeft: "25px" }}>
              <h2 style={{ borderBottom: "solid", marginRight: "25px" }}>
                {i18n.t("Vehicle:title:DeleteQuestion")}
              </h2>
              <p>{i18n.t("Vehicle:P:DeleteQuestion1")}</p>
              <p>{i18n.t("Vehicle:P:DeleteQuestion2")}</p>
              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ marginRight: "75px", marginLeft: "50px" }}
              >
                <DefaultButton
                  style={{
                    color: "white",
                    backgroundColor: this.props.theme.palette.blueDark,
                    borderRadius: 10,
                    height: "40px",
                    width: "150px",
                  }}
                  onClick={() => {
                    this.setState({ showModal: false });
                  }}
                >
                  {i18n.t("Vehicle:P:DeleteQuestionNo")}
                </DefaultButton>
                <DefaultButton
                  style={{
                    color: "white",
                    backgroundColor: this.props.theme.palette.blueDark,
                    borderRadius: 10,
                    height: "40px",
                    width: "150px",
                  }}
                  onClick={async () => {
                    await ClientVehicleServices.Delete(
                      this.state.vehicleSelected.id
                    );
                    this.getVehicles();
                  }}
                >
                  {i18n.t("Vehicle:P:DeleteQuestionYes")}
                </DefaultButton>
              </Stack>
            </Stack>
          }
        />
      </Stack>
    );
  }

  renderUnderComponent(title: string, vehicles: IVehicle[]) {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={title}
        render={
          <Stack
            style={{
              marginLeft: "5px",
              marginTop: "20px",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            {this.renderVehicles(vehicles)}
          </Stack>
        }
      />
    );
  }

  renderVehicles(vehicles: IVehicle[]): JSX.Element[] {
    const iconButtonStyles = {
      root: {
        color: this.props.theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        width: "10px",
      },
      rootHovered: {
        color: this.props.theme.palette.neutralDark,
      },
    };

    let element = vehicles.map((x, i) => {
      let linkId = "/vehicle/id=" + x.id;

      return (
        <Stack style={{ marginLeft: "15px" }} key={x.id}>
          <Stack horizontal horizontalAlign="space-between">
            <Label>- {x.vehiculeLibelle}</Label>

            <Stack horizontal verticalAlign="center">
              {x.peutEtreModifier ? (
                <Link
                  to={linkId}
                  onClick={() => {
                    this.setState({ showModal: true, vehicleSelected: x });
                  }}
                >
                  {i18n.t("Vehicle:P:DeleteQuestionUpdate")}
                </Link>
              ) : (
                ""
              )}
              {x.peutEtreSupprimer ? (
                <IconButton
                  style={{ marginLeft: "20px" }}
                  iconProps={{ iconName: "trash" }}
                  styles={iconButtonStyles}
                  onClick={() => {
                    this.setState({ showModal: true, vehicleSelected: x });
                  }}
                />
              ) : (
                ""
              )}
            </Stack>
          </Stack>
        </Stack>
      );
    });
    return element;
  }
}
