import { styled } from "@fluentui/react/lib/Utilities";
import { IReservationPassengerVehicleProps } from "./ReservationPassengerVehicle.types";
import {
  getStyles,
  IReservationPassengerVehicleStyleProps,
  IReservationPassengerVehicleStyles,
} from "./ReservationPassengerVehicle.styles";
import { ReservationPassengerVehicleComponent } from "./ReservationPassengerVehicle.base";

/**
 * ReservationPassengerVehicle
 */
export const ReservationPassengerVehicle = styled<
  IReservationPassengerVehicleProps,
  IReservationPassengerVehicleStyleProps,
  IReservationPassengerVehicleStyles
>(ReservationPassengerVehicleComponent, getStyles);
