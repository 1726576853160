import { styled } from "@fluentui/react/lib/Utilities";
import { IConfirmationAccountProps } from "./ConfirmationAccount.types";
import {
  getStyles,
  IConfirmationAccountStyleProps,
  IConfirmationAccountStyles,
} from "./ConfirmationAccount.styles";
import { ConfirmationAccountComponent } from "./ConfirmationAccount.base";

/**
 * ConfirmationAccount
 */
export const ConfirmationAccount = styled<
  IConfirmationAccountProps,
  IConfirmationAccountStyleProps,
  IConfirmationAccountStyles
>(ConfirmationAccountComponent, getStyles);
