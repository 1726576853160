import {
  Checkbox,
  ChoiceGroup,
  ComboBox,
  DatePicker,
  Dropdown,
  IChoiceGroupOption,
  IDatePickerStrings,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";
import { DateTime } from "luxon";
import * as React from "react";
import CustomStringServices from "../../Services/CustomStringServices/CustomStringServices";
import DateServices from "../../Services/DateServices/DateService";
import i18n from "../../Services/i18n";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import {
  IBasicInformationProps,
  IBasicInformationState,
} from "./BasicInformation.types";

export class BasicInformationComponent extends React.Component<
  IBasicInformationProps,
  IBasicInformationState
> {
  private _genreOptions: IChoiceGroupOption[] = [
    { key: "0", text: i18n.t("BasicInformation:ChoiceGroupe:Male") },
    {
      key: "1",
      text: i18n.t("BasicInformation:ChoiceGroupe:Feminine"),
      styles: { field: { marginLeft: "15px" } },
    },
  ];

  private _dayPickerStrings: IDatePickerStrings = DateServices.GetDatesWithLang();
  private _dateNaissanceMax: Date = new Date();

  constructor(props: IBasicInformationProps) {
    super(props);
    
    if (this.props.isClient) {
      this._dateNaissanceMax.setDate(this._dateNaissanceMax.getDate() - parseInt(process.env.REACT_APP_RESIDENT_AGE_OVER_DAYS))
    }

    this.state = {
      msgErrorIsNotAnAdult: false,
    };
  }

  renderTitreDesktop() {
    if (this.props.isReadOnly) {
      return (
        <Label style={{ marginLeft: this.props.mobile ? "" : "20px" }}>
          {this.props.mobile
            ? i18n.t("BasicInformation:TextFieldLabel:Title") + " "
            : ""}{" "}
          {this.props.titre}
        </Label>
      );
    } else {
      return (
        <Dropdown
          tabIndex={1}
          required={this.props.mobile}
          selectedKey={this.props.titre}
          options={this.props.titleOptions}
          onChange={(e, v) => {
            if (v !== undefined) {
              this.props.updateTitre(v.key.toString());
            }
          }}
        />
      );
    }
  }

  renderTitreMobile() {
    if (this.props.isReadOnly) {
      return (
        <Label style={{ marginLeft: this.props.mobile ? "" : "20px" }}>
          {this.props.mobile
            ? i18n.t("BasicInformation:TextFieldLabel:Title") + " "
            : ""}{" "}
          {this.props.titre}
        </Label>
      );
    } else {
      return (
        <Dropdown
          required={this.props.mobile}
          selectedKey={this.props.titre}
          options={this.props.titleOptions}
          onChange={(e, v) => {
            if (v !== undefined) {
              this.props.updateTitre(v.key.toString());
            }
          }}
        />
      );
    }
  }

  renderPrenom() {
    if (this.props.isReadOnly) {
      return (
        <Label
          style={{
            marginLeft: this.props.mobile ? "" : "20px",
            marginTop: this.props.mobile ? "" : "10px",
          }}
        >
          {this.props.mobile
            ? i18n.t("BasicInformation:TextFieldLabel:FirstName") + ""
            : ""}{" "}
          {this.props.prenom}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{ marginTop: "10px", width: this.props.mobile ? "" : "350px" }}
        >
          <TextField
            tabIndex={3}
            type="text"
            name="given-name"
            autoComplete="given-name"
            required={this.props.mobile}
            placeholder={
              this.props.mobile
                ? i18n.t("BasicInformation:TextFieldLabel:FirstName")
                : ""
            }
            style={{ textTransform: "capitalize" }}
            value={this.props.prenom}
            readOnly={this.props.isReadOnly}
            onChange={(e, v) => {
              if (v !== undefined && v.length < 25) {
                this.props.updatePrenom(v);
              }
            }}
          />
        </Stack>
      );
    }
  }

  renderNom() {
    if (this.props.isReadOnly) {
      return (
        <Label
          style={{
            marginTop: this.props.isClient
              ? this.props.mobile
                ? ""
                : "10px"
              : "",
          }}
        >
          {this.props.mobile
            ? i18n.t("BasicInformation:TextFieldLabel:Name") + " "
            : ""}
          {this.props.nom}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{
            marginTop: this.props.mobile
              ? "10px"
              : this.props.isClient
              ? "20px"
              : "",
            width: this.props.mobile ? "" : "350px",
          }}
        >
          <TextField
            tabIndex={window.location.href.includes("/passenger") ? 2 : 4}
            type="text"
            name="family-name"
            autoComplete="family-name"
            required={this.props.mobile}
            placeholder={
              this.props.mobile
                ? i18n.t("BasicInformation:TextFieldLabel:Name")
                : ""
            }
            style={{ textTransform: "uppercase" }}
            value={this.props.nom}
            readOnly={this.props.isReadOnly}
            onChange={(e, v) => {
              if (v !== undefined && v.length < 25) {
                this.props.updateNom(v);
              }
            }}
          />
        </Stack>
      );
    }
  }

  renderResident() {
    if (this.props.isReadOnly || this.props.cannotUpdateResident) {
      return (
        <Label>
          {this.props.mobile
            ? i18n.t("BasicInformation:CheckBox:EstResident")
            : ""}
          {this.props.isResident
            ? i18n.t("BasicInformation:ChoiceGroupe:Yes")
            : i18n.t("BasicInformation:ChoiceGroupe:No")}
        </Label>
      );
    } else {
      return (
        <Stack verticalAlign="center">
          {this.props.isClient ? (
            <Stack horizontal verticalAlign="center">
              {this.props.mobile ? (
                <Label style={{ marginRight: "10px" }}>
                  {i18n.t("BasicInformation:CheckBox:EstResident")}
                </Label>
              ) : (
                ""
              )}
              <Checkbox
                inputProps={{
                  tabIndex: window.location.href.includes("/passenger") ? 0 : 2,
                }}
                label={i18n.t("BasicInformation:Checkbox:label")}
                onChange={(e, v) => {
                  this.props.updateIsResident(v);
                }}
                checked={this.props.isResident}
              />
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      );
    }
  }

  renderCategorieAge() {
    if (!this.props.isClient) {
      return (
        <Stack
          style={{
            marginLeft: "20px",
            marginTop: "10px",
          }}
          horizontal
        >
          <Label style={{ color: this.props.theme.palette.blueLight }}>
            {i18n.t("BasicInformation:AgeCategory:Label")}
          </Label>
          <Label
            style={{
              marginLeft: "20px",
              color: this.props.theme.palette.blueLight,
            }}
          >
            {this.props.categorieAge}
          </Label>
        </Stack>
      );
    }
  }

  renderDateDeNaissance() {
    if (!this.props.isClient || this.props.isResident) {
      return (
        <Stack
          horizontal={!this.props.mobile}
          style={{
            marginTop: "10px",
            marginLeft: this.props.mobile ? "" : "20px",
          }}
        >
          <Stack horizontal>
            <Label
              required
              style={{
                marginLeft: this.props.isClient ? "5px" : "",
              }}
            >
              {i18n.t("BasicInformation:DateDeNaissance:label")}
            </Label>
          </Stack>

          <Stack
            style={{
              marginLeft: this.props.mobile ? "" : "20px",
              width: this.props.mobile ? "" : "280px",
            }}
          >
            <DatePicker
              tabIndex={5}
              defaultValue={null}
              disabled={this.props.isReadOnly}
              strings={this._dayPickerStrings}
              formatDate={CustomStringServices.FormatDate}
              maxDate={this._dateNaissanceMax}
              onSelectDate={(d) => {
                if (d !== null) {
                  this.props.updateDateDeNaissance(d);
                  if (this.props.isClient) {
                    let datNow = new Date();
                    let difference = d.getTime() - datNow.getTime();
                    let numberOfDay = (difference / (1000 * 3600 * 24)) * -1;
                    const isNotAnAdult = numberOfDay < parseInt(process.env.REACT_APP_RESIDENT_AGE_OVER_DAYS);
                    this.setState({ msgErrorIsNotAnAdult: isNotAnAdult });
                  }
                }
              }}
              value={
                this.props.dateDeNaissance !== null
                  ? new Date(this.props.dateDeNaissance)
                  : null
              }
            />
          </Stack>

          {this.state.msgErrorIsNotAnAdult ? (
            <Stack style={{ marginLeft: this.props.mobile ? "" : "20px" }}>
              <Label style={{ color: "red" }}>
                {i18n.t("LoginInformation:MsgError:IsTooYoung")}
              </Label>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      );
    }
  }

  renderGenre() {
    if (!this.props.isClient) {
      return (
        <Stack
          style={{
            marginLeft: "20px",
            marginBottom: "20px",
          }}
          horizontal
          verticalAlign="center"
        >
          <Label style={{ marginRight: "20px" }}>
            {i18n.t("BasicInformation:Gender:label")}
          </Label>
          <ChoiceGroup
            tabIndex={6}
            options={this._genreOptions}
            selectedKey={this.props.genre?.toString()}
            onChange={(e, v) => {
              if (v !== undefined) {
                let l = v.key === "1" ? 1 : 0;
                this.props.updateGenre(l);
              }
            }}
            styles={{ flexContainer: { display: "flex" } }}
          />
        </Stack>
      );
    }
  }

  renderLangue() {
    if (this.props.isClient) {
      return (
        <Stack
          style={{
            marginLeft: this.props.mobile ? "" : "20px",
            marginTop: this.props.mobile ? "10px" : "20px",
            marginBottom: "25px",
          }}
          horizontal
        >
          <Stack verticalAlign="center" style={{ marginRight: "15px" }}>
            <Stack horizontal>
              <Label
                required={!this.props.isReadOnly}
                style={{
                  marginLeft:
                    this.props.isClient && !this.props.isReadOnly ? "5px" : "",
                }}
              >
                {i18n.t("BasicInformation:TextFieldLabel:LangueCommunication")}
              </Label>
            </Stack>
          </Stack>

          {this.props.isReadOnly ? (
            <Label style={{ marginLeft: "20px" }}>
              {this.props.langue?.toString() === "0"
                ? i18n.t("BasicInformation:ChoiceGroupe:French")
                : i18n.t("BasicInformation:ChoiceGroupe:English")}
            </Label>
          ) : (
            <ChoiceGroup
              tabIndex={8}
              options={[
                {
                  key: "0",
                  text: i18n.t("BasicInformation:ChoiceGroupe:French"),
                },
                {
                  key: "1",
                  text: i18n.t("BasicInformation:ChoiceGroupe:English"),
                  styles: { field: { marginLeft: "15px" } },
                },
              ]}
              defaultSelectedKey={
                this.props.langue?.toString() === "0" ? "0" : "1"
              }
              onChange={(e, v) => {
                if (v !== undefined) {
                  let l = v.key === "0" ? 0 : 1;
                  this.props.updateLangue(l);
                }
              }}
              styles={{
                flexContainer: { display: "flex" },
              }}
            />
          )}
        </Stack>
      );
    }
  }

  PassagerToApproveMessage() {
    return (
      <Stack style={{ margin: "10px", fontStyle: "italic", color: "red" }}>
        <p>{i18n.t("BasicInformation:PsgToApproveMsg")}</p>
      </Stack>
    );
  }

  render(): JSX.Element {
    return this.props.mobile ? this.renderMobile() : this.renderDesktop();
  }

  renderMobile(): JSX.Element {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={
          this.props.isClient
            ? i18n.t("BasicInformation:H_Title:InformationPerso")
            : i18n.t("BasicInformation:H_Title:InformationPersoPassager")
        }
        isMobile={true}
        render={
          <Stack style={{ width: "90%", margin: "15px", marginTop: "0px" }}>
            {!this.props.isClient && this.props.isResident && this.props.isNew
              ? this.PassagerToApproveMessage()
              : ""}
            {this.renderResident()}
            {this.renderTitreMobile()}
            {this.renderPrenom()}
            {this.renderNom()}
            {this.renderDateDeNaissance()}
            {this.renderCategorieAge()}
            {this.renderGenre()}
            {this.renderLangue()}
          </Stack>
        }
      />
    );
  }

  renderDesktop(): JSX.Element {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={
          this.props.isClient
            ? i18n.t("BasicInformation:H_Title:InformationPerso")
            : i18n.t("BasicInformation:H_Title:InformationPersoPassager")
        }
        render={
          <Stack>
            {!this.props.isClient && this.props.isResident && this.props.isNew
              ? this.PassagerToApproveMessage()
              : ""}
            <Stack
              horizontalAlign="start"
              horizontal
              style={{ marginLeft: "20px", marginTop: "10px" }}
            >
              <Stack style={{ marginRight: "15px" }}>
                <Stack horizontal>
                  <Label required={!this.props.isReadOnly}>
                    {i18n.t("BasicInformation:TextFieldLabel:Title")}{" "}
                  </Label>
                </Stack>
                <Stack style={{ marginTop: "10px" }} horizontal>
                  <Label required={!this.props.isReadOnly}>
                    {i18n.t("BasicInformation:TextFieldLabel:FirstName")}
                  </Label>
                </Stack>
              </Stack>
              <Stack>
                {this.renderTitreDesktop()}
                {this.renderPrenom()}
              </Stack>
              <Stack style={{ marginRight: "15px", marginLeft: "100px" }}>
                {this.props.isClient ? (
                  <Label>
                    {i18n.t("BasicInformation:CheckBox:EstResident")}
                  </Label>
                ) : (
                  ""
                )}
                <Stack horizontal>
                  <Label
                    required={!this.props.isReadOnly}
                    style={{
                      marginTop: this.props.isClient ? "10px" : "",
                    }}
                  >
                    {i18n.t("BasicInformation:TextFieldLabel:Name")}
                  </Label>
                </Stack>
              </Stack>
              <Stack>
                {this.renderResident()}
                {this.renderNom()}
              </Stack>
            </Stack>

            {this.renderCategorieAge()}
            {this.renderDateDeNaissance()}
            {this.renderGenre()}
            {this.renderLangue()}
          </Stack>
        }
      />
    );
  }
}
