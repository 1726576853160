import { Checkbox, Label, Stack, TextField } from "@fluentui/react";
import * as React from "react";
import i18n from "../../Services/i18n";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import {
  IParticularAttentionProps,
  IParticularAttentionState,
} from "./ParticularAttention.types";

export class ParticularAttentionComponent extends React.Component<
  IParticularAttentionProps,
  IParticularAttentionState
> {
  constructor(props: IParticularAttentionProps) {
    super(props);
  }

  render(): JSX.Element {
    return this.props.mobile ? this.renderMobile() : this.renderDesktop();
  }

  renderMobile(): JSX.Element {
    const heightOfCheckBoxSection =
      ((this.props.conditions.length + 1) * 30).toString() + "px";

    const heightOfParticularity =
      ((this.props.particulariteAlimentaires.length + 1) * 30).toString() +
      "px";

    const heightSection =
      heightOfCheckBoxSection > heightOfParticularity
        ? heightOfCheckBoxSection
        : heightOfParticularity;

    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("AP:Title:AttentionParticuliere")}
        render={
          <Stack style={{ width: "90%", margin: "15px" }}>
            {this.renderCbMobility()}
            {this.renderCondition(heightSection)}
            {this.renderParticularity(heightSection)}

            <Label>{i18n.t("AP:Label:ContactUrgence")}</Label>

            {this.renderIdentification()}
            {this.renderRelation()}
            {this.renderFirstTelephone()}
            {this.renderSecondTelephone()}
            {this.renderOtherSection()}
          </Stack>
        }
      />
    );
  }

  renderCbMobility() {
    return (
      <Stack horizontal verticalAlign="center">
        <Label> {i18n.t("AP:Label:MobReduite")}</Label>
        <Stack style={{ marginLeft: "30px" }}>
          <Checkbox
            inputProps={{ tabIndex: 48 }}
            checked={this.props.estMobliliteReduite}
            onChange={(e, v) => {
              this.props.updateEstMobliliteReduite(v);
            }}
          />
        </Stack>
      </Stack>
    );
  }

  renderCondition(heightSection: string) {
    return (
      <Stack
        style={{
          marginLeft: this.props.mobile ? "" : "10px",
          marginRight: this.props.mobile ? "10px" : "",
          backgroundColor: this.props.theme.palette.neutralLight,
          height: heightSection,
          paddingLeft: "20px",
          paddingBottom: "20px",
          paddingRight: "20px",
        }}
      >
        <Label>{i18n.t("AP:Label:Condition")} </Label>
        <Stack style={{ height: "100%" }} verticalAlign="space-between">
          {this.props.conditions?.map((x, tabIndex) => {
            return (
              <Stack key={x.code}>
                <Checkbox
                  inputProps={{ tabIndex: tabIndex + 60 }}
                  label={x.description}
                  checked={x.valeur}
                  onChange={(e, v) => {
                    this.props.updateConditionValue(x.code, v);
                  }}
                />
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    );
  }

  renderParticularity(heightSection: string) {
    return (
      <Stack
        style={{
          marginLeft: this.props.mobile ? "" : "10px",
          marginRight: this.props.mobile ? "10px" : "",
          marginTop: this.props.mobile ? "10px" : "",
          backgroundColor: this.props.theme.palette.neutralLight,
          height: heightSection,
          paddingLeft: "20px",
          paddingBottom: "20px",
          paddingRight: "20px",
        }}
      >
        <Label>{i18n.t("AP:Label:PartiAlimentaire")}</Label>
        <Stack
          style={{
            height: "100%",
            marginLeft: this.props.mobile ? "" : "20px",
          }}
          verticalAlign="space-between"
        >
          {this.props.particulariteAlimentaires?.map((x, tabIndex) => {
            return (
              <Stack key={x.code}>
                <Checkbox
                  inputProps={{ tabIndex: tabIndex + 70 }}
                  label={x.description}
                  checked={x.valeur}
                  onChange={(e, v) => {
                    this.props.updateParticulariteAlimentairesValue(x.code, v);
                  }}
                />
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    );
  }

  renderIdentification() {
    return (
      <Stack style={{ marginTop: this.props.mobile ? "10px" : "" }}>
        <TextField
          tabIndex={80}
          placeholder={
            this.props.mobile ? i18n.t("AP:Label:Identification") : ""
          }
          value={this.props.contactUrgence.identification}
          onChange={(e, v) => {
            if (v !== undefined && v.length < 250) {
              this.props.updateIdentification(v);
            }
          }}
        />
      </Stack>
    );
  }

  renderRelation() {
    return (
      <Stack style={{ marginTop: this.props.mobile ? "10px" : "" }}>
        <TextField
          tabIndex={81}
          placeholder={this.props.mobile ? i18n.t("AP:Label:Relation") : ""}
          value={this.props.contactUrgence.relation}
          onChange={(e, v) => {
            if (v !== undefined && v.length < 100) {
              this.props.updateRelation(v);
            }
          }}
        />
      </Stack>
    );
  }

  renderFirstTelephone() {
    return (
      <Stack style={{ marginTop: this.props.mobile ? "10px" : "" }}>
        <TextField
          tabIndex={82}
          placeholder={this.props.mobile ? i18n.t("AP:Label:TelephoneOne") : ""}
          value={this.props.contactUrgence.telephone1}
          onChange={(e, v) => {
            if (v !== undefined && v.length < 100) {
              this.props.updateTelephone1(v);
            }
          }}
        />
      </Stack>
    );
  }

  renderSecondTelephone() {
    return (
      <Stack style={{ marginTop: this.props.mobile ? "10px" : "" }}>
        <TextField
          tabIndex={83}
          placeholder={this.props.mobile ? i18n.t("AP:Label:TelephoneTwo") : ""}
          value={this.props.contactUrgence.telephone2}
          onChange={(e, v) => {
            if (v !== undefined && v.length < 100) {
              this.props.updateTelephone2(v);
            }
          }}
        />
      </Stack>
    );
  }
  renderDesktop(): JSX.Element {
    const heightOfCheckBoxSection =
      ((this.props.conditions.length + 1) * 30).toString() + "px";

    const heightOfParticularity =
      ((this.props.particulariteAlimentaires.length + 1) * 30).toString() +
      "px";

    const heightSection =
      heightOfCheckBoxSection > heightOfParticularity
        ? heightOfCheckBoxSection
        : heightOfParticularity;
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("AP:Title:AttentionParticuliere")}
        render={
          <Stack style={{ marginLeft: "20px", marginTop: "40px" }}>
            {this.renderCbMobility()}

            <Stack horizontal style={{ width: "100%" }}>
              <Stack horizontal>{this.renderCondition(heightSection)}</Stack>

              <Stack horizontal>
                {this.renderParticularity(heightSection)}
              </Stack>

              <Stack
                style={{
                  marginLeft: "10px",
                  backgroundColor: this.props.theme.palette.neutralLight,
                  height: heightSection,
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
              >
                <Label>{i18n.t("AP:Label:ContactUrgence")}</Label>
                <Stack
                  horizontal
                  style={{ height: "100%", paddingBottom: "20px" }}
                >
                  <Stack
                    style={{ marginLeft: "20px" }}
                    verticalAlign="space-between"
                  >
                    <Label>{i18n.t("AP:Label:Identification")} </Label>
                    <Label>{i18n.t("AP:Label:Relation")}</Label>
                    <Label>{i18n.t("AP:Label:TelephoneOne")}</Label>
                    <Label>{i18n.t("AP:Label:TelephoneTwo")}</Label>
                  </Stack>
                  <Stack
                    style={{ height: "100%", marginLeft: "20px" }}
                    verticalAlign="space-between"
                  >
                    {this.renderIdentification()}
                    {this.renderRelation()}
                    {this.renderFirstTelephone()}
                    {this.renderSecondTelephone()}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack style={{ width: "75%", marginBottom: "25px" }}>
              {this.renderOtherSection()}
            </Stack>
          </Stack>
        }
      />
    );
  }

  renderOtherSection() {
    return (
      <TextField
        tabIndex={85}
        multiline
        label={i18n.t("AP:Label:Autres")}
        value={this.props.autre}
        onChange={(e, v) => {
          if (v !== undefined) {
            this.props.updateAutre(v);
          }
        }}
      />
    );
  }
}
