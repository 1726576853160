import { styled } from "@fluentui/react/lib/Utilities";
import { IAppProps } from "./App.types";
import { getStyles, IAppStyleProps, IAppStyles } from "./App.styles";
import { AppComponent } from "./App.base";

/**
 * App
 */
export const App = styled<IAppProps, IAppStyleProps, IAppStyles>(
  AppComponent,
  getStyles
);
