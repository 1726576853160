import { styled } from "@fluentui/react/lib/Utilities";
import { ISignInProps } from "./SignIn.types";
import { getStyles, ISignInStyleProps, ISignInStyles } from "./SignIn.styles";
import { SignInComponent } from "./SignIn.base";

/**
 * SignIn
 */
export const SignIn = styled<ISignInProps, ISignInStyleProps, ISignInStyles>(
  SignInComponent,
  getStyles
);
