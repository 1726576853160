import {
  Checkbox,
  DefaultButton,
  IconButton,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import { IMiniPassenger } from "../../Models/IMiniPassenger";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { PassengerServices } from "../../Services/PassengerServices";
import { ModalWithSimpleBanner } from "../ModalWithSimpleBanner";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import { IMyPassengersProps, IMyPassengersState } from "./MyPassengers.types";

export class MyPassengersComponent extends React.Component<
  IMyPassengersProps,
  IMyPassengersState
> {
  private _iconButtonStyles = {
    root: {
      color: this.props.theme.palette.neutralPrimary,
      marginLeft: "auto",
      marginTop: "4px",
      marginRight: "2px",
      width: "10px",
    },
    rootHovered: {
      color: this.props.theme.palette.neutralDark,
    },
  };

  constructor(props: IMyPassengersProps) {
    super(props);
    this.state = {
      miniPassengerApprouved: [],
      miniPassengerNotApprouved: [],
      isLoading: true,
      showModal: false,
      showModalPsgNotApprovedInfo: false
    };
  }

  async componentDidMount() {
    await this.getPassengers();
  }

  async getPassengers() {
    LoadingServices.setLoading("getPassengers", true);
    let miniPassengerApprouved: IMiniPassenger[] = [];
    let miniPassengerNotApprouved: IMiniPassenger[] = [];
    if (!this.props.forReservation) {
      let v = await PassengerServices.GetAllPassengers();
      v.forEach((x) => {
        if (x.enAttenteApprobation) {
          miniPassengerNotApprouved.push(x);
        } else {
          miniPassengerApprouved.push(x);
        }
      });
    }

    this.setState({
      miniPassengerApprouved: miniPassengerApprouved,
      isLoading: false,
      miniPassengerNotApprouved: miniPassengerNotApprouved,
      showModal: false,
    });
    LoadingServices.setLoading("getPassengers", false);
  }

  render(): JSX.Element {
    return (
      <Stack style={{ marginLeft: "10px" }}>
        {this.state.isLoading ? (
          ""
        ) : (
          <Stack>
            <Stack>
              {this.props.forReservation ? (
                <Stack>
                  {this.props.miniPassengerSelected.length > 0
                    ? this.renderUnderComponent(
                        i18n.t("Passenger:title:h3-1"),
                        this.props.miniPassengerSelected
                      )
                    : ""}
                </Stack>
              ) : (
                <Stack>

                  {this.state.miniPassengerApprouved.length > 0
                    ? this.renderUnderComponent(
                        i18n.t("Passenger:title:h3-1"),
                        this.state.miniPassengerApprouved
                      )
                    : ""}

                  {this.state.miniPassengerNotApprouved.length > 0
                    ? this.renderPsgToApprove(
                      i18n.t("Passenger:title:EnAttenteApprobation"),
                        this.state.miniPassengerNotApprouved)
                    : ""}
                </Stack>
              )}
            </Stack>
            {this.props.forReservation ? (
              ""
            ) : (
              <Stack>
                <Link
                  to="/passenger"
                  style={{
                    marginTop: "25px",
                    marginBottom: "25px",
                    height: "50px",
                    width: "250px",
                  }}
                >
                  <DefaultButton
                    style={{
                      color: "white",
                      backgroundColor: this.props.theme.palette.blueDark,
                      borderRadius: 10,
                      height: "50px",
                      width: "250px",
                      position: "initial",
                    }}
                  >
                    {i18n.t("Passenger:Button:Add")}
                  </DefaultButton>
                </Link>
                {this.renderModal()}
                {this.renderPsgNotApprovedInfoModal()}
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    );
  }

  renderPsgToApprove(title: string, passengers: IMiniPassenger[]){
    const infotitle = (
    <IconButton
      style={{ marginLeft: "20px" }}
      iconProps={{ iconName: "FeedbackRequestSolid" }}
      styles={this._iconButtonStyles}
      onClick={() => {
        this.setState({ showModalPsgNotApprovedInfo: true });
      }}
    />)
    
    return (
        this.renderUnderComponent(title, passengers, infotitle) 
    )
  }

  renderUnderComponent(title: string, passengers: IMiniPassenger[], infoIcon?: JSX.Element) {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={title}
        infoIcon={infoIcon}
        render={
          <Stack
            style={{
              marginLeft: "5px",
              marginTop: "20px",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            {
              this.props.mobile ? this.renderPassengersMobile(passengers) : this.renderPassengers(passengers)
            }

            {this.props.forReservation ? (
              <Stack style={{ minHeight: "30px" }}>
                {this.props.showErrorOnlyYoungChildSelected ? (
                  <Label style={{ color: "red", marginLeft: "15px" }}>
                    {i18n.t("Passenger:Label:MsgErrorKids")}
                  </Label>
                ) : (
                  ""
                )}
                {this.props.needTutor ? (
                  <Stack
                    style={{
                      marginLeft: "15px",
                      width: this.props.mobile ? "" : "400px",
                      marginBottom: this.props.mobile ? "10px" : "",
                      minHeight: "65px",
                    }}
                  >
                    <Label>{i18n.t("Passenger:Label:Tuteur")}</Label>
                    <TextField
                      onChange={(e, v) => {
                        if (v !== undefined) {
                          this.props.updateTutor(v);
                        }
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack style={{ minHeight: "65px" }}></Stack>
                )}
              </Stack>
            ) : (
              ""
            )}
          </Stack>
        }
      />
    );
  }


  renderPsgNotApprovedInfoModal() {
    return (
      <Stack>
        <ModalWithSimpleBanner
          onClose={() => {
            this.setState({ showModalPsgNotApprovedInfo: false });
          }}
          {...this.props}
          isOpen={this.state.showModalPsgNotApprovedInfo}
          render={
            <Stack style={{ margin: "25px" }}>
            <p>{i18n.t("Passenger:p:InfoPassagerToApprove")}</p>          
            </Stack>
          }
        />
      </Stack>
    );
  }

  renderModal() {
    return (
      <Stack>
        <ModalWithSimpleBanner
          onClose={() => {
            this.setState({ showModal: false });
          }}
          redirectUrlToClose={"/mypassengers"}
          {...this.props}
          isOpen={this.state.showModal}
          render={
            <Stack style={{ marginLeft: "25px" }}>
              <h2 style={{ borderBottom: "solid", marginRight: "25px" }}>
                {i18n.t("Passenger:title:DeleteQuestion")}
              </h2>
              <p>{i18n.t("Passenger:P:DeleteQuestion1")}</p>
              <p>{i18n.t("Passenger:P:DeleteQuestion2")}</p>
              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ marginRight: "75px", marginLeft: "50px" }}
              >
                <DefaultButton
                  style={{
                    color: "white",
                    backgroundColor: this.props.theme.palette.blueDark,
                    borderRadius: 10,
                    height: "40px",
                    width: "150px",
                  }}
                  onClick={() => {
                    this.setState({ showModal: false });
                  }}
                >
                  {i18n.t("Passenger:P:DeleteQuestionNo")}
                </DefaultButton>
                <DefaultButton
                  style={{
                    color: "white",
                    backgroundColor: this.props.theme.palette.blueDark,
                    borderRadius: 10,
                    height: "40px",
                    width: "150px",
                  }}
                  onClick={async () => {
                    await PassengerServices.Delete(
                      this.state.passengerSelectedId
                    );
                    await this.getPassengers();
                  }}
                >
                  {i18n.t("Passenger:P:DeleteQuestionYes")}
                </DefaultButton>
              </Stack>
            </Stack>
          }
        />
      </Stack>
    );
  }

  renderPassengers(passengers: IMiniPassenger[]): JSX.Element[] {
    const iconButtonStyles = {
      root: {
        color: this.props.theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        width: "10px",
      },
      rootHovered: {
        color: this.props.theme.palette.neutralDark,
      },
    };
    let element = passengers.map((x) => {
      let linkId = "/passenger/id=" + x.id;
      let nom = x.nomComplet;
      let categorie = x.categorie;

      return (
        <Stack key={x.id} horizontal horizontalAlign="space-between">
          {this.props.forReservation ? (
            <Label style={{ marginLeft: "15px" }}>
              - {x.nomCompletAvecCategorie}
            </Label>
          ) : (
            <Stack horizontal>
              <Label
                style={{
                  marginLeft: "15px",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                - {nom}
              </Label>
              <Label
                style={{
                  marginLeft: "5px",
                  fontSize: "20px",
                  fontWeight: "inherit",
                }}
              >
                {categorie}
              </Label>
            </Stack>
          )}

          {this.props.forReservation ? (
            <Stack>
              <Checkbox
                checked={x.isCheck}
                onChange={(e, v) => {
                  this.props.updateSelectPassenger(x, v);
                }}
              />
            </Stack>
          ) : (
            <Stack horizontal verticalAlign="center">
              {x.peutEtreModifier ? (
                <Label>
                  <a
                    href={linkId}
                    style={{
                      textDecoration: "underline",
                      color: "darkblue",
                      fontSize: "16px",
                    }}
                  >
                    {i18n.t("Passenger:P:DeleteQuestionUpdate")}
                  </a>
                </Label>
              ) : (
                ""
              )}
              {x.peutEtreSupprimer ? (
                <IconButton
                  style={{ marginLeft: "20px", position: "initial" }}
                  iconProps={{ iconName: "trash" }}
                  styles={iconButtonStyles}
                  onClick={() => {
                    this.setState({
                      showModal: true,
                      passengerSelectedId: x.id,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </Stack>
          )}
        </Stack>
      );
    });

    return element;
  }

  renderPassengersMobile(passengers: IMiniPassenger[]): JSX.Element[] {
    const iconButtonStyles = {
      root: {
        color: this.props.theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        width: "10px",
      },
      rootHovered: {
        color: this.props.theme.palette.neutralDark,
      },
    };

    let compteur = 0;
    let couleur = "white";
    let element = passengers.map((x) => {
      let linkId = "/passenger/id=" + x.id;
      let nom = x.nomComplet;
      let categorie = x.categorie;
      
      if(compteur == 0)
      {
        compteur = compteur + 1;
        couleur = "white";
      }
      else
      {
        compteur = compteur - 1;
        couleur = "WhiteSmoke";
      }

      return (
        <Stack key={x.id} horizontal horizontalAlign="space-between" style={{backgroundColor:couleur}} >
          {this.props.forReservation ? (
            <Label style={{ marginLeft: "15px" }}>
              - {x.nomCompletAvecCategorie}
            </Label>
          ) : (
            <Stack >
              <Label style={{ marginLeft: "15px", fontSize: "16px", fontWeight: "bold", }} >
                - {nom}
              </Label>
              <Label style={{ marginLeft: "5px", fontSize: "12px", fontWeight: "inherit", }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{categorie}
              </Label>
                
            </Stack>
            
          )}

          {this.props.forReservation ? (
            <Stack>
              <Checkbox
                checked={x.isCheck}
                onChange={(e, v) => {
                  this.props.updateSelectPassenger(x, v);
                }}
              />
            </Stack>
          ) : (
            <Stack horizontal verticalAlign="start">
              {x.peutEtreModifier ? (
                <Label>
                  <a
                    href={linkId}
                    style={{
                      textDecoration: "underline",
                      color: "darkblue",
                      fontSize: "16px",
                    }}
                  >
                    {i18n.t("Passenger:P:DeleteQuestionUpdate")}
                  </a>
                </Label>
              ) : (
                ""
              )}
              {x.peutEtreSupprimer ? (
                <IconButton
                  style={{ marginLeft: "20px", position: "initial" }}
                  iconProps={{ iconName: "trash" }}
                  styles={iconButtonStyles}
                  onClick={() => {
                    this.setState({
                      showModal: true,
                      passengerSelectedId: x.id,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </Stack>
            
          )}

        </Stack>
      );
    });

    return element;
  }
}
