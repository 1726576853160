import axios from "axios";
import { IMaintenance } from "../Models/IMaintenance";
import { IRetourVersion } from "../Models/IRetourVersion";
import { apiUrl } from "./ApiService";

export class AppService {
  public static async GetVersion(): Promise<IRetourVersion> {
    let result: any | undefined;
    try {
      const x = await axios.get<any>(`${apiUrl}/api/version/apiversion`);
      result = x.data;
    } catch (error) {
      result = (error as any)?.response?.status;
      result = result === 403 ? "" : result;
    }

    return result;
  }

  public static async GetInMaintenance(): Promise<IMaintenance> {
    let result: any | undefined;
    try {
      const x = await axios.get<IMaintenance>(
        `${apiUrl}/api/maintenance/get-maintenance`
      );
      result = x.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
}
