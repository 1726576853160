import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IReservationVehicleSubComponentStyles {}

export interface IReservationVehicleStyles
  extends IAppBaseStyles<IReservationVehicleSubComponentStyles> {
  logo: IStyle;
  modalLine: IStyle;
  iconButtonStyles: IStyle;
}

export interface IReservationVehicleStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getReservationVehicleClassNames = (
  styles?: IStyleFunctionOrObject<
    IReservationVehicleStyleProps,
    IReservationVehicleStyles
  >,
  props?: IReservationVehicleStyleProps
): IComponentBaseClassNames<
  IReservationVehicleStyleProps,
  IReservationVehicleStyles,
  IReservationVehicleSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IReservationVehicleStyleProps,
    IReservationVehicleStyles,
    IReservationVehicleSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IReservationVehicleStyleProps,
  IReservationVehicleStyles
> = (props: IReservationVehicleStyleProps): IReservationVehicleStyles => {
  return {
    logo: {
      maxHeight: 50,
    },

    modalLine: {
      "border-bottom-style": "solid",
      "border-bottom-width": "medium",
      "border-bottom-color": "darkblue",
    },

    iconButtonStyles: {
      root: {
        color: props.theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        
      },
    },
  };
};
