import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import {
  IAppBaseStyleProps,
  IAppBaseStyles,
  IAppTokens,
} from "../../../src/Base";

export interface IHistorySubComponentStyles {}

export interface IHistoryStyles
  extends IAppBaseStyles<IHistorySubComponentStyles> {}

export interface IHistoryStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getHistoryClassNames = (
  styles?: IStyleFunctionOrObject<IHistoryStyleProps, IHistoryStyles>,
  props?: IHistoryStyleProps
): IComponentBaseClassNames<
  IHistoryStyleProps,
  IHistoryStyles,
  IHistorySubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IHistoryStyleProps,
    IHistoryStyles,
    IHistorySubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<IHistoryStyleProps, IHistoryStyles> = (
  props: IHistoryStyleProps
): IHistoryStyles => {
  return {
    root: ["app-History", {}],
  };
};
