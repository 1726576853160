import { IDatePickerStrings } from "@fluentui/react";
import i18n from "../i18n";
class DateServices {
  GetDatesWithLang() {
    const langage = i18n.getLanguage();
    const DayPickerStrings: IDatePickerStrings = {
      months: [],
      shortMonths: [],
      days: [],
      shortDays: [],
      goToToday: "",
      prevMonthAriaLabel: "",
      nextMonthAriaLabel: "",
      prevYearAriaLabel: "",
      nextYearAriaLabel: "",
      closeButtonAriaLabel: "",
      monthPickerHeaderAriaLabel: "",
      yearPickerHeaderAriaLabel: "",
    };

    if (langage === "an") {
      DayPickerStrings.months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      DayPickerStrings.days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      DayPickerStrings.shortMonths = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      DayPickerStrings.shortDays = ["S", "M", "T", "W", "T", "F", "S"];
      DayPickerStrings.goToToday = "Go to today";
      DayPickerStrings.prevMonthAriaLabel = "Go to previous month";
      DayPickerStrings.nextMonthAriaLabel = "Go to next month";
      DayPickerStrings.prevYearAriaLabel = "Go to previous year";
      DayPickerStrings.closeButtonAriaLabel = "Close date picker";
      DayPickerStrings.monthPickerHeaderAriaLabel =
        "{0}, select to change the year";
      DayPickerStrings.yearPickerHeaderAriaLabel =
        "{0}, select to change the month";
    } else {
      DayPickerStrings.months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      DayPickerStrings.days = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];
      DayPickerStrings.shortMonths = [
        "Jan",
        "Fev",
        "Mar",
        "Av",
        "Ma",
        "Jun",
        "Jul",
        "Au",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      DayPickerStrings.shortDays = ["D", "L", "Ma", "Me", "J", "V", "S"];
      DayPickerStrings.goToToday = "Aller à aujourd'hui";
      DayPickerStrings.prevMonthAriaLabel = "Aller au mois précédent";
      DayPickerStrings.nextMonthAriaLabel = "Aller au mois prochain";
      DayPickerStrings.prevYearAriaLabel = "Aller à l'année précédente";
      DayPickerStrings.closeButtonAriaLabel = "Fermer le sélecteur de dates";
      DayPickerStrings.monthPickerHeaderAriaLabel =
        "{0}, sélectionner pour changer l'année";
      DayPickerStrings.yearPickerHeaderAriaLabel =
        "{0}, sélectionner pour changer le mois";
    }

    return DayPickerStrings;
  }
}

export default new DateServices();
