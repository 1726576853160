import {
  FontSizes,
  IconButton,
  INavLinkGroup,
  INavStyles,
  Label,
  Nav,
  Stack,
} from "@fluentui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import i18n from "../../Services/i18n";
import { ReservationServices } from "../../Services/ReservationServices";
import { getNavBarClassNames } from "./NavBar.styles";
import { INavBarProps, INavBarState } from "./NavBar.types";

export class NavBarComponent extends React.Component<
  INavBarProps,
  INavBarState
> {
  constructor(props: INavBarProps) {
    super(props);
    this.state = {
      nombreItem: 0,
      fullHeight: false,
    };
  }

  private _navStyles: Partial<INavStyles> = {
    root: {
      width: this.props.mobile ? window.innerHeight - 55 : 200,
      height: this.props.mobile ? window.innerWidth : "100vh",
      backgroundColor: this.props.theme.palette.blueDark,
      boxSizing: "border-box",
      border: "1px solid #eee",
      overflowY: "auto",
    },
    link: {
      whiteSpace: "normal",
      lineHeight: "inherit",
      textDecoration: "none",
      color: "white",
    },
  };

  async componentDidMount() {
    if (this.props.isConnected) {
      let n = await ReservationServices.GetNombreItemPanier();
      this.setState({ nombreItem: n });
    }
  }

  render(): JSX.Element {
    return this.props.mobile ? this.renderMobile() : this.renderDesktop();
  }

  renderMobile(): JSX.Element {
    const { styles } = this.props;
    const { classNames } = getNavBarClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    return (
      <Stack
        style={{
          width: "101%",
          backgroundColor: this.props.theme.palette.blueDark,
          marginTop: "-5px",
          minHeight: "50px",
          position: "fixed",
          zIndex: 1000,
        }}
        verticalAlign="center"
        horizontal
        horizontalAlign="space-between"
      >
        {this.renderFullWindowNav()}
        <IconButton
          size={20}
          iconProps={{ iconName: "AlignJustify" }}
          style={{
            color: this.props.theme.palette.white,
            marginLeft: "10px",
            marginTop: "4px",
            marginRight: "2px",
            float: "right",
          }}
          onClick={() => {
            this.setState({ fullHeight: !this.state.fullHeight });
          }}
        />
        
        <span 
          onClick={() => {
              window.location.href="/"
            }} 
          style={{
            color: this.props.theme.palette.white, 
            fontSize: "12px",
        }}>
          {i18n.t("Home:Label:GoHome")}
        </span>


        {this.props.isConnected ? (
          <Stack
            horizontal
            onClick={() => {
              this.setState({ fullHeight: false });
            }}
          >
            <Stack style={{ margin: "10px", marginRight:"25px"}} horizontalAlign="end">
              <Link
                to="/cart"
                style={{ color: this.props.theme.palette.white }}
              >
                {i18n.t("AppHeader:label:Cart")}({this.state.nombreItem})
              </Link>
              <Label
                style={{
                  fontStyle: "italic",
                  cursor: "pointer",
                  color: this.props.theme.palette.white,
                }}
                onClick={() => {
                  this.props.disconnect();
                }}
              >
                {i18n.t("AppHeader:label:logOut")}
              </Label>
            </Stack>
            {/* <Stack verticalAlign="center" style={{ margin: "5px" }}>
              <Link to="/myprofil">
                <img className={classNames.logo} src="/img/UserLogo.png" />
              </Link>
            </Stack> */}
          </Stack>
        ) : (
          <Stack
            onClick={() => {
              this.setState({ fullHeight: false });
            }}
            horizontal
            style={{ marginRight: "50px" }}
          >
            <Link
              style={{
                marginLeft: "10px",
                color: this.props.theme.palette.blueLight,
              }}
              to="/signup"
            >
              {i18n.t("AppHeader:label:SignUp")}
            </Link>
            <Link
              style={{
                marginLeft: "10px",
                color: this.props.theme.palette.blueLight,
              }}
              to="/signin"
            >
              {i18n.t("AppHeader:label:SignIn")}
            </Link>
          </Stack>
        )}
      </Stack>
    );
  }

  renderDesktop(): JSX.Element {
    return (
      <Stack style={{ position: "fixed" }}>
        <Nav
          styles={this._navStyles}
          onRenderGroupHeader={this.onRenderGroupHeader.bind(this)}
          groups={this.props.navGroup}
        />
      </Stack>
    );
  }

  renderFullWindowNav() {
    if (this.state.fullHeight) {
      return (
        <Stack
          horizontal
          style={{
            position: "absolute",
            top: 55,
            left: 0,
            backgroundColor: this.props.theme.palette.blueDark,
            width: "100%",
          }}
        >
          <Nav
           
            groups={this.props.navGroup}
            styles={this._navStyles}
            onRenderGroupHeader={this.onRenderGroupHeader.bind(this)}
          />
        </Stack>
      );
    }
  }

  onRenderGroupHeader(group: INavLinkGroup): JSX.Element {
    let chevron = group.isExpanded ? " - " : " + ";
    return (
      <Stack horizontal verticalAlign="center">
        <IconButton
          style={{
            color: this.props.theme.palette.white,
            marginLeft: "auto",
            marginTop: "4px",
            marginRight: "2px",
            float: "right",
          }}
          iconProps={{ iconName: group.groupData }}
        />
        <Label
          onClick={() => {
            this.props.updateNavBar(group);
          }}
          style={{
            borderBottomStyle: "solid",
            borderBottomWidth: "thin",
            borderBottomColor: "white",
            marginLeft: "5px",
            marginRight: "5px",
            fontSize: "16px",
            fontWeight: "bold",
            color: "white",
            cursor: "pointer",
            marginTop: "10px",
            marginBottom: "10px",
            width: "100%",
          }}
        >
          {group.name} {chevron}
        </Label>
      </Stack>
    );
  }
}
