import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface ILastNewsSubComponentStyles {}

export interface ILastNewsStyles
  extends IAppBaseStyles<ILastNewsSubComponentStyles> {}

export interface ILastNewsStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getLastNewsClassNames = (
  styles?: IStyleFunctionOrObject<ILastNewsStyleProps, ILastNewsStyles>,
  props?: ILastNewsStyleProps
): IComponentBaseClassNames<
  ILastNewsStyleProps,
  ILastNewsStyles,
  ILastNewsSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    ILastNewsStyleProps,
    ILastNewsStyles,
    ILastNewsSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<ILastNewsStyleProps, ILastNewsStyles> = (
  props: ILastNewsStyleProps
): ILastNewsStyles => {
  return {
    root: ["app-LastNews", {}],
  };
};
