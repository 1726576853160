import { styled } from "@fluentui/react/lib/Utilities";
import { ITicketingProps } from "./Ticketing.types";
import {
  getStyles,
  ITicketingStyleProps,
  ITicketingStyles,
} from "./Ticketing.styles";
import { TicketingComponent } from "./Ticketing.base";

/**
 * Ticketing
 */
export const Ticketing = styled<
  ITicketingProps,
  ITicketingStyleProps,
  ITicketingStyles
>(TicketingComponent, getStyles);
