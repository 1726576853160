import {
  INavLinkGroup,
  IProcessedStyleSet,
  MessageBar,
  MessageBarType,
  ScrollablePane,
  Stack,
} from "@fluentui/react";
import * as React from "react";
import { Route, Routes } from "react-router";
import Cookies from "universal-cookie";
import { ILayoutBaseStyles } from ".";
import { UserInfo } from "../../Models/IUserInfo";
import { ConfirmationPassword } from "../../Pages/ConfirmationPassword";
import { Home } from "../../Pages/Home";
import i18n from "../../Services/i18n";
import { UserAccountServices } from "../../Services/UserAccountServices";
import { AppHeader } from "../AppHeader";
import { Cart } from "../Cart";
import { ContactUs } from "../ContactUs";
import { CurrentBooking } from "../CurrentBooking";
import { DocumentReservation } from "../DocumentReservation";
import { Footer } from "../Footer";
import { History } from "../History";
import { LegalConditions } from "../LegalConditions";
import { MyPassengers } from "../MyPassengers";
import { MyProfil } from "../MyProfil";
import { MyVehicles } from "../MyVehicles";
import { NavBar } from "../NavBar";
import { Passenger } from "../Passenger";
import { PrivacyPolicy } from "../PrivacyPolicy";
import { ReservationPassengerVehicle } from "../ReservationPassengerVehicle";
import { RouteCalculator } from "../RouteCalculator";
import { Vehicle } from "../Vehicle";
import { getLayoutBaseClassNames } from "./LayoutBase.styles";
import { ILayoutBaseProps, ILayoutBaseState } from "./LayoutBase.types";

export class LayoutBaseComponent extends React.Component<
  ILayoutBaseProps,
  ILayoutBaseState
> {
  private _cookies = new Cookies();

  private _userInfo = this._cookies.get("userInfo") as UserInfo;

  constructor(props: ILayoutBaseProps) {
    super(props);
    if (this._userInfo === undefined) {
      i18n.setLanguage("fr");
    } else {
      i18n.setLanguage(this._userInfo.compteEntete.langue);
    }

    document.getElementById("AppTitleBrowser").innerHTML =
      i18n.t("app:title:browser");

    this.state = {
      isConnected: this._userInfo !== undefined ? true : false,
      navGroup:
        this._userInfo !== undefined
          ? this.getPrivateNav()
          : this.getPublicNav(),
      user: this._userInfo,
      lang: "",
      reloadItemAppHeader: false,
      estReloadRequis: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<ILayoutBaseProps>,
    prevState: Readonly<ILayoutBaseState>,
    snapshot?: any
  ): void {
    if (
      prevProps.maintenance.enMaintenance !==
      this.props.maintenance.enMaintenance
    ) {
      this.removeSession();
    }

    if (prevState.lang !== this.state.lang) {
      document.getElementById("AppTitleBrowser").innerHTML =
        i18n.t("app:title:browser");
      this.setState({
        navGroup: this.state.isConnected
          ? this.getPrivateNav()
          : this.getPublicNav(),
        lang: this.state.lang,
      });
    }
  }

  getPrivateNav(): INavLinkGroup[] {
    let nav: INavLinkGroup[] = [
      {
        name: i18n.t("LayoutBase:Name:MyInformation"),
        automationId: "key1",
        collapseByDefault: true,
        groupData: "Home",
        links: [
          {
            name: i18n.t("LayoutBase:Link:MyProfil"),
            url: "/myprofil",
            key: "key11",
          },
          {
            name: i18n.t("LayoutBase:Link:MyPassengers"),
            url: "/mypassengers",
            key: "key12",
          },
          {
            name: i18n.t("LayoutBase:Link:MyVehicles"),
            url: "/myvehicles",
            key: "key13",
          },
        ],
      },
      {
        automationId: "key2",
        groupData: "Ferry",
        name: i18n.t("LayoutBase:Name:Reservation"),
        collapseByDefault: true,
        links: [
          {
            name: i18n.t("LayoutBase:Link:RPV"),
            url: "/reservationpassengervehicle",
            key: "key21",
          },
          {
            name: i18n.t("LayoutBase:Link:CurrentReservation"),
            url: "/currentbooking",
            key: "key22",
          },
          {
            name: i18n.t("LayoutBase:Link:History"),
            url: "/history",
            key: "key23",
          },
        ],
      },
      {
        groupData: "Calculator",
        automationId: "key3",
        name: i18n.t("LayoutBase:Name:Calculator"),
        collapseByDefault: true,
        links: [
          {
            name: i18n.t("LayoutBase:Link:Calculator"),
            url: "/costcalculator",
            key: "key31",
          },
        ],
      },
      {
        groupData: "Headset",
        automationId: "key6",
        name: i18n.t("LayoutBase:Name:ConctactUs"),
        collapseByDefault: true,
        links: [
          {
            name: i18n.t("LayoutBase:Link:ConctactUs"),
            url: "/contactus",
            key: "key5",
          },
        ],
      },
    ];
    return nav;
  }

  getPublicNav(): INavLinkGroup[] {
    let navCalcul: INavLinkGroup = {
      name: i18n.t("LayoutBase:Name:Calculator"),
      automationId: "key1",
      collapseByDefault: true,
      links: [
        {
          name: i18n.t("LayoutBase:Link:Calculator"),
          url: "/costcalculator",
          key: "key1",
        },
      ],
    };

    let navPublicLinkGroups: INavLinkGroup[] = [
      {
        name: i18n.t("LayoutBase:Name:ConctactUs"),
        automationId: "key2",
        collapseByDefault: true,
        links: [
          {
            name: i18n.t("LayoutBase:Link:ConctactUs"),
            url: "/contactus",
            key: "key3",
          },
        ],
      },
    ];
    if (!this.props.maintenance.enMaintenance) {
      navPublicLinkGroups.push(navCalcul);
    }
    return navPublicLinkGroups;
  }

  renderMobile() {
    return (
      <Stack>
        <NavBar
          isConnected={this.state.isConnected}
          navGroup={this.state.navGroup}
          updateNavBar={(g) => this.updateNavBar(g)}
          {...this.props}
          disconnect={() => {
            this.removeSession();
          }}
          userInfo={this.state.user}
        />
        <Stack style={{ marginTop: "50px" }}>
          {this.state.isConnected
            ? this.privateRouteRender()
            : this.publicRouteRender()}
        <Footer {...this.props} />
        </Stack>
      </Stack>
    );
  }

  updateNavBar(group: INavLinkGroup) {
    this.state.navGroup.forEach((x) => (x.isExpanded = false));
    this.state.navGroup.filter(
      (x) => x.automationId === group.automationId
    )[0].isExpanded = group.isExpanded ? false : true;
    this.setState({ navGroup: this.state.navGroup });
  }

  renderDesktop(classNames: IProcessedStyleSet<ILayoutBaseStyles>) {
    return (
      <Stack>
        <ScrollablePane>
          {this.props.maintenance.enMaintenance ? (
            <MessageBar messageBarType={MessageBarType.severeWarning}>
              {this.props.maintenance.message}
            </MessageBar>
          ) : (
            ""
          )}
          <Stack horizontal>
            <Stack style={{ position: "fixed" }}>
              <NavBar
                {...this.props}
                isConnected={this.state.isConnected}
                navGroup={this.state.navGroup}
                updateNavBar={(g) => this.updateNavBar(g)}
              />
            </Stack>
            <Stack className={classNames.root} style={{ marginLeft: "200px" }}>
              <AppHeader
                {...this.props}
                isConnected={this.state.isConnected}
                Disconnect={() => {
                  this.removeSession();
                }}
                userInfo={this.state.user}
                reloadItemAppHeader={this.state.reloadItemAppHeader}
              />
              {this.state.isConnected
                ? this.privateRouteRender()
                : this.publicRouteRender()}

              <Footer {...this.props} />
            </Stack>
          </Stack>
        </ScrollablePane>
      </Stack>
    );
  }

  privateRouteRender() {
    return (
      <Routes>
        <Route
          path="/privacypolicy"
          element={<PrivacyPolicy {...this.props} />}
        />
        <Route path="/contactus" element={<ContactUs {...this.props} />} />
        <Route
          path="/costcalculator"
          element={<RouteCalculator {...this.props} />}
        />
        <Route
          path="/cart"
          element={
            <Cart
              {...this.props}
              setEstReloadRequis={(value:boolean) => {
                this.setState({ estReloadRequis: value });
              }}
              estReloadRequis={this.state.estReloadRequis}
              reloadItemAppHeader={() => {
                this.setState({ reloadItemAppHeader: true });
              }}
            />
          }
        />
        <Route
          path="/reservationpassengervehicle"
          element={
            <ReservationPassengerVehicle
              updateCartInfo={() => {
                this.setState({ ...this.setState });
              }}
              {...this.props}
            />
          }
        />
        <Route
          path="/myprofil"
          element={
            <MyProfil
              updateLangue={(value) => {
                let lg = value === 0 ? "fr" : "an";
                this._userInfo.compteEntete.langue = value === 0 ? "fr" : "an";
                this._cookies.set("userInfo", this._userInfo);
                this.setState({ lang: lg });
              }}
              updateNomPrenom={(n, p) => {
                this._userInfo.compteEntete.nom = n;
                this._userInfo.compteEntete.prenom = p;
                this.state.user.compteEntete.nom = n;
                this._cookies.set("userInfo", this._userInfo);
                this.state.user.compteEntete.prenom = p;
                this.setState({ ...this.state });
              }}
              {...this.props}
            />
          }
        />
        <Route path="/" element={<Home {...this.props} />} />
        <Route
          path="/mypassengers"
          element={<MyPassengers {...this.props} forReservation={false} />}
        />
        <Route path="/passenger/*" element={<Passenger {...this.props} />} />
        <Route
          path="/myvehicles"
          element={<MyVehicles {...this.props} forReservation={false} />}
        />
        <Route path="/vehicle/*" element={<Vehicle {...this.props} />} />

        <Route
          path="/currentbooking"
          element={<CurrentBooking {...this.props} />}
        />

        <Route
          path="/legalconditions"
          element={<LegalConditions {...this.props} />}
        />
        <Route path="/history" element={<History {...this.props} />} />
        <Route
          path="/documentreservation/*"
          element={<DocumentReservation {...this.props} />}
        />
      </Routes>
    );
  }
  publicRouteRender() {
    if (this.props.maintenance.enMaintenance) {
      return (
        <Routes>
          <Route path="/contactus" element={<ContactUs {...this.props} />} />
          <Route
            path="/privacypolicy"
            element={<PrivacyPolicy {...this.props} />}
          />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route
            path="/confirmationpassword/*"
            element={<ConfirmationPassword {...this.props} />}
          />
          <Route path="/contactus" element={<ContactUs {...this.props} />} />
          <Route
            path="/costcalculator"
            element={<RouteCalculator {...this.props} />}
          />
          <Route
            path="/privacypolicy"
            element={<PrivacyPolicy {...this.props} />}
          />
        </Routes>
      );
    }
  }

  async removeSession() {
    await UserAccountServices.UserLogout();
    this._cookies.remove("userInfo");
    this.setState({
      isConnected: false,
      navGroup: this.getPublicNav(),
      user: undefined,
    });
  }

  verifyExpirationSession() {
    if (this._userInfo !== undefined) {
      let date = Date.now();
      let dateSession = Date.parse(this._userInfo.expireLe);
      if (date > dateSession) {
        this.removeSession();
      }
    }
  }

  render(): JSX.Element {
    if (this._userInfo === undefined) {
      i18n.setLanguage("fr");
    } else {
      i18n.setLanguage(this._userInfo.compteEntete.langue);
    }
    this.verifyExpirationSession();
    const { styles } = this.props;
    const { classNames } = getLayoutBaseClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    return this.props.mobile
      ? this.renderMobile()
      : this.renderDesktop(classNames);
  }
}
