export default {
  "app:Version": " -- Alpha version : 2 -- ",
  "app:title": "Application fr",
  "app:translation": "ANG/FR",
  "app:title:browser": "Relais Nordik Inc : Billeterie en ligne",
  "app:footerDR": "@ {year} Relais Nordik inc Tous droits réservés",
  "app:footerConfidentialite": "Confidentialité",
  "app:footerContactUs": "Contactez-nous ",
  "app:genericError": "Une erreur est survenue, svp communiquer avec nous au 1-418-723-8787",
  //#REGION SignUp Page

  "SignUp:CheckBox:Condition": "J'ai lu et j'accepte ",
  "SignUp:CheckBox:ConditionLink": "les conditions",
  "SignUp:CheckBox:Yes": "Oui",
  "SignUp:CheckBox:Francais": "Francais",
  "SignUp:CheckBox:Anglais": "Anglais",
  "SignUp:H_Title:CreeCompte": "Créer mon compte",

  "SignUp:H_Title:Relais": "Relais",
  "SignUp:H_Title:NordikInc": "Nordik Inc.",
  "SignUp:Button:Save": "Sauvegarder >",
  "SignUp:Modal:Text1": "Bonjour !",
  "SignUp:Modal:Text2": "Votre compte à bel et bien été créé.",
  "SignUp:Modal:Text3": "Vous recevrez sous peu un courriel d'activation de votre compte chez Relais Nordik inc.",
  "SignUp:Modal:Text5": "Si vous n'avez rien reçu ,",
  "SignUp:Modal:TextLink1": "cliquez sur ce lien",
  "SignUp:Modal:Obligatoire": "Vous devez entrer tous les champs obligatoires.",

  //#ENDREGION SignUp Page

  //#REGION SignIn Page
  "SignIn:H_Title:Relais": "Relais",
  "SignIn:H_Title:NordikInc": "Nordik Inc.",
  "SignIn:H_Title:Connexion": "Connexion",
  "SignIn:H_Title:NoAccount": "Vous n'avez pas de compte ?",
  "SignIn:TextFieldLabel:UserName": "Courriel",
  "SignIn:TextFieldLabel:UserNameForget": "Identifiant de connexion oublié ?",
  "SignIn:TextFieldLabel:PassWord": "Mot de passe",
  "SignIn:TextFieldLabel:PassWordForget": "Mot de passe oublié ?",
  "SignIn:Button:Connexion": "Se connecter",
  "SignIn:Button:CreationCompte": "Création de compte",
  "SignIn:Checkbox:RememberMe": "Se souvenir de moi",
  "SignIn:MsgError:LoginFail": "La connexion a échoué, le courriel ou mot de passe est invalide.",

  //#ENDREGION SignIn Page

  //#REGION ForgetPassword Page
  "ForgetPassword:H_Title:Relais": "Relais",
  "ForgetPassword:H_Title:Nordik": "Nordik Inc.",
  "ForgetPassword:H_Title:H1": "Vous avez oublié votre mot de passe ?",
  "ForgetPassword:Label:1": "Veuillez inscrire votre courriel ci-dessous pour",
  "ForgetPassword:Label:2":
    "recevoir un lien de réinitialisation de mot de passe.",
  "ForgetPassword:DefaultButton:Placeholder": "Courriel",
  "ForgetPassword:DefaultButton:Text": "Envoyer >",
  //#ENDREGION ForgetPassword Page

  // #REGION Communication Information

  "CommunicationInformation:H_Title:Communication": "Communication",
  "CommunicationInformation:TextFieldLabel:Telephone": "Téléphone :",
  "CommunicationInformation:TextFieldLabel:Cellulaire": "Cellulaire :",
  "CommunicationInformation:TextFieldLabel:Email": "Courriel :",
  "CommunicationInformation:EndMessage":
    "Je désire recevoir des communications provenant de l'administration de Relais Nordik inc.",
  // #ENDREGION Communication Information

  // #REGION Adress Information
  "AdressInformation:H_Title:Adress": "Adresse",
  "AdressInformation:TextFieldLabel:NumeroCivique": "Numéro civique :",
  "AdressInformation:TextFieldLabel:Rue": "Rue :",
  "AdressInformation:TextFieldLabel:Appartement": "Appartement :",
  "AdressInformation:TextFieldLabel:CassierPostal": "Casier postal :",
  "AdressInformation:TextFieldLabel:Ville": "Ville :",
  "AdressInformation:TextFieldLabel:Province": "Province :",
  "AdressInformation:TextFieldLabel:CodePostal": "Code postal :",
  "AdressInformation:TextFieldLabel:CodePostal:Mask": "Code post\\al:a9a9a9",
  "AdressInformation:TextFieldLabel:SameAdresse": "Même adresse que le client",

  // #ENDREGION Adress Information

  // #REGION Login Information
  "LoginInformation:H_Title:InformationConex": "Connexion",
  "LoginInformation:TextFieldLabel:Email": "Courriel :",
  "LoginInformation:TextFieldLabel:Password": "Mot de passe :",
  "LoginInformation:TextFieldLabel:PasswordConfirm": "Confirmez :",
  "LoginInformation:MsgError:RegexEmail":
    "Votre mot de passe doit contenir 8 caractères, incluant une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.",
  "LoginInformation:MsgError:ConfirmPW":
    "La confirmation du mot de passe doit être identique au mot de passe",
  "LoginInformation:MsgError:EmailAlreadyUse":
    "Votre courriel est déjà utilisé",
  "LoginInformation:MsgError:Connect": "Connectez-vous",
  "LoginInformation:MsgError:EmailInvalid": "Votre courriel est invalide",
  "LoginInformation:MsgError:IsTooYoung": "Doit être 16 ans ou plus",
  // #ENDREGION Login Information

  // confirmation mdp {i18n.t("Paiement:Title:Timer")}
  "ConfirmationPassword:Titre": "Confirmation du nouveau mot de passe",
  "ConfirmationPassword:MotDePasse": "Mot de passe: ",
  "ConfirmationPassword:Sauvegarder": "Sauvegarder",
  "ConfirmationPassword:ConfirmerMotDePasse": "Confirmer le mot de passe: ",
  "ConfirmationPassword:MotDePasseValidation": "Votre mot de passe doit contenir 8 caractères, incluant une lettre majuscule, une lettre minuscule et un caractère spécial.",
  "ConfirmationPassword:MotDePasseDifferent": "La confirmation du mot de passe doit être identique au mot de passe.",

  // #REGION Basic Information
  "BasicInformation:H_Title:InformationPerso": "Informations",
  "BasicInformation:H_Title:InformationPersoPassager": "Informations",
  "BasicInformation:TextFieldLabel:Title": "Titre :",
  "BasicInformation:TextFieldLabel:FirstName": "Prénom :",
  "BasicInformation:TextFieldLabel:Name": "Nom :",
  "BasicInformation:TextFieldLabel:LangueCommunication":
    "Langue de navigation et de communication :",
  "BasicInformation:CheckBox:EstResident": "Est un résident :",
  "BasicInformation:ChoiceGroupe:French": "Français",
  "BasicInformation:ChoiceGroupe:English": "Anglais",
  "BasicInformation:Checkbox:label": "Oui",
  "BasicInformation:DateDeNaissance:label": "Date de naissance :",
  "BasicInformation:Gender:label": "Genre :",
  "BasicInformation:DateDeNaissance:Placeholder": "Choisir une date",
  "BasicInformation:AgeCategory:Label": "Catégorie d'age :",
  "BasicInformation:ChoiceGroupe:Male": "Masculin",
  "BasicInformation:ChoiceGroupe:Feminine": "Féminin",
  "BasicInformation:ChoiceGroupe:Yes": "Oui",
  "BasicInformation:ChoiceGroupe:No": "Non",
  "BasicInformation:ChoiceGroupe:AbbreviationMister": "M",
  "BasicInformation:ChoiceGroupe:AbbreviationMiss": "MME",
  "BasicInformation:PsgToApproveMsg": "Le statut de résident de la Basse-Côte-Nord doit être approuvé par un membre de l’Équipe Relais Nordik. Cette approbation peut prendre jusqu’à 48h où vous ne pourrez pas réserver de billet en ligne pour ce passager.",

  // #ENDREGION Basic Information

  //#REGION HEADER
  "AppHeader:label:logOut": "Déconnexion",
  "AppHeader:label:SignUp": "Création de compte",
  "AppHeader:label:SignIn": "Connexion",
  "AppHeader:label:UserCo": "Connecté en tant que :",
  "AppHeader:label:Cart": "Panier",

  //#ENDREGION HEADER

  //#REGION Vehicle

  "Vehicle:title:Conteneurisable": "Véhicule Conteneurisable ",
  "Vehicle:title:Attente": "Véhicle en attente d'approbation ",
  "Vehicle:button:add": "Ajouter un véhicule",

  "Vehicle:title:DeleteQuestion": "Vous souhaitez supprimer ce véhicule  ?",
  "Vehicle:P:DeleteQuestion1":
    "Etes-vous certains de vouloir supprimer ce véhicule de votre liste?",
  "Vehicle:P:DeleteQuestion2": "",
  "Vehicle:P:DeleteQuestionYes": "Oui",
  "Vehicle:P:DeleteQuestionNo": "Non",
  "Vehicle:P:DeleteQuestionUpdate": "Modifier",

  "Vehicle:Title:h3-1": "Information véhicule",
  "Vehicle:Title:h2-1": "Définition véhicule conteneurisable",
  "Vehicle:DefinitionVehiculeConteneurisable:Label":
    "Désigne un véhicule de 3 000 kilogrammes ou moins ayant des dimensions maximales de 6,0 mètres de longueur, 2,1 mètres de largeur et 2,1 mètres de hauteur. Ces dimensions sont associées à l’utilisation d’un conteneur standard correspondant à un équivalent de vingt pieds (EVP).",
  "Vehicle:label:Immatriculation": "Immatriculation",
  "Vehicle:label:Marque": "Marque",
  "Vehicle:label:QcPlate": "Plaque du Québec ?",
  "Vehicle:label:BelongsToCustomer": "Véhicule appartient au client ?",
  "Vehicle:label:VehicleType": "Type du véhicule",
  "Vehicle:label:Modele": "Modèle",
  "Vehicle:label:Annee": "Année",
  "Vehicle:label:Couleur": "Couleur",
  "Vehicle:Title:h3-2": "Dimensions",
  "Vehicle:label:Poids": "Poids",
  "Vehicle:label:Longueur": "Longueur",
  "Vehicle:label:Largeur": "Largeur",
  "Vehicle:label:Hauteur": "Hauteur",
  "Vehicle:Button:Annuler": "Annuler",
  "Vehicle:Button:Sauvegarder": "Sauvegarder",
  "Vehicle:InputSuffix:KG": "Kg",
  "Vehicle:InputSuffix:Meters": "Mètres",
  "Vehicle:label:LongueurError": "Votre longueur doit être inférieur à 6",
  "Vehicle:label:LargeurError": "Votre largeur doit être inférieur à 2.1",
  "Vehicle:label:HauteurError": "Votre hauteur doit être inférieur à 2.1",
  "Vehicle:label:Under12HoursVehicle":
    "Il est possible de faire une réservation de véhicule jusqu'au lundi midi du voyage courant.",

  //#ENDREGION Vehicle

  //#REGION MyPassenger
  "Passenger:title:h1-1": "Information pour un passager",
  "Passenger:title:h3-1": "Mes passagers",
  "Passenger:title:EnAttenteApprobation": "Passagers en attente d'approbation",
  "Passenger:Button:Add": "Ajouter un passager",
  "Passenger:Button:Cancel": "Annuler",
  "Passenger:Button:Save": "Sauvegarder",

  "Passenger:title:DeleteQuestion": "Vous souhaitez supprimer ce passager?",
  "Passenger:P:DeleteQuestion1":
    "Etes-vous certains de vouloir supprimer ce passager de votre liste?",
  "Passenger:P:DeleteQuestion2": "",
  "Passenger:P:DeleteQuestionYes": "Oui",
  "Passenger:P:DeleteQuestionNo": "Non",
  "Passenger:P:DeleteQuestionUpdate": "Modifier",
  "Passenger:p:InfoPassagerToApprove": "Un passager en attente d’approbation doit être manuellement approuvé par un membre de l’équipe Relais Nordik comme bénéficiant du rabais exclusif aux résidents de la Basse-Côte-Nord. Cette approbation peut prendre jusqu’à 48h selon les heures d’ouverture régulières. Vous ne pourrez pas réserver de billet en ligne pour ce passager tant qu’il ne sera pas approuvé.",
  "Passenger:Label:Tuteur": "Tuteur",
  "Passenger:Label:MsgErrorKids": "Un enfant de moins de 2 ans doit obligatoirement être accompagné d'un adulte",

  //#ENDREGION MyPassenger

  //#REGION CONFIRMATION ACCOUNT
  "CA:H3:1": "Merci à vous !",
  "CA:H3:2": "Votre compte est à présent fonctionnel.",
  "CA:H3:3": "Cliquer ici pour commencer à utiliser votre compte",

  //ENDREGION CONFIRMATION ACCOUNT

  //#Region MyProfil

  "MP:Label:CloseAccount": "Je désire fermer mon compte",
  "MP:Label:Update": "Modifier",
  "MP:Label:Cancel": "Annuler",
  "MP:Label:Save": "Sauvegarder",

  "MP:Modal:h3": "Êtes vous certain de vouloir désactiver votre compte ?",
  "MP:Modal:Question": "Êtes vous certain de vouloir désactiver ce compte.",
  "MP:Modal:Label":
    "Une fois désactivé, il ne vous sera plus possible d'y avoir accès.",
  "MP:P:DeleteQuestionYes": "Oui",
  "MP:P:DeleteQuestionNo": "Non",
  "MP:P:CannotDeleteAccount": "Il vous est présentement impossible de supprimer votre compte puisque vous avez des réservations actives. Pour toute question, contactez-nous au 418-723-8787 option 2.",

  //#ENDREGION MyProfil

  //#Region LayoutBase

  "LayoutBase:Name:ConctactUs": "Contactez-nous",
  "LayoutBase:Name:MyInformation": "Mes informations",
  "LayoutBase:Name:Reservation": "Réservation",
  "LayoutBase:Name:Calculator": "Calculateur",
  "LayoutBase:Name:TM": "Transport Marchandise",
  "LayoutBase:Name:RM": "Réclamation Marchandise",

  "LayoutBase:Link:ConctactUs": "Contactez-nous",
  "LayoutBase:Link:MyProfil": "Mon Profil",
  "LayoutBase:Link:MyPassengers": "Mes Passagers",
  "LayoutBase:Link:MyVehicles": "Mes Véhicules",
  "LayoutBase:Link:RPV": "Réservation passager ou véhicule",
  "LayoutBase:Link:CurrentReservation": "Mes réservations courantes",
  "LayoutBase:Link:History": "Historique",
  "LayoutBase:Link:Calculator": "Calculateur d'itinéraire",
  "LayoutBase:Link:Estimator": "Estimateur",
  "LayoutBase:Link:Reservation": "Réservation",
  "LayoutBase:Link:THN": "Transport hors-normes",
  "LayoutBase:Link:MarcDang": "Marchandises dangereuse",
  "LayoutBase:Link:RM": "Réclamation Marchandise",

  //#EndRegion LayoutBase

  //#Region Attention Particuliere

  "AP:Title:AttentionParticuliere": "Attentions particulières",
  "AP:Label:MobReduite": " Mobilité réduite ",
  "AP:Label:Condition": " Conditions : ",
  "AP:Label:PartiAlimentaire": " Particularités alimentaires :  ",
  "AP:Label:Identification": " Identification :  ",
  "AP:Label:Relation": " Relation :  ",
  "AP:Label:TelephoneOne": " Téléphone 1 :  ",
  "AP:Label:TelephoneTwo": " Téléphone 2 :  ",
  "AP:Label:Autres": " Autres:  ",
  "AP:Label:ContactUrgence": "Contact d'urgence :  ",

  //#EndRegion Attention Particuliere

  //#Region InformationItinerary

  "IF:Titre:InformationIntineraire": "Information et itinéraire",
  "IF:Titre:NombrePlaceDisponiblePartie1": "Il reste actuellement ",
  "IF:Titre:NombrePlaceDisponiblePartie2": " places pour véhicules de disponibles sur le navire pour ce tronçon.",
  "IF:Label:PortOrigine": "Port d'origine :",
  "IF:Label:PortDestination": "Port de destination :",
  "IF:Label:DateDeDepart": "Date de départ :",
  "IF:Label:Voyage": "Voyage : ",
  "IF:Label:AllerRetour": "Aller-Retour ",
  "IF:Label:isResident": "Est résident",

  //#EndRegion InformationItinerary

  //#Region ReservationPassenger

  "RPV:Message:PassageMaritimeOnly": "Seules des réservations de passages maritimes et de véhicules conteneurisables peuvent être achetées via la billetterie en ligne. Les cabines et les forfaits voyages sont toujours réservés au téléphone (1-800-463-0680).",
  "RPV:btn:Recommencer": "Recommencer",
  "RPV:btn:Retour": "Retour",
  "RPV:btn:AjoutPanier": "Ajouter au panier",
  "RPV:btn:Suivant": "Suivant",
  "RPV:Message:NoVehiculeForRoundTrip": "Malheureusement, il n'est pas possible de réserver un aller-retour pour un véhicule. Vous devez faire 2 réservations distinctes, une en aval et une en amont. Veuillez svp cliquer sur retour et modifier votre trajet.",
  "RPV:Message:NoPlaceLeft": "Malheureusement, les réservations véhicules sont complètes pour ce tronçon. Veuillez svp sélectionner un autre voyage pour compléter une réservation.",
  "RPV:Message:TooManySelectedVehicle1": "Il reste seulement ",
  "RPV:Message:TooManySelectedVehicle2": " espace(s) vehicule pour ce voyage. Svp sélectionner le véhicule voulu.",
  "RPV:Message:OverbookMustChoose1": "Il reste ",
  "RPV:Message:OverbookMustChoose2": " place(s) de disponible pour le voyage ",
  "RPV:Message:OverbookMustChoose3": ". Veuillez svp supprimer les réservations qui ne voyageront pas afin de pouvoir continuer en effectuant votre paiement.",
  //#EndRegion ReservationPassenger

  //#Region ReservationVehicle

  "RV:Label:MatiereDangereuse":
    "Je ne transporterai pas de marchandises dangereuses",
  "RV:Label:Particularite": "Particularité",
  "RV:Label:MatiereDangereuseMsgError":
    "Vous devez sélectionner que vous ne transporterez pas de marchandises dangereuses pour continuer.",
  "RV:PopUp:MatiereDangereuse1":
    "Relais Nordik Inc est assujettie  à la Loi de 1992 sur le transport de marchandises dangereuses et ses règlements. Toute personne est tenue de déclarer si elle transporte des marchandises dangereuses. Il y a plus de 3 600 marchandises dangereuses répertoriées dans la loi. Plusieurs articles, matières ou produits bien connus font partie de la liste : ",
  "RV:PopUp:li1": " Le propane et l'essence (camping);",
  "RV:PopUp:li2":
    " Le carburant pour les équipements de camping (p. ex. naphte ou gaz de pétrole liquéfié [GPL]);",
  "RV:PopUp:li3": " Les feux d'artifice;",
  "RV:PopUp:li4": " Les armes à feu et munitions;",
  "RV:PopUp:li5":
    " Les batteries qui contiennent de l'acide (à l'exception de celle du véhicule);",
  "RV:PopUp:li6": " Les produits de nettoyage (désinfectants et détergents);",
  "RV:PopUp:li7": " Les extincteurs;",
  "RV:PopUp:li8": " Les bouteilles d'air ou d'oxygène;",
  "RV:PopUp:li9":
    " Toute autre marchandise présentant un risque pour la sécurité et la santé des gens, ou encore pour les biens ou l'environnement.",
  "RV:PopUp:RedMsg":
    " Si vous transportez de telles marchandises, vous devez le déclarer avant votre embarquement. Toute personne omettant de mentionner à Relais Nordik Inc les marchandises dangereuses qu'elle transporte commet une infraction aux lois canadiennes et est passible d'une amende ou de poursuites.",

  //#EndRegion ReservationVehicle

  //#Region ServiceSupplementaire

  "SS:Title:ServeiceSupplementaire": "Service Supplémentaire",
  //#EndRegion ServiceSupplementaire

  //#Region Sommaire

  "Somaire:Title:Sommaire": "Sommaire",
  "Somaire:Label:Voyage": "Voyage : ",
  "Somaire:Label:Troncon": "Troncon : ",
  "Somaire:Label:DateDembarquement": "Date d'embarquement : ",
  "Somaire:Label:CoutPassage": "Coûts Passagers",
  "Somaire:Label:CoutVehicle": "Coûts Véhicules",
  "Somaire:Label:CoutSS": "Coûts Services Supplémentaires",
  "Somaire:Label:SommaireDesCouts": "Sommaire des coûts",
  "Somaire:Label:Passager": "Passagers",
  "Somaire:Label:Vehicules": "Véhicules",
  "Somaire:Label:Services": "Services",
  "Somaire:Label:Total": "Total",
  "Somaire:Label:Tps": "Tps",
  "Somaire:Label:Tvq": "Tvq",
  "Somaire:Label:MontantAPayer": "Montant à payer",

  //#EndRegion Sommaire

  "LatestNews:Label:Titre" : "Dernières nouvelles ...",
  "LatestNews:Label:EntetePsg" : "Voici la liste de vos passagers ayant été approuvé dans les 30 derniers jours.",
  "LatestNews:Label:NoPassager" : "Vous n'avez aucun passagers qui à été approuvé dans les 30 derniers jours.",
  "LatestNews:Label:EnteteVhc" : "Voici la liste de vos véhicules ayant été approuvé dans les 30 derniers jours.",
  "LatestNews:Label:NoVehicule" : "Vous n'avez aucun véhicules qui à été approuvé dans les 30 derniers jours.",

  //#Region Cart

  "Cart:Title:NoItem": "Vous n'avez aucune réservation dans votre panier.",
  "Cart:Title:MsgBar":
    "Les dates de certaines de vos réservations sont arrivées à échéance. Ces réservations ont été supprimées de votre panier.",
    
  "Cart:Title:MsgBarOverbook":
  "Désolé, il n'y a plus d'emplacement de disponible pour votre véhicule sur ce tronçon. La réservation de ce véhicule a été supprimée.",

  "Cart:Title:OverBookingMsgBar":
  "Impossible d'effectuer la réservation du véhicule, veuillez rafraichir votre panier.",

  "Cart:Title:MyCart": "Mon panier d'achat",
  "Cart:Title:DeleteItem":
    "Souhaitez vous supprimer définitivement cette réservation du panier ?",
  "Cart:Title:DeleteItemQuestion":
    "Êtes vous certain de vouloir supprimer cet item ?",
  "Cart:Label:Yes": "Oui",
  "Cart:Label:Non": "Non",
  "Cart:Label:SousTotal": "SOUS-TOTAL : ",
  "Cart:Label:Tps": "Tps :",
  "Cart:Label:Tvq": "Tvq :",
  "Cart:Label:Total": "Total :",
  "Cart:Label:SS": "Service supplémentaire : ",
  "Cart:Label:SSS": "Services supplémentaires : ",
  "Cart:Label:Vehicule": "Véhicules : ",
  "Cart:Label:Passager": "Passagers : ",
  "Cart:Label:Checkbox1": "J'ai lu et j'accepte  ",
  "Cart:Label:Checkbox2": "les conditions",
  "Cart:Label:Checkbox3":
    " de remboursement de cartes d'embarquement et véhicule",
  "Cart:Label:AddReservation": "Ajouter une réservation",
  "Cart:Label:Paiement": "Paiement",
  "Cart:Button:ReturnToCart": "Retourner au panier",

  "Cart:Modal:Error400":
    "Le paiement est refusé, veuillez essayer à nouveau ou communiquer avec nous au 1-800-463-8060",
  "Cart:Modal:Error500":
    "Erreur technique, svp communiquer avec nous au 1-800-463-8060",
  //#EndRegion Cart

  //#Region Paiement
  "Paiement:Title:Timesup": "Temps écoulé, veuillez réviser votre panier.",
  "Paiement:Title:Timer": "Les espaces conteneurs pour vos véhicules sont actuellement réservées pour ",
  "Paiement:Title:Paiement": "Paiement émis à Relais Nordik Inc.",
  "Paiement:Label:MontantTotal": "Montant Total : ",
  "Paiement:Label:NomProprio": "Nom du propriétaire de la carte : ",
  "Paiement:Label:CartNumber": "Numéro de la carte de crédit : ",
  "Paiement:Label:ValidationNumber": "Code de vérification : ",
  "Paiement:Label:DateDExpiration": "Date d'expiration : ",
  "Paiement:Label:Email": "Courriel : ",
  "Paiement:Label:PayerMaintenant": "Payer Maintenant ",
  "Paiment:Label:Cancel": "Annuler",

  //#EndRegion Paiement

  //#Region Confirmation
  "Confirmation:Title:Merci": "Merci",

  "Confirmation:Title:Resumer": "Résumé d'itinéraire",
  "Confirmation:Title:Reservation": "Votre réservation a été enregistrée",
  "Confirmation:Title:Reservation1": "dans notre système. Merci de voyager",
  "Confirmation:Title:Reservation3": "avec nous !",
  "Confirmation:text:R":
    "Merci d'avoir choisi de voyager avec nous ! Vous recevrez <br /> un courriel sous peu contenant toutes les informations <br />",
  "Confirmation:text:1":
    "Vous recevrez un courriel sous peu contenant toutes les informations essentielles au bon déroulement de votre embarquement.",
  "Confirmation:text:2":
    "En tout temps avant votre embarquement vous avez accès à vos documents dans la section Mes réservations courantes.",
  "Confirmation:text:3":
    "Pour toutes autres questions, n'hésitez pas à communiquer avec nous au 418-723-8787",

  //#EndRegion Confirmation

  //#Region CurrentBooking
  "CB:Label:History": "Historique",
  "CB:Label:MontantPayer": "Montant payé",
  "CB:Label:DateDeDepart": "Date de départ",
  "CB:Label:NoConfirmation": "Numéro de confirmation",
  "CB:Label:HistoryNotice": "Seule l'historique des réservations de la saison actuelle sont affichées.",
  "CB:Label:ResTraitement": "Mes réservations en attente de traitement",
  "CB:Label:HistoryNoItem": "Vous n'avez pas d'historique de réservations dans votre compte.",
  "CB:Label:MRC": "Mes réservations courantes",
  "CB:Label:Voyager": "Voyage",
  "CB:Label:Reservation": "Réservation",
  "CB:Label:OD": "Origine-Destination",
  "CB:Label:Action": "Action",
  "CB:Label:PolitiquePsg": "Politique d'annulation passagers",
  "CB:Label:FirstRulePsg":
    "Une confirmation de la réservation est transmise au client après  réception du paiement par carte de crédit du coût total de la carte d’embarquement.",
  "CB:Label:SecondRulePsg":
    "100 % remboursable jusqu’à 6 heures avant l’heure prévue d’arrivée du navire selon l’horaire officiel affiché (l’avis    d’annulation doit être envoyé par courriel ou annulé via votre compte).",
  
  "CB:Label:PolitiqueVhc": "Politique d'annulation véhicules",
    "CB:Label:FirstRuleVhc":
      "Une confirmation de la réservation est transmise au client après réception du paiement par carte de crédit du coût total de la carte d’embarquement.",
    "CB:Label:SecondRuleVhc":
      "100% remboursable jusqu'à 2 semaines avant la date prévu du transport du véhicule selon l'horaire officiel affiché (l'avis d'annulation doit être envoyé par courriel ou annulé via votre compte).",
    "CB:Label:ThirdRuleVhc":
      "75% remboursable après le délais de 2 semaines, mais avant midi le lundi précédant la date à laquelle le navire doit quitter le port de Rimouski pour réaliser son voyage.",
  
  "CB:H3:ModalDelete": "Souhaitez-vous annuler la réservation ?",
  "CB:label:Question": "Êtes vous certain de vouloir supprimer cette réservation ?",
  "CB:label:MoreInformationLigne1": "Vous vous apprêtez à annuler la réservation au complet.",
  "CB:label:MoreInformationLigne2": "Si vous devez annuler seulement un ou plusieurs passagers de votre réservation, veuillez communiquer avec nous au 1-800-463-0680.",
  "CB:label:ConfirmationAnnulationLigne1": "La réservation a bien été annulée. Le remboursement sera appliqué automatiquement sur votre carte de crédit dans un délai de 3 à 5 jours ouvrables.",
  "CB:label:ConfirmationAnnulationLigne2": "Pour toutes questions supplémentaires, veuillez communiquer avec nous au 1-800-463-0680.",
  "CB:Label:No": "Non",
  "CB:Label:Yes": "Oui",

  //#EndRegion CurrentBooking

  //#Region Calculator
  "Calculator:Title:Cal": "Calculatrice",
  "Calculator:Title:Passager": "Nombre de passagers",
  "Calculator:PassagerLabel:Adulte": "Adulte ( 16 à 64 ans )",
  "Calculator:PassagerLabel:Jeunes": "Jeune ( 2 à 16 ans )",
  "Calculator:PassagerLabel:bebe": "Bébé ( 0 - 2 ans gratuit )",
  "Calculator:PassagerLabel:Aine": "Ainé ( 65 ans et + )",
  "Calculator:Title:Vehicule": "Nombre de véhicules",
  "Calculator:label:AvertissementVehiculeAllerRetour": "Il n'est pas possible de réserver un aller-retour pour un véhicule. Vous devez faire 2 réservations distinctes, une en aval et une en amont.",
  "Calculator:label:Vehicuel": "Véhicule conteneurisable ",
  "Calculator:label:Moto": "Moto, Cyclomoteur",
  "Calculator:label:Bike": "Vélo",
  "Calculator:Somaire:Passager": "Passagers",
  "Calculator:Somaire:Vehicule": "Véhicules",
  "Calculator:Somaire:Service": "Services",
  "Calculator:Somaire:SousTotal": "Sous-Total",
  "Calculator:Somaire:Tps": "Tps",
  "Calculator:Somaire:Tvq": "Tvq",
  "Calculator:Somaire:MontantPayer": "Montant à payer",
  "Calculator:MontantApprox:Advert":
    "Ce montant est approximatif. Le tarif sera calculé à la fin de la réservation.",
  "Calculator:btn:Reserver": "Réserver",
  "Calculator:btn:Calculer": "Calculer",
  "Calculator:btn:PourPMOnly": "Pour passages maritimes seulement.",
  "Calculator:label:AssuranceSection": "Assurance véhicule :",
  "Calculator:label:ValeurVehicule": "Valeur du véhicule",
  "Calculator:label:AssuranceQuestion": "Désirez-vous être informé à propos de l'assurance véhicule ? ",
  "Calculator:label:AssuranceQuestionMandatoryMsg": "(Réponse obligatoire)",
  "Calculator:label:AssuranceCost": "Coût estimé de l’assurance",
  "Calculator:label:AssuranceInfo": "Pour en savoir plus sur nos assurances veuillez nous contacter au 1-800-463-0680 après avoir complété la réservation.",

  //#EndRegion Calculator

  //#Region DocumentReservation
  "DR:Title:DC": "Document Réservation",
  "DR:Label:Trocon": "Troncon :",
  "DR:Label:DE": "Date d'embarquement :",
  "DR:Label:NR": " Numéro Réservation",
  "DR:Label:Services": "Services",
  "DR:Label:Description": "Description",
  "DR:Label:Billet": "Billet",
  "DR:Label:PassageMaritime": "Passage Maritime",
  "DR:Label:Vehicule": "Véhicule",
  "DR:Label:Impression": "Impression :",
  "DR:Label:FicheReservation": "Fiche de réservation",
  "DR:Label:LettreTransport": "Lettre de transport",

  //#EndRegion DocumentReservation

  //#Region ContactUs
  "ContactUs:Label1:Terminaux": "Terminaux",

  "ContactUs:Label1:Rimouski": "Rimouski",
  "ContactUs:Label1:RNInc": "Relais Nordik Inc. ",
  "ContactUs:Label1:Adress": "17 Ave Lebrun Cp1113 Succ A",
  "ContactUs:Label1:ville": "Rimouski, Québec ",
  "ContactUs:Label1:CodePostal": "G5L 7R1",

  "ContactUs:Label1:PhoneLabel": "Téléphone :",
  "ContactUs:Label1:Phone1": "418-723-8787",
  "ContactUs:Label1:Phone2": "1-800-463-0680",
  "ContactUs:Label1:Fax": "Télécopieur : 418-722-9307",

  "ContactUs:Label1:OpenDay": "Ouvert du lundi au vendredi",
  "ContactUs:Label1:OpenHours": "de 8h30 à 17h",
  "ContactUs:Label1:CloseBetween": "Fermé entre 12h et 13h",

  "ContactUs:Label2:SeptIle": "Sept- Iles",
  "ContactUs:Label2:RNInc": "Relais Nordik Inc. ",
  "ContactUs:Label2:Adress": "265 rue des pionniers",
  "ContactUs:Label2:ville": "Sept-Iles, Québec ",
  "ContactUs:Label2:CodePostal": "G4R 4K2",

  "ContactUs:Label2:PhoneLabel": "Téléphone :",
  "ContactUs:Label2:Phone1": "418-723-8787",
  "ContactUs:Label2:Phone2": "1-800-463-0680",
  "ContactUs:Label2:Fax": "Télécopieur : 418-722-9307",

  "ContactUs:Label2:OpenDay": "Ouvert du lundi au vendredi",
  "ContactUs:Label2:OpenHours": "de 8h30 à 17h",
  "ContactUs:Label2:CloseBetween": "Fermé entre 12h et 13h",

  "ContactUs:h1:ContactUs": "Contactez-nous",
  "ContactUs:Label:Opinion": "Votre opinion sur nos services",
  "ContactUs:Label:1": "Afin de nous aider à nous améliorer, merci ",
  "ContactUs:Label:2": "de prendre le temps de nous faire part de",
  "ContactUs:Label:3": "vos commentaires et de vos suggestions",
  "ContactUs:link:site": "https://relaisnordik.com/contact",
  //#EndRegion ContactUs

  //#Region Home
  "Home:Label:GoHome": "Accueil",
  "Home:Label:Create":
    "Saviez-vous que vous pouvez recevoir des alertes de la Société des traversiers du Québec, vous avisant de changements apportés au   service de la desserte maritime de Relais Nordik? Il vous suffit de vous créer un compte et ensuite vous abonner aux alertes pour votre/vos port(s) d’embarquement.",
  "Home:Label:PositionDuNavire":"Position du navire",
  //#EndRegion Home

  "Privacy:Title": "POLITIQUE DE CONFIDENTIALITÉ",
  "Message:VehiculePlaceDispo1":"Il reste actuellement ",
  "Message:VehiculePlaceDispo2":" places pour véhicules de disponibles sur le navire pour ce tronçon."
};
