import { DefaultButton, Label, Stack, TextField } from "@fluentui/react";
import CustomStringServices from "../../Services/CustomStringServices/CustomStringServices";
import * as React from "react";
import { Navigate } from "react-router";
import { ModalWithSimpleBanner } from "../../Components/ModalWithSimpleBanner";
import i18n from "../../Services/i18n";
import { UserAccountServices } from "../../Services/UserAccountServices";
import { getForgetPasswordClassNames } from "./ForgetPassword.styles";
import {
  IForgetPasswordProps,
  IForgetPasswordState,
} from "./ForgetPassword.types";

export class ForgetPasswordComponent extends React.Component<
  IForgetPasswordProps,
  IForgetPasswordState
> {
  private _email: string = "";
  constructor(props: IForgetPasswordProps) {
    super(props);
    this.state = {
      redirect: false,
      isSucess: false,
      isFail: false,
      showErrorMsg: false,
    };
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getForgetPasswordClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const { redirect, isFail, isSucess } = this.state;
    let msg = "";
    if (isFail) {
      msg = "Créer un message d'erreur si envoie est echoué";
    } else if (isSucess) {
      msg = "Créer un message de succès si envoie est réussis";
    }

    if (redirect) {
      return <Navigate to="/" />;
    } else {
      return (
        <Stack>
          <Stack
            horizontal
            horizontalAlign="space-between"
            className={classNames.modalLine}
          >
            <Stack horizontal>
              <h2 style={{ marginLeft: "15px" }}>
                <a href="/">
                  <img  className={classNames.logo} src="/img/logo-Bella.svg" />
                </a>
              </h2>
            </Stack>
            <Stack style={{ marginRight: "25px", marginTop: "15px" }}>
              <Label
                style={{ cursor: "pointer", fontSize: "bold" }}
                onClick={() => {
                  let lang = i18n.getLanguage();
                  i18n.changeLanguage(lang);
                  this.forceUpdate();
                }}
              >
                {i18n.t("app:translation")}
              </Label>
            </Stack>
          </Stack>
          <ModalWithSimpleBanner
            redirectUrlToClose={"/"}
            {...this.props}
            isOpen={this.state.isFail || this.state.isSucess}
            render={
              <Stack>
                <Label>{msg}</Label>
              </Stack>
            }
          />

          <Stack>
            <h3
              className={classNames.modalLine}
              style={{
                maxWidth: this.props.mobile ? "" : "700px",
                marginLeft: this.props.mobile ? "" : "50px",
                marginTop: "50px",
              }}
            >
              {i18n.t("ForgetPassword:H_Title:H1")}
            </h3>
            <Stack
              style={{
                maxWidth: this.props.mobile ? "" : "700px",
                marginLeft: this.props.mobile ? "" : "50px",
                marginTop: "50px",
              }}
            >
              <Label style={{ fontSize: "20px" }}>
                {i18n.t("ForgetPassword:Label:1")}{" "}
                {i18n.t("ForgetPassword:Label:2")}
              </Label>

              <TextField
                placeholder={i18n.t("ForgetPassword:DefaultButton:Placeholder")}
                onChange={(e, v) => {
                  if (v !== undefined) {
                    let showErrorMsg: boolean;

                    if (
                      CustomStringServices.ValidateEmail(v)
                    ) {
                      showErrorMsg = false;
                    } else {
                      showErrorMsg = true;
                    }
                    this._email = v;
                    this.setState({
                      showErrorMsg: showErrorMsg,
                    });
                  }
                }}
              />
              {this.state.showErrorMsg ? (
                <Label style={{ color: "red" }}>
                  {i18n.t("LoginInformation:MsgError:EmailInvalid")}
                </Label>
              ) : (
                ""
              )}

              <DefaultButton
                style={{
                  marginTop: "25px ",
                  maxWidth: "150px",
                  color: "white",
                  backgroundColor: this.props.theme.palette.blueDark,
                  borderRadius: 10,
                  height: "50px",
                  width: "250px",
                }}
                text={i18n.t("ForgetPassword:DefaultButton:Text")}
                onClick={() => {
                  this.forgetPassword();
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      );
    }
  }

  forgetPassword() {
    this.setState({ redirect: true });
    UserAccountServices.ForgetPassword(this._email).then((x) => {
      if (x < 299) {
        this.setState({ isSucess: true });
      } else if (x > 299 && x < 500) {
        this.setState({ isFail: true });
      } else if (x > 500) {
        this.setState({ isFail: true });
      }
    });
  }
}
