import { styled } from "@fluentui/react/lib/Utilities";
import { IPaymentProps } from "./Payment.types";
import {
  getStyles,
  IPaymentStyleProps,
  IPaymentStyles,
} from "./Payment.styles";
import { PaymentComponent } from "./Payment.base";

/**
 * Payment
 */
export const Payment = styled<
  IPaymentProps,
  IPaymentStyleProps,
  IPaymentStyles
>(PaymentComponent, getStyles);
